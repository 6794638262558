import axios from 'axios'
import { message } from 'antd'
import baseAdminUrl from './baseAdminUrl'
import history from './history'

function handleError(msg) {
  message.error(msg)
}

// create an axios instance
const service = axios.create({
  baseURL: baseAdminUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      if (localStorage.getItem('payone_access_token')) {
        config.headers['authorization'] = localStorage.getItem(
          'payone_access_token'
        )
      }
    }
    if (localStorage.getItem('inst_token')) {
      config.headers['token'] = localStorage.getItem('inst_token')
    }
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    if (response.data instanceof ArrayBuffer) {
      return response
    }
    const authorization = response.headers.authorization
    if (authorization) {
      localStorage.setItem('payone_access_token', authorization)
    }
    const token = response.headers.token
    if (token) {
      localStorage.setItem('inst_token', token)
    }
    const res = response.data
    if (res.code === 112012) {
      let authorization = localStorage.getItem('authorization')
      if (localStorage.getItem('authorization')) {
        localStorage.clear()
        history.push(
          `/buyCrypto/login?${
            window.location.href.split('?')[1]
          }&authorization=${authorization}`
        )
      } else {
        history.push(`/buyCrypto/login?${window.location.href.split('?')[1]}`)
      }
    } else if (res.code === 310001 || res.code === 310002) {
      return res
    } else if (res.code !== 0) {
      handleError(res.msg || 'Error')
      return res.msg
    } else {
      return res
    }
  },
  (error) => {
    const hasResCode =
      error.response && error.response.data && error.response.data.code
    if (hasResCode) {
      const code = error.response.data.code
      if (code === 102001) {
        handleError('Expiration of login')
        localStorage.clear()
        history.push('/buyCrypto/login')
      } else if (code === 102007) {
        handleError('User not logged in')
        localStorage.clear()
        history.push('/buyCrypto/login')
      } else {
        handleError(error.response.data.msg)
        return Promise.reject(error)
      }
    } else {
      handleError(error?.response?.data?.msg)
      return Promise.reject(error)
    }
  }
)

export default service
