import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Result, Icon } from 'antd-mobile'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { getCheckout } from '../../utils/api'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { handleReturnUrl } from '../../utils/handle'

const ResultPaymentInProgress = (props) => {
  const { t } = useTranslation()
  const [url, handleUrl] = useState('')
  const [id, handleId] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')

  useEffect(() => {
    const paramsId = props.location.search.split('id=')[1]
    if (paramsId) {
      handleId(paramsId)
      queryOrder(paramsId)
    } else {
      message.error('order number no exist')
    }
  }, [])
  const reFetchDuration = 5000
  const queryOrder = (id) => {
    let params = { id }
    getCheckout(params)
      .then((res) => {
        handleUrl(res.data.result.return_url)
        handleLogo(res.data.result.logo)
        handleTitle(res.data.result.title)
        if (res.data.result.status === 6) {
          //超时
          props.history.push(`../result-timeout/?id=${id}`)
        } 
        else if (res.data.result?.status === 4) {
          props.history.push(`../result-fail/?id=${id}`);
        }
        else  if (res.data.result?.status === 1)  {
          props.history.push(`../result-success/?id=${id}`);
        }
        else if(res.data.result.inte_type === 'post_checkout_intent' && res.data.result.status === 23){
          setTimeout(()=>{
            queryOrder(id)
          }, reFetchDuration)
        }
      })
      .catch((err) => {
        console.log(err)
        message.error(err.msg)
      })
  }

  const messageNode = () => {
    return (
      <>
        <div className='topMessage'>{t('Result.tips8')}</div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'result-example-embedded' : ''
        } result-example`}
      >
        <Result
          img={
            <Icon type='ellipsis' className='spe' style={{ fill: '#0045ff' }} />
          }
          title={t('Result.tips9')}
          message={messageNode()}
          buttonText={t('Result.commonTips2')}
          buttonType='primary'
          onButtonClick={() => {
            const nextUrl = handleReturnUrl(`${url}`, {
              status: 0,
              id,
            });
            if(nextUrl){
              window.location.href = nextUrl
            }
          }}
        />
      </div>
      <Footer />
    </>
  )
}

export default withRouter(ResultPaymentInProgress)
