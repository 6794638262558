import React, { useState, useEffect } from 'react'
import Header from '../../Components/Header'
import { withRouter } from 'react-router-dom'
import { Icon, Button, Tabs } from 'antd-mobile'
import { message } from 'antd'
import QRCode from 'qrcode.react'
import { Helmet } from 'react-helmet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { getCheckout, cancelPayment, eventTracking } from '../../../utils/api'
import './index.css'
import visaMaster from '../../../assets/images/visa.png'
import copyImg from '../../../assets/images/copy.png'
import closeImg from '../../../assets/images/close.png'
import { CoinJson, FiatJson } from '../../../utils/coinJson'
import CircleClock from '../../../utils/canvas'
import cardTips1 from '../../../assets/images/cardTips1.png'
import cardTips5 from '../../../assets/images/cardTips5.png'
import cardTips3 from '../../../assets/images/cardTips3.png'

const tab = [
  { title: 'Crypto', sub: '1' },
  { title: 'Card', sub: '2' },
]

const PayContainer = (props) => {
  const { t } = useTranslation()
  const [id, handleId] = useState('')
  const [relHash, handleRelHash] = useState('')
  const [hash, handleHash] = useState('')
  const [amount, handleAmount] = useState('')
  const [dataOutAmount, handleDataOutAmount] = useState('')
  const [fiat, handleFiat] = useState('')
  const [currency, handleCurrency] = useState('')
  const [name, handleName] = useState('')
  const [chain, handleChain] = useState('')
  const [supportCrypto, handleSupportCrypto] = useState(false)
  const [url, handleUrl] = useState('')
  const [choose, handleChoose] = useState(false)
  const [gatewayUrl, handleGatewayUrl] = useState('')
  const [gatewayName, handleGatewayName] = useState('')
  const [payWayUrl, handlePayWayUrl] = useState('')
  const [loading, handleLoading] = useState(true)
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')

  const [tabUSDT, handleTabUSDT] = useState([])

  const visaMasterData = [
    {
      label: t('home.visaMasterData_label1'),
      img: cardTips3,
      id: 1,
    },
    {
      label: t('home.visaMasterData_label2'),
      img: cardTips5,
      id: 2,
    },
    {
      label: t('home.visaMasterData_label3'),
      img: cardTips1,
      id: 3,
    },
  ]

  const getParams = (url) => {
    var params = {}
    let search = '?' + url.split('?')[1]
    if (search.indexOf('?') == 0 && search.indexOf('=') > 1) {
      var paramArray = unescape(search).substring(1, search.length).split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  useEffect(() => {
    if (props.match.params.id) {
      handleId(props.match.params.id)
      queryOrder(props.match.params.id)
    } else {
      message.error('order number no exist')
      handleLoading(false)
    }
  }, [])

  const queryOrder = (id) => {
    handleLoading(true)
    let params = { id }
    getCheckout(params)
      .then((response) => {
        handleLoading(false)
        let res = response.data
        let status = res.result?.status
        if (res.code === 0) {
          if (res.result.status === 0) {
            props.history.push(`../result-payment-inProgress/?id=${id}`)
          }
          if (status === 1) {
            props.history.push(`../result-success/?id=${id}`)
          }
          if (status === 2) {
            props.history.push(`../result-payment-confirming/?id=${id}`)
          }
          if (status === 3) {
            props.history.push(`../result-payment-error/?id=${id}`)
          }
          if (status === 4) {
            props.history.push(`../result-fail/?id=${id}`)
          }
          if (status === 5) {
            props.history.push(`../result-cancel/?id=${id}`)
          }
          if (status === 6) {
            props.history.push(`../result-timeout/?id=${id}`)
          }
          if (res.result.status === 7) {
            props.history.push(`../result-refund-inProgress/?id=${id}`)
          }
          if (res.result.status === 8) {
            props.history.push(`../result-refund-success/?id=${id}`)
          }
          if (res.result.status === 9) {
            props.history.push(`../result-refund-fail/?id=${id}`)
          }
          if (status === 26) {
            props.history.push(`../?id=${id}`)
          }
          if (status === 23) {
            let hash = res?.result?.data?.address
            let splitHash = `${hash.substr(0, 13)}...${hash.substr(
              hash.length - 13,
              13
            )}`
            handleRelHash(hash)
            handleHash(splitHash)
            handleAmount(res?.result?.data?.amount)
            handleDataOutAmount(res?.result?.amount)
            handleFiat(res?.result?.currency)
            handleCurrency(res?.result?.data?.currency)
            handleName(res?.result?.data?.name)
            handleChain(res?.result?.data?.chain)
            handleSupportCrypto(res?.result?.data?.support_crypto)
            handleUrl(res?.result?.return_url)
            drawCanvas(res.result.expires_at)
            handleGatewayUrl(res.result?.data?.gateway[0]?.redirect_url)
            handlePayWayUrl(res.result?.data?.gateway[0]?.post_payway)
            handleGatewayName(res.result?.data?.gateway[0]?.name)
            handleLogo(res.result.logo)
            handleTitle(res.result.title)
            if (
              res?.result?.data?.support_crypto &&
              res.result?.data?.gateway[0]?.redirect_url
            ) {
              handleTabUSDT([
                { title: 'VISA/MASTER', sub: '1', logo: visaMaster },
                {
                  title: res?.result?.data?.name,
                  sub: '2',
                  logo: CoinJson[res?.result?.data?.currency],
                },
              ])
            } else if (res?.result?.data?.support_crypto) {
              handleTabUSDT([
                {
                  title: res?.result?.data?.name,
                  sub: '2',
                  logo: CoinJson[res?.result?.data?.currency],
                },
              ])
            } else if (res.result?.data?.gateway[0]?.redirect_url) {
              handleTabUSDT([
                { title: 'VISA/MASTER', sub: '1', logo: visaMaster },
              ])
            }
          }
        } else {
          handleLoading(false)
          message.error(response.data.msg)
        }
      })
      .catch((err) => {
        handleLoading(false)
        message.error(err.msg)
      })
  }

  const drawCanvas = (expires_at) => {
    let dataTime = Date.now()
    if (Number(expires_at) > dataTime) {
      if (24 * 60 * 60 > (Number(expires_at) - dataTime) / 1000) {
        const circleClock = new CircleClock('canvas')
        circleClock.run((Number(expires_at) - dataTime) / 1000, () => {
          //TODO:
          console.log('倒计时执行完毕')
        })
      }
    }
  }

  const cancel = () => {
    let params = { id }
    cancelPayment(params)
      .then((response) => {
        let res = response.data
        if (res.code === 0) {
          window.location.href = `${url}?id=${id}&status=cancel`
        } else {
          message.error(response.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  const renderCardContainer = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#fff',
          flexDirection: 'column',
        }}
        key='1'
      >
        <div className='buyCoin'>
          {t('PayContainer.tips1')}
          <div className='buyCoinTitle'>{t('PayContainer.tips2')}</div>
          {visaMasterData.map((item) => {
            return (
              <div className='listItemBottomTipsContainer' key={item.id}>
                <img src={item.img} alt='img' />
                <div className='listItemBottomTipsText'>{item.label}</div>
              </div>
            )
          })}
        </div>
        <Button
          className='confirmPayBtnBottom'
          onClick={() => {
            let params = {
              order_no: id,
              event_tracking_code: `go to ${gatewayName}`,
            }
            eventTracking(params)
            if (gatewayName === 'mercuryo') {
              let params = getParams(gatewayUrl)
              window.mercuryoWidget.run({
                widgetId: params.widget_id,
                host: document.getElementById('mercuryo-widget'),
                address: params.address,
                type: params.type,
                currency: params.currency,
                fixCurrency: params.fix_currency,
                fixAmount: params.fix_amount,
                fiatCurrency: params.fiat_currency,
                fixFiatCurrency: params.fix_fiat_currency,
                fiatAmount: params.fiat_amount,
                fixFiatAmount: params.fix_fiat_amount,
                merchantTransactionId: params.merchant_transaction_id,
                signature: params.signature,
              })
            } else if (gatewayName === 'banxa') {
              window.open(gatewayUrl)
            } else if (gatewayName === 'moonpay') {
              window.open(gatewayUrl)
            } else if (gatewayUrl) {
              handleChoose(!choose)
            }
          }}
        >
          {t('PayContainer.tips3')}
        </Button>
      </div>
    )
  }

  const renderCryptoContainer = () => {
    return supportCrypto ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#fff',
          flexDirection: 'column',
        }}
        key='2'
      >
        <div className='toPayTips'>
          {t('PayContainer.tips5')}
          {chain}
        </div>
        <div className='codeScan'>
          <QRCode value={relHash} size={140} />
        </div>
        <div className='addressContainer'>
          <span className='copyWeight'>{hash}</span>
          <CopyToClipboard
            text={relHash}
            onCopy={(value, a) => {
              message.success('Copied')
            }}
          >
            <img className='copyImage' src={copyImg} alt='img' />
          </CopyToClipboard>
        </div>
        <div className='copySty'>{t('PayContainer.amount')}</div>
        <div className='addressContainer'>
          <span className='copyWeight'>
            {amount} {currency}
          </span>
          <CopyToClipboard
            text={amount}
            onCopy={(value, a) => {
              message.success('Copied')
            }}
          >
            <img className='copyImage' src={copyImg} alt='img' />
          </CopyToClipboard>
        </div>
        <Button
          className='confirmPayBtnBottom'
          onClick={() => {
            window.location.href = `${url}?id=${id}&status=confirming`
          }}
        >
          {t('PayContainer.alreadyPaid')}
        </Button>
      </div>
    ) : (
      <></>
    )
  }

  const renderLoading = () => (
    <div className='loadingDiv'>
      <div>{t('home.loading')}</div>
      <Icon
        type='loading'
        className='spe'
        size='lg'
        style={{ fill: '#0045ff', marginTop: '10px' }}
      />
    </div>
  )

  const renderIframe = () => {
    switch (gatewayName) {
      case 'mercuryo':
        return (
          <div
            id='mercuryo-widget'
            style={{ width: '315px', height: '795px' }}
          />
        )
      case 'post_payway':
        return (
          <iframe
            title='post_payway'
            srcDoc={payWayUrl}
            height='795'
            width='315'
            frameBorder='0'
          />
        )
      default:
        return (
          <iframe
            title=' '
            src={gatewayUrl}
            height='795'
            width='315'
            frameBorder='0'
          />
        )
    }
  }

  const renderContainerAll1 = () => {
    if (gatewayUrl && supportCrypto) {
      return [renderCardContainer(), renderCryptoContainer()]
    } else if (gatewayUrl) {
      return <>{renderCardContainer()}</>
    } else if (supportCrypto) {
      return <>{renderCryptoContainer()}</>
    }
  }

  const renderContainerAll2 = () => {
    if (gatewayUrl && supportCrypto) {
      return [renderCryptoContainer(), renderCardContainer()]
    } else if (gatewayUrl) {
      return <>{renderCardContainer()}</>
    } else if (supportCrypto) {
      return <>{renderCryptoContainer()}</>
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={true} cancel={cancel} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'currencyContainer-list-pay-embedded'
            : ''
        } currencyContainer-list-pay`}
      >
        <div className='canvasContainer'>
          <h2>{t('PayContainer.title')}</h2>
          <canvas id='canvas' width='80' height='80'></canvas>
        </div>
        <span className='tipsCard'>#{id}</span>
        <div className='bottomBorder' />
        <div className='currencyTop'>
          <div className='payTopLeft'>
            {fiat ? (
              <img className='topLeftImg' src={FiatJson[fiat]} alt='img' />
            ) : null}
            <span className='topLeftText'>{fiat}</span>
          </div>
          <div className='topRight'>
            <span className='topRightAmount'>{dataOutAmount}</span>
          </div>
        </div>
        <div className='payCenter'>
          {!loading ? (
            <div className='listContainer'>
              {currency === 'USDT' ||
              currency === 'BUSD' ||
              currency === 'BUSD-BEP20' ||
              currency === 'USDT-BEP20' ||
              currency === 'USDT-TRC20' ||
              currency === 'DAI' ? (
                <Tabs
                  tabs={tabUSDT}
                  initialPage={0}
                  renderTab={(tab) => (
                    <>
                      <img src={tab.logo} className='titleImg' alt='img' />
                      <span className='titleSty'>{tab.title}</span>
                    </>
                  )}
                >
                  {renderContainerAll1()}
                </Tabs>
              ) : (
                <Tabs
                  tabs={tab}
                  initialPage={0}
                  renderTab={(tab) => <span>{tab.title}</span>}
                >
                  {renderContainerAll2()}
                </Tabs>
              )}
            </div>
          ) : (
            renderLoading()
          )}
        </div>
        <div className={choose ? 'coinContainerChoose' : 'coinContainer'}>
          <div
            className='cancelBuy'
            onClick={() => {
              if (gatewayUrl) {
                handleChoose(!choose)
              }
            }}
          >
            <img className='copyImage' src={closeImg} alt='img' />
          </div>
          {renderIframe()}
        </div>
      </div>
    </>
  )
}

export default withRouter(PayContainer)
