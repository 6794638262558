import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { JSEncrypt } from "encryptlong";
import { InputItem, Button } from "antd-mobile";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import Header from "../Components/Header";
import { getCheckout, channelPayment } from "../../utils/api";
import { SHA256 } from "../../utils/sha256.js";
import countryList from "../../utils/Country.json";
import { regNumber } from "../../utils/reg";
import { siteIdV1, siteIdV2 } from "../../utils/config";
import visaCard from "../../assets/images/visaCard.svg";
import mastercard from "../../assets/images/mastercard.svg";
import visaMasterCard from "../../assets/images/visa-masterCard.svg";
import mcSecureCode from "../../assets/images/mc-secure-code.svg";
import verifiedByVisa from "../../assets/images/verified-by-visa.svg";

import pci from "../../assets/images/pci.svg";
import Lock from "../../assets/images/lock.svg";
import xshangImg from "../../assets/images/xshang.svg";
import xxiaImg from "../../assets/images/xxia.svg";
import cardType from "../../assets/images/cardType.svg";
import closeImg from "../../assets/images/close.png";
import "./index.css";

const BankCard = (props) => {
  const { t } = useTranslation();
  const [type, handleType] = useState("publickey");
  const [loading, handleLoading] = useState(false);
  const [amount, handleAmount] = useState("");
  const [currency, handleCurrency] = useState("");
  const [cardNumberDisabled, handleCardNumberDisabled] = useState(false);
  const [publickey, handlePublickey] = useState("");
  const [card_number, handleCardNumber] = useState("");
  const [canDelete, handleCanDelete] = useState(false);
  const [expire, handleExpire] = useState("");
  const [cvv, handleCvv] = useState("");
  const [cardImg, handleCardImg] = useState(mastercard);
  const [showTipsCardNumber, handleShowTipsCardNumber] = useState(false);
  const [showExpire, handleShowExpire] = useState(false);
  const [showCvv, handleShowCvv] = useState(false);
  const [payType, handlePayType] = useState([]);

  const [last_name, handleLastName] = useState("");
  const [first_name, handleFirstName] = useState("");
  const [email, handleEmail] = useState("");
  const [phone, handlePhone] = useState("");
  const [hiddenPhone, handleHiddenPhone] = useState(false)
  const [last_name_disabled, handleLastNameDisabled] = useState(false);
  const [first_name_disabled, handleFirstNameDisabled] = useState(false);
  const [email_disabled, handleEmailDisabled] = useState(false);
  const [phone_disabled, handlePhoneDisabled] = useState(true);
  const [logo, handleLogo] = useState("");
  const [title, handleTitle] = useState("");
  const [showLastNameTips, handleShowLastNameTips] = useState(false);
  const [showFirstNameTips, handleShowFirstNameTips] = useState(false);
  const [showEmailTips, handleShowEmailTips] = useState(false);
  const [showPhoneTips, handleShowPhoneTips] = useState(false);
  const [countryOption, handleCountryOption] = useState([]);
  const [country, handleCountry] = useState([]);
  const [countryDisabled, handleCountryDisabled] = useState([]);
  const [billingShow, handleBillingShow] = useState(true);
  const [billingIsInit, handleBillingIsInit] = useState(false);

  const [siteId, handleSiteId] = useState(null);

  const [id, handleId] = useState("");

  const [submittedCardMsg, handleSubmittedCardMsg] = useState("");
  const [submittedTotal, handleSubmittedTotal] = useState(0);

  const getParams = () => {
    var params = {};
    if (
      props.location.search.indexOf("?") == 0 &&
      props.location.search.indexOf("=") > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split("&");
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split("=")[0]] = currentValue.split("=")[1];
        });
      }
    }
    return params;
  };

  useEffect(() => {
    if (getParams().dev_fingerprint_url) {
      const script1 = document.createElement("script");
      script1.src = window.atob(getParams().dev_fingerprint_url);
      // 显示声明同步加载
      script1.async = false;
      document.body.appendChild(script1);
      return () => {
        if (getParams().dev_fingerprint_url) {
          document.body.removeChild(script1);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (getParams().type === "publickey-withname") {
      handleSiteId(siteIdV2);
    } else if (
      getParams().type === "publickey-verify" ||
      getParams().type === "publickey"
    ) {
      handleSiteId(siteIdV1);
    }
  }, []);

  useEffect(() => {
    initOption();
    if (getParams().type) {
      handleType(getParams().type);
    }
    if (props.match.params.id) {
      handleId(props.match.params.id);
      initOrder(props.match.params.id);
    } else {
      message.error("order number no exist");
    }
  }, []);

  const initOption = () => {
    let countryOptions = [];
    countryList.map((item) => {
      countryOptions.push({
        value: item.code,
        label: `+${item.code} ${item.name_en}`,
      });
    });
    handleCountryOption(countryOptions);
  };

  const initOrder = (id) => {
    let params = {
      id,
      channel: "pt",
    };
    getCheckout(params)
      .then((response) => {
        let res = response.data;
        if (res.code === 0) {
          handleAmount(res.result.amount);
          handleCurrency(res.result.currency);
          handlePublickey(res.result.data.publickey);
          handleLogo(res.result.logo);
          handleTitle(res.result.title);
          handlePayType(res.result.pay_type || []);
          if (getParams().type === "publickey-verify") {
            handleLastName(res.result.data.customer.last_name);
            handleFirstName(res.result.data.customer.first_name);
            handleEmail(res.result.data.customer.email);
            handlePhone(res.result.data.customer.phone);
            if(res.result.data.customer.phone){
              handleHiddenPhone(res.result.data.customer.phone.indexOf('***')!==-1)
            }
            else{
              handleHiddenPhone(false)
            }
            
            handleCountry(res.result.data.customer.country);
            handleCardNumber(res.result.data.customer.card_number);
            handleLastNameDisabled(Boolean(res.result.data.customer.last_name));
            handleFirstNameDisabled(
              Boolean(res.result.data.customer.first_name)
            );
            handleEmailDisabled(Boolean(res.result.data.customer.email));
            handlePhoneDisabled(Boolean(res.result.data.customer.phone));
            handleCountryDisabled(Boolean(res.result.data.customer.country));
            handleCardNumberDisabled(
              Boolean(res.result.data.customer.card_number)
            );
          }
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const getSHA256 = () => {
    return SHA256(card_number + expire + cvv);
  };
  const payment = () => {
    handleSubmittedTotal(submittedTotal + 1);
    if (expire.length === 5) {
      let len = expire.length;
      if (
        !regNumber.test(expire.substring(0, 2)) ||
        !regNumber.test(expire.substring(len - 2, len)) ||
        expire.substring(2, 3) !== "/"
      ) {
        handleShowExpire(true);
      } else {
        handleShowExpire(false);
      }
    }
    if (submittedTotal > 5) {
      props.history.push(`../result-fail/?id=${id}`);
    } else if (
      getSHA256() === submittedCardMsg &&
      (type === "publickey-verify" || type === "publickey-withname")
    ) {
      message.error("Payment failed.");
    } else if (
      type === "publickey" &&
      (expire.length !== 5 || !cvv || !card_number)
    ) {
      if (expire.length !== 5) {
        handleShowExpire(true);
      } else {
        handleShowExpire(false);
      }
      if (!card_number || card_number.length < 19) {
        handleShowTipsCardNumber(true);
      }
      handleShowCvv(!cvv);
    } else if (
      type === "publickey-verify" &&
      (expire.length !== 5 ||
        !cvv ||
        !card_number ||
        !last_name ||
        !first_name ||
        !email ||
        !phone)
    ) {
      if (!last_name || !first_name || !email || !phone) {
        handleBillingShow(true);
      }
      if (expire.length !== 5) {
        handleShowExpire(true);
      } else {
        handleShowExpire(false);
      }
      if (!card_number || card_number.length < 19) {
        handleShowTipsCardNumber(true);
      } else {
        handleShowTipsCardNumber(false);
      }
      handleShowCvv(!cvv);
      handleShowLastNameTips(!last_name);
      handleShowFirstNameTips(!first_name);
      handleShowEmailTips(!email);
      handleShowPhoneTips(!phone);
    } else if (
      type === "publickey-withname" &&
      (expire.length !== 5 || !cvv || !card_number || !last_name || !first_name)
    ) {
      if (expire.length !== 5) {
        handleShowExpire(true);
      } else {
        handleShowExpire(false);
      }
      if (!card_number || card_number.length < 19) {
        handleShowTipsCardNumber(true);
      } else {
        handleShowTipsCardNumber(false);
      }
      handleShowCvv(!cvv);
      handleShowLastNameTips(!last_name);
      handleShowFirstNameTips(!first_name);
    } else if (!phone_disabled && !country) {
      handleShowPhoneTips(true);
      handleBillingShow(true);
    } else {
      handleShowLastNameTips(false);
      handleShowFirstNameTips(false);
      handleShowEmailTips(false);
      handleShowPhoneTips(false);
      handleLoading(true);
      const enc = new JSEncrypt();
      enc.setPublicKey(publickey);
      let jsonPsw = {
        type: type,
        card_number: card_number.replace(/\s*/g, ""),
        expire: expire,
        cvv: cvv,
      };
      if (type === "publickey-verify") {
        jsonPsw.customer = {
          email: email,
          first_name: first_name,
          last_name: last_name,
          phone: phone,
        };
        if (!phone_disabled) {
          jsonPsw.customer.country = country;
        } else {
          jsonPsw.customer.country = "";
        }
      } else if (type === "publickey-withname") {
        jsonPsw.first_name = first_name;
        jsonPsw.last_name = last_name;
      }
      let encrypted = enc.encryptLong(JSON.stringify(jsonPsw));
      let params = {
        id,
        encrypt: encrypted,
        forter_token: localStorage.getItem("payone_pay_forter_token"),
      };
      if (type === "publickey-withname") {
        params.browserInfo = {
          browserAcceptHeader: "application/json",
          browserJavaEnabled: window.navigator.javaEnabled(),
          browserLanguage: window.navigator.language,
          browserTZ: 0 - new Date().getTimezoneOffset() / 60,
          browserUserAgent: "",
          browserColorDepth: window.screen.colorDepth,
          browserScreenHeight: window.screen.height,
          browserScreenWidth: window.screen.width,
          browserJavascriptEnabled: true,
          browserIP: "",
          challengeWindowSize: window.screen.challengeWindowSize,
        };
      }
      channelPayment(params)
        .then((response) => {
          let res = response.data;
          handleLoading(false);
          if (res.code === 0) {
            if (res.result?.status === 4) {
              props.history.push(`../result-fail/?id=${id}`);
            }
            else if (res.result?.status === 0 || res.result?.status === 1) {
              if (res.result?.acs_url) {
                window.location.href = res.result?.acs_url;
              } else if (res.result?.front_url) {
                window.location.href = `${res.result?.front_url}`;
              } else {
                if (res.result?.status === 0) {
                  props.history.push(`../result-payment-inProgress/?id=${id}`);
                } else {
                  props.history.push(`../result-success/?id=${id}`);
                }
              }
            } else {
              message.error(res.result.msg);
            }
          } else if (res.code === 111072) {
            handleSubmittedCardMsg(getSHA256());
            message.error(res.msg);
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          handleLoading(false);
          message.error(err);
        });
    }
  };

  const bankCardChange = (value) => {
    if (value.slice(0, 1) == 4) {
      handleCardImg(visaCard);
    } else {
      handleCardImg(mastercard);
    }
    handleCardNumber(value);
  };

  const bankCardOnBlur = () => {
    if (!card_number || card_number.length < 19) {
      handleShowTipsCardNumber(true);
    } else {
      handleShowTipsCardNumber(false);
    }
  };

  const expireOnBlur = () => {
    if (!expire) {
      handleShowExpire(true);
    } else {
      handleShowExpire(false);
    }
  };

  const expireChange = (value) => {
    let rule1 = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    let rule2 = ["2", "3", "4", "5", "6", "7", "8", "9"];
    if (!canDelete) {
      if (rule1.indexOf(value) >= 0) {
        handleExpire(`${value}/`);
      } else if (value == 0 || value == 1) {
        handleExpire(value);
      } else if (rule2.indexOf(value) >= 0) {
        handleExpire(`0${value}/`);
      } else {
        handleExpire(value);
      }
    } else {
      handleExpire(value);
    }
  };

  const cvvOnBlur = () => {
    if (!cvv) {
      handleShowCvv(true);
    } else {
      handleShowCvv(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={logo} />
        {siteId ? (
          <script type="text/javascript" id={siteId}>
            {`      ;(function () {
    var eu = '22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv'
    var siteId = "${siteId}"
    function t(t, e) {
      for (var n = t.split(''), r = 0; r < n.length; ++r)
        n[r] = String.fromCharCode(n[r].charCodeAt(0) + e)
      return n.join('')
    }
    function e(e) {
      return t(e, -_).replace(/%SN%/g, siteId)
    }
    function n() {
      var t = 'no' + 'op' + 'fn',
        e = 'g' + 'a',
        n = 'n' + 'ame'
      return window[e] && window[e][n] === t
    }
    function r() {
      return !(
        !navigator.brave || 'function' != typeof navigator.brave.isBrave
      )
    }
    function o() {
      return document.currentScript && document.currentScript.src
    }
    function i(t) {
      try {
        ;(F.ex = t),
          n() && F.ex.indexOf(V.uB) === -1 && (F.ex += V.uB),
          r() && F.ex.indexOf(V.uBr) === -1 && (F.ex += V.uBr),
          o() && F.ex.indexOf(V.nIL) === -1 && (F.ex += V.nIL),
          window.ftr__snp_cwc || (F.ex += V.s),
          C(F)
      } catch (e) {}
    }
    function a(t, e) {
      function n(o) {
        try {
          o.blockedURI === t && (e(), document.removeEventListener(r, n))
        } catch (i) {
          document.removeEventListener(r, n)
        }
      }
      var r = 'securitypolicyviolation'
      document.addEventListener(r, n),
        setTimeout(function () {
          document.removeEventListener(r, n)
        }, 2 * 60 * 1e3)
    }
    function c(t, e, n, r) {
      var o = !1
      ;(t = 'https://' + t),
        a(t, function () {
          r(!0), (o = !0)
        })
      var i = document.createElement('script')
      ;(i.onerror = function () {
        if (!o)
          try {
            r(!1), (o = !0)
          } catch (t) {}
      }),
        (i.onload = n),
        (i.type = 'text/javascript'),
        (i.id = 'ftr__script'),
        (i.async = !0),
        (i.src = t)
      var c = document.getElementsByTagName('script')[0]
      c.parentNode.insertBefore(i, c)
    }
    function u(t, e, n) {
      var r = new XMLHttpRequest()
      if (
        (a(t, function () {
          n(new Error('CSP Violation'), !0), (isErrorEventCalled = !0)
        }),
        'withCredentials' in r)
      )
        r.open('GET', t, !0)
      else {
        if ('undefined' == typeof XDomainRequest) return
        ;(r = new XDomainRequest()), r.open('GET', t)
      }
      ;(r.onload = function () {
        'function' == typeof e && e(r)
      }),
        (r.onerror = function (t) {
          if ('function' == typeof n && !isErrorEventCalled)
            try {
              n(t, !1), (isErrorEventCalled = !0)
            } catch (e) {}
        }),
        (r.onprogress = function () {}),
        (r.ontimeout = function () {
          'function' == typeof n && n('tim' + 'eo' + 'ut', !1)
        }),
        setTimeout(function () {
          r.send()
        }, 0)
    }
    function d() {
      u(
        y,
        function (n) {
          try {
            var r = n.getAllResponseHeaders().toLowerCase()
            if (r.indexOf(x.toLowerCase()) < 0) return
            var o = n.getResponseHeader(x),
              i = t(o, -_ - 1)
            if (i) {
              var a = i.split(':')
              if (a && 3 === a.length) {
                var c = a[0],
                  u = a[1],
                  d = a[2]
                switch (u) {
                  case 'none':
                    A = c + T
                    break
                  case 'res':
                    A = c + T + d
                    break
                  case 'enc':
                    A = c + e('1forxgiurqw1qhw2vq2(VQ(2vfulsw1mv')
                    break
                  case 'enc-res':
                    for (var s = '', v = 0, l = 0; v < 20; ++v)
                      s +=
                        v % 3 > 0 && l < 12
                          ? siteId.charAt(l++)
                          : F.id.charAt(v)
                    var m = d.split('.')
                    if (m.length > 1) {
                      var h = m[0],
                        w = m[1]
                      A = c + T + h + '.' + s + '.' + w
                    }
                }
                if (r.indexOf(S.toLowerCase()) >= 0) {
                  var p = n.getResponseHeader(S),
                    g = t(p, -_ - 1)
                  window.ftr__altd = g
                }
                j(V.dUAL), setTimeout(f, k, V.dUAL)
              }
            }
          } catch (y) {}
        },
        function (t, e) {
          i(e ? V.uAS + V.cP : V.uAS)
        }
      )
    }
    function f(t) {
      try {
        var e = t === V.uDF ? L : A
        if (!e) return
        var n = function () {
            try {
              Q(), i(t + V.uS)
            } catch (e) {}
          },
          r = function (e) {
            try {
              Q(),
                (F.td = 1 * new Date() - F.ts),
                i(e ? t + V.uF + V.cP : t + V.uF),
                t === V.uDF && d()
            } catch (n) {
              i(V.eUoe)
            }
          }
        c(e, void 0, n, r)
      } catch (o) {
        i(t + V.eTlu)
      }
    }
    var s = 'fort',
      v = 'erTo',
      l = 'ken',
      m = s + v + l,
      h = 10,
      w = {
        write: function (t, e, n, r) {
          void 0 === r && (r = !0)
          var o, i
          if (
            (n
              ? ((o = new Date()),
                o.setTime(o.getTime() + 24 * n * 60 * 60 * 1e3),
                (i = '; expires=' + o.toGMTString()))
              : (i = ''),
            !r)
          )
            return void (document.cookie =
              escape(t) + '=' + escape(e) + i + '; path=/')
          for (
            var a = 1, c = document.domain.split('.'), u = h, d = !0;
            d && c.length >= a && u > 0;

          ) {
            var f = c.slice(-a).join('.')
            document.cookie =
              escape(t) + '=' + escape(e) + i + '; path=/; domain=' + f
            var s = w.read(t)
            ;(null != s && s == e) ||
              ((f = '.' + f),
              (document.cookie =
                escape(t) + '=' + escape(e) + i + '; path=/; domain=' + f)),
              (d = document.cookie.indexOf(t + '=' + e) === -1),
              a++,
              u--
          }
        },
        read: function (t) {
          var e = null
          try {
            for (
              var n = escape(t) + '=',
                r = document.cookie.split(';'),
                o = 32,
                i = 0;
              i < r.length;
              i++
            ) {
              for (var a = r[i]; a.charCodeAt(0) === o; )
                a = a.substring(1, a.length)
              0 === a.indexOf(n) &&
                (e = unescape(a.substring(n.length, a.length)))
            }
          } finally {
            return e
          }
        },
      },
      p = '13'
    p += 'ck'
    var g = function (t) {
        var e = function () {
          var e = document.createElement('link')
          return (
            e.setAttribute('rel', 'pre' + 'con' + 'nect'),
            e.setAttribute('cros' + 'sori' + 'gin', 'anonymous'),
            (e.onload = function () {
              document.head.removeChild(e)
            }),
            (e.onerror = function (t) {
              document.head.removeChild(e)
            }),
            e.setAttribute('href', t),
            document.head.appendChild(e),
            e
          )
        }
        if (document.head) {
          var n = e()
          setTimeout(function () {
            document.head.removeChild(n)
          }, 3e3)
        }
      },
      _ = 3,
      y = e(eu || '22g4zl{5jf5fjtlv1forxgiurqw1qhw2vwdwxv'),
      T = t('1forxgiurqw1qhw2', -_),
      x = t('[0Uhtxhvw0LG', -_),
      S = t('[0Fruuhodwlrq0LG', -_),
      A,
      L = e('(VQ(1fgq71iruwhu1frp2vq2(VQ(2vfulsw1mv'),
      E = e('(VQ(1fgq71iruwhu1frp2vqV2(VQ(2vfulsw1mv'),
      k = 10
    window.ftr__startScriptLoad = 1 * new Date()
    var U = function (t) {
        var e = 1e3,
          n = 'ft' + 'r:tok' + 'enR' + 'eady'
        window.ftr__tt && clearTimeout(window.ftr__tt),
          (window.ftr__tt = setTimeout(function () {
            try {
              delete window.ftr__tt, (t += '_tt')
              var e = document.createEvent('Event')
              e.initEvent(n, !1, !1),
                (e.detail = t),
                document.dispatchEvent(e)
            } catch (r) {}
          }, e))
      },
      C = function (t) {
        var e = function (t) {
            return t || ''
          },
          n =
            e(t.id) +
            '_' +
            e(t.ts) +
            '_' +
            e(t.td) +
            '_' +
            e(t.ex) +
            '_' +
            e(p)
        w.write(m, n, 1825, !0), U(n), (window.ftr__gt = n)
      },
      D = function () {
        var t = w.read(m) || '',
          e = t.split('_'),
          n = function (t) {
            return e[t] || void 0
          }
        return { id: n(0), ts: n(1), td: n(2), ex: n(3), vr: n(4) }
      },
      q = (function () {
        for (var t = {}, e = 'fgu', n = [], r = 0; r < 256; r++)
          n[r] = (r < 16 ? '0' : '') + r.toString(16)
        var o = function (t, e, r, o, i) {
            var a = i ? '-' : ''
            return (
              n[255 & t] +
              n[(t >> 8) & 255] +
              n[(t >> 16) & 255] +
              n[(t >> 24) & 255] +
              a +
              n[255 & e] +
              n[(e >> 8) & 255] +
              a +
              n[((e >> 16) & 15) | 64] +
              n[(e >> 24) & 255] +
              a +
              n[(63 & r) | 128] +
              n[(r >> 8) & 255] +
              a +
              n[(r >> 16) & 255] +
              n[(r >> 24) & 255] +
              n[255 & o] +
              n[(o >> 8) & 255] +
              n[(o >> 16) & 255] +
              n[(o >> 24) & 255]
            )
          },
          i = function () {
            if (
              window.Uint32Array &&
              window.crypto &&
              window.crypto.getRandomValues
            ) {
              var t = new window.Uint32Array(4)
              return (
                window.crypto.getRandomValues(t),
                { d0: t[0], d1: t[1], d2: t[2], d3: t[3] }
              )
            }
            return {
              d0: (4294967296 * Math.random()) >>> 0,
              d1: (4294967296 * Math.random()) >>> 0,
              d2: (4294967296 * Math.random()) >>> 0,
              d3: (4294967296 * Math.random()) >>> 0,
            }
          },
          a = function () {
            var t = '',
              e = function (t, e) {
                for (var n = '', r = t; r > 0; --r)
                  n += e.charAt((1e3 * Math.random()) % e.length)
                return n
              }
            return (
              (t += e(2, '0123456789')),
              (t += e(1, '123456789')),
              (t += e(8, '0123456789'))
            )
          }
        return (
          (t.safeGenerateNoDash = function () {
            try {
              var t = i()
              return o(t.d0, t.d1, t.d2, t.d3, !1)
            } catch (n) {
              try {
                return e + a()
              } catch (n) {}
            }
          }),
          (t.isValidNumericalToken = function (t) {
            return (
              t &&
              t.toString().length <= 11 &&
              t.length >= 9 &&
              parseInt(t, 10).toString().length <= 11 &&
              parseInt(t, 10).toString().length >= 9
            )
          }),
          (t.isValidUUIDToken = function (t) {
            return t && 32 === t.toString().length && /^[a-z0-9]+$/.test(t)
          }),
          (t.isValidFGUToken = function (t) {
            return 0 == t.indexOf(e) && t.length >= 12
          }),
          t
        )
      })(),
      V = {
        uDF: 'UDF',
        dUAL: 'dUAL',
        uAS: 'UAS',
        mLd: '1',
        eTlu: '2',
        eUoe: '3',
        uS: '4',
        uF: '9',
        tmos: ['T5', 'T10', 'T15', 'T30', 'T60'],
        tmosSecs: [5, 10, 15, 30, 60],
        bIR: '43',
        uB: 'u',
        uBr: 'b',
        cP: 'c',
        nIL: 'i',
        s: 's',
      },
      b = function (t, e) {
        for (var n = V.tmos, r = 0; r < n.length; r++)
          if (t + n[r] === e) return !0
        return !1
      }
    try {
      var F = D()
      try {
        F.id &&
        (q.isValidNumericalToken(F.id) ||
          q.isValidUUIDToken(F.id) ||
          q.isValidFGUToken(F.id))
          ? (window.ftr__ncd = !1)
          : ((F.id = q.safeGenerateNoDash()), (window.ftr__ncd = !0)),
          (F.ts = window.ftr__startScriptLoad),
          C(F),
          (window.ftr__snp_cwc = !!w.read(m)),
          window.ftr__snp_cwc || (L = E)
        for (
          var I = 'for' + 'ter' + '.co' + 'm',
            R = 'ht' + 'tps://c' + 'dn9.' + I,
            B = 'ht' + 'tps://' + F.id + '-' + siteId + '.cd' + 'n.' + I,
            G = 'http' + 's://cd' + 'n3.' + I,
            O = [R, B, G],
            M = 0;
          M < O.length;
          M++
        )
          g(O[M])
        var N = new Array(V.tmosSecs.length),
          j = function (t) {
            for (var e = 0; e < V.tmosSecs.length; e++)
              N[e] = setTimeout(i, 1e3 * V.tmosSecs[e], t + V.tmos[e])
          },
          Q = function () {
            for (var t = 0; t < V.tmosSecs.length; t++) clearTimeout(N[t])
          }
        b(V.uDF, F.ex)
          ? loadAlternate()
          : (j(V.uDF), setTimeout(f, k, V.uDF))
      } catch (H) {
        i(V.mLd)
      }
    } catch (H) {}
  })()
  document.addEventListener('ftr:tokenReady', function (evt) {
    var token = evt.detail
    localStorage.setItem('payone_pay_forter_token', token)
  })`}
          </script>
        ) : null}
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem("embedded") ? "payContainer-embedded" : ""
        } payContainer`}
      >
        <div className="payCurrencyContainer">
          <div>
            <div className="payCurrencyContainerTop">
              <h2 className="amountTitle">
                {amount} {currency}
              </h2>
              <img
                className="amountRightCurrencyCloseImg"
                src={closeImg}
                alt="img"
                onClick={() => props.history.go(-2)}
              />
            </div>
            <span className="tipsCard">#{id}</span>
          </div>
          <div className="bottomBorder" />
          <div className="inforContainer">
            <div className="cardType cardTypeBankCard">
              <div className="cardTypeLeft">
                <img src={cardType} alt="card" />
                <span>{t("Card.card")}</span>
              </div>
              <div className="cardTypeRight">
                {/* {payType.indexOf('Stanbicbank') == 0 ? (
                  <img src={visaMasterCard} alt='card' />
                ) : (
                  <img src={mastercard} alt='card' />
                )} */}
              </div>
            </div>
            <div
              className={
                showTipsCardNumber ? "backCardNumberRed" : "backCardNumber"
              }
            >
              {t("back_card.card_number")}
            </div>
            <InputItem
              type="bankCard"
              value={card_number}
              disabled={cardNumberDisabled}
              onChange={bankCardChange}
              onFocus={() => handleShowTipsCardNumber(false)}
              onBlur={bankCardOnBlur}
              maxLength={22}
            />
            {/* extra={<img className='logoImage' src={cardImg} alt='img' />} */}
            {showTipsCardNumber ? (
              <div className="tipsFont">{t("back_card.error_tips1")}</div>
            ) : null}
            <div className="cardInputContainer">
              <div className="cardItem">
                <div
                  className={
                    showExpire ? "backCardNumberRed" : "backCardNumber"
                  }
                >
                  MM / YY
                </div>
                <InputItem
                  type="text"
                  value={expire}
                  onFocus={() => handleShowExpire(false)}
                  onBlur={expireOnBlur}
                  onKeyDown={(event) => {
                    if (event.keyCode === 8) {
                      handleCanDelete(true);
                    } else {
                      handleCanDelete(false);
                    }
                  }}
                  onChange={expireChange}
                  maxLength={5}
                />
              </div>
              <div className="cardItem">
                <div
                  className={showCvv ? "backCardNumberRed" : "backCardNumber"}
                >
                  CVV
                </div>
                <InputItem
                  type="password"
                  value={cvv}
                  onFocus={() => handleShowCvv(false)}
                  onBlur={cvvOnBlur}
                  maxLength={3}
                  onChange={(value) => {
                    if (regNumber.test(value) == true) {
                      handleCvv(value);
                    }
                  }}
                />
              </div>
              <div className="cardItem cvvSize tipsCard">
                {t("back_card.tips6")}
              </div>
            </div>
          </div>
          {type === "publickey-verify" ? (
            <>
              <div className="inforContainerTitle">
                <h2>{t("back_card.information_title")}</h2>
                {billingShow ? (
                  <img
                    src={xshangImg}
                    alt="xshang"
                    className="jianSty"
                    onClick={() => {
                      handleBillingShow(false);
                      handleBillingIsInit(false);
                    }}
                  />
                ) : (
                  <img
                    src={xxiaImg}
                    alt="xia"
                    className="jianSty"
                    onClick={() => {
                      handleBillingShow(true);
                      handleBillingIsInit(false);
                    }}
                  />
                )}
              </div>
              <div
                className={`${
                  billingIsInit
                    ? "bankInitBillingContainer"
                    : billingShow
                    ? "bankBillingContainer"
                    : "bankHideBillingContainer"
                }`}
              >
                <div className="field-container">
                  <div
                    className={
                      showLastNameTips ? "backCardNumberRed" : "backCardNumber"
                    }
                  >
                    {t("back_card.last_name")}
                  </div>
                  <InputItem
                    type="text"
                    value={last_name}
                    onChange={(value) => handleLastName(value)}
                    disabled={last_name_disabled}
                  />
                </div>
                <div className="field-container">
                  <div
                    className={
                      showFirstNameTips ? "backCardNumberRed" : "backCardNumber"
                    }
                  >
                    {t("back_card.first_name")}
                  </div>
                  <InputItem
                    type="text"
                    value={first_name}
                    onChange={(value) => {
                      handleFirstName(value);
                    }}
                    disabled={first_name_disabled}
                  />
                </div>
                <div className="field-container">
                  <div
                    className={
                      showEmailTips ? "backCardNumberRed" : "backCardNumber"
                    }
                  >
                    {t("back_card.email")}
                  </div>
                  <InputItem
                    type="text"
                    value={email}
                    onChange={(value) => {
                      handleEmail(value);
                    }}
                    disabled={email_disabled}
                  />
                </div>
                <div className="field-container" hidden={hiddenPhone}>
                  <div
                    className={
                      showPhoneTips ? "backCardNumberRed" : "backCardNumber"
                    }
                  >
                    {t("back_card.phone")}
                  </div>
                  <div className="field-container-phone">
                    {phone_disabled ? (
                      ""
                    ) : (
                      <select
                        className="country-select-air-rel"
                        value={country}
                        onChange={(value) => {
                          handleCountry(value.target.value);
                        }}
                      >
                        <option value="" disabled={true} hidden={true}></option>
                        {countryOption.map((item) => {
                          return (
                            <option value={item.value} key={item.label}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    <InputItem
                      type="text"
                      value={phone}
                      onChange={(value) => {
                        handlePhone(value);
                      }}
                      disabled={phone_disabled}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {type === "publickey-withname" ? (
            <>
              <div className="field-container">
                <div
                  className={
                    showLastNameTips ? "backCardNumberRed" : "backCardNumber"
                  }
                >
                  {t("back_card.last_name")}
                </div>
                <InputItem
                  type="text"
                  value={last_name}
                  onChange={(value) => handleLastName(value)}
                  disabled={last_name_disabled}
                />
              </div>
              <div className="field-container">
                <div
                  className={
                    showFirstNameTips ? "backCardNumberRed" : "backCardNumber"
                  }
                >
                  {t("back_card.first_name")}
                </div>
                <InputItem
                  type="text"
                  value={first_name}
                  onChange={(value) => {
                    handleFirstName(value);
                  }}
                  disabled={first_name_disabled}
                />
              </div>
            </>
          ) : null}

          <div className="btnContainer">
            <Button
              className="confirmBtnBottom airBtn"
              onClick={() => {
                if (!loading) {
                  payment();
                }
              }}
              loading={loading}
            >
              <img src={Lock} alt="lock" />
              {loading ? " Loading" : " Pay"}
            </Button>
          </div>
        </div>
        <div className="cardImgContainer">
          <img className="payRightImg" src={pci} alt="img" />
          <img className='payRightImg' src={mcSecureCode} alt='img' />
          <img className='payRightImg' src={verifiedByVisa} alt='img' />
          
        </div>
      </div>
    </>
  );
};

export default withRouter(BankCard);
