import React, { useRef } from "react";

const NewPaymentChannel = React.forwardRef( (props, ref) => {
  const { formProps } = props
  const {
    bizType,
    txnSubType,
    orderId,
    backUrl,
    signature,
    txnType,
    channelType,
    frontUrl,
    certId,
    encoding,
    version,
    accessType,
    txnTime,
    merId,
    currencyCode,
    signMethod,
    txnAmt,
    accNo,
    encryptCertId,
    reserved,
  } = formProps;
  // const formRef = useRef(null);
  const actionUrl = "https://gateway.95516.com/gateway/api/frontTransReq.do";

  // const handleSubmit = (event) => {
  //   console.log('被调用')
  //   event.preventDefault();
  //   formRef.current.submit();

  // };

  return (
    <form method="POST" action={actionUrl} ref={ref}>
      <input type="hidden" name="bizType" value={bizType} />
      <input type="hidden" name="txnSubType" value={txnSubType} />
      <input type="hidden" name="orderId" value={orderId} />
      <input type="hidden" name="backUrl" value={backUrl} />
      <input type="hidden" name="signature" value={signature} />
      <input type="hidden" name="txnType" value={txnType} />
      <input type="hidden" name="channelType" value={channelType} />
      <input type="hidden" name="frontUrl" value={frontUrl} />
      <input type="hidden" name="certId" value={certId} />
      <input type="hidden" name="encoding" value={encoding} />
      <input type="hidden" name="version" value={version} />
      <input type="hidden" name="accessType" value={accessType} />
      <input type="hidden" name="txnTime" value={txnTime} />
      <input type="hidden" name="merId" value={merId} />
      <input type="hidden" name="currencyCode" value={currencyCode} />
      <input type="hidden" name="signMethod" value={signMethod} />
      <input type="hidden" name="txnAmt" value={txnAmt} />
      {/* extra  optional params  */}
      {accNo&&<input type="hidden" name="accNo" value={accNo} />}
      { encryptCertId&&<input type="hidden" name="encryptCertId" value={encryptCertId} />}
      { reserved&&<input type="hidden"  name="reserved" value={reserved} />}
    </form>
  );
})

export default NewPaymentChannel;
