import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  createElement,
  loadAirwallex,
  getElement,
  confirmPaymentIntent,
} from 'airwallex-payment-elements'
import { Icon, Button, InputItem } from 'antd-mobile'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import cardType from '../../assets/images/cardType.svg'
import visaCard from '../../assets/images/cardType1.svg'
import mastercard from '../../assets/images/cardType2.svg'
import cardType3 from '../../assets/images/cardType3.svg'
import Lock from '../../assets/images/lock.svg'
import chaImg from '../../assets/images/airCha.svg'
import countryList from '../../utils/Country.json'
import Header from '../Components/Header'
import { getCheckout } from '../../utils/api'
import './index.css'

const Airwallex = (props) => {
  const { t } = useTranslation()
  const [id, handleId] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')
  const [returnUrl, handleReturnUrl] = useState('')
  const [amount, handleAmount] = useState('')
  const [currency, handleCurrency] = useState('')
  const [clientSecret, handleClientSecret] = useState('')
  const [AirId, handleAirId] = useState('')
  const [countryOption, handleCountryOption] = useState([])
  const [billingShow, handleBillingShow] = useState(false)
  const [billingIsInit, handleBillingIsInit] = useState(true)

  const [last_name, handleLastName] = useState('')
  const [first_name, handleFirstName] = useState('')
  const [country, handleCountry] = useState('')
  const [state, handleState] = useState('')
  const [city, handleCity] = useState('')
  const [address, handleAddress] = useState('')
  const [zipcode, handleZipcode] = useState('')
  const [email, handleEmail] = useState('')
  const [phone, handlePhone] = useState('')

  const [cardNumberReady, setCardNumberReady] = useState(false)
  const [cvcReady, setCvcReady] = useState(false)
  const [expiryReady, setExpiryReady] = useState(false)

  const [cardNumberFocus, setCardNumberFocus] = useState(false)
  const [cvcFocus, setCvcFocus] = useState(false)
  const [expiryFocus, setExpiryFocus] = useState(false)

  const [cardNumberIsInit, setCardNumberIsInit] = useState(true)
  const [cvcIsInit, setCvcIsInit] = useState(true)
  const [expiryIsInit, setExpiryIsInit] = useState(true)

  const [cardNumberComplete, setCardNumberComplete] = useState(false)
  const [cvcComplete, setCvcComplete] = useState(false)
  const [expiryComplete, setExpiryComplete] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    initOption()
    if (props.match.params.id) {
      handleId(props.match.params.id)
      initOrder(props.match.params.id)
    } else {
      message.error('order number no exist')
    }
  }, [props.match.params.id])

  useEffect(() => {
    loadAirwallex({
      env: 'prod',
      origin: window.location.origin,
      fonts: [
        {
          src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
          family: 'AxLLCircular',
          weight: 400,
        },
      ],
    }).then(() => {
      const cardNumEle = createElement('cardNumber')
      const cvcEle = createElement('cvc')
      const expiryEle = createElement('expiry')
      cardNumEle.mount('cardNumber')
      cvcEle.mount('cvc')
      expiryEle.mount('expiry')
    })

    const onReady = (event) => {
      const { type } = event.detail
      if (type === 'cardNumber') {
        setCardNumberReady(true)
      }
      if (type === 'cvc') {
        setCvcReady(true)
      }
      if (type === 'expiry') {
        setExpiryReady(true)
      }
    }

    const onFocus = (event) => {
      const { type } = event.detail
      if (type === 'cardNumber') {
        setCardNumberFocus(true)
        setCardNumberIsInit(false)
      } else {
        setCardNumberFocus(false)
      }
      if (type === 'cvc') {
        setCvcFocus(true)
        setCvcIsInit(false)
      } else {
        setCvcFocus(false)
      }
      if (type === 'expiry') {
        setExpiryFocus(true)
        setExpiryIsInit(false)
      } else {
        setExpiryFocus(false)
      }
    }

    const onBlur = (event) => {
      const { type } = event.detail
      if (type === 'cardNumber') {
        setCardNumberFocus(false)
      }
      if (type === 'cvc') {
        setCvcFocus(false)
      }
      if (type === 'expiry') {
        setExpiryFocus(false)
      }
    }
    const onChange = (event) => {
      const { type, complete } = event.detail
      if (type === 'cardNumber') {
        setCardNumberComplete(complete)
      }
      if (type === 'cvc') {
        setCvcComplete(complete)
      }
      if (type === 'expiry') {
        setExpiryComplete(complete)
      }
    }

    window.addEventListener('onReady', onReady)
    window.addEventListener('onFocus', onFocus)
    window.addEventListener('onBlur', onBlur)
    window.addEventListener('onChange', onChange)

    return () => {
      window.removeEventListener('onReady', onReady)
      window.removeEventListener('onFocus', onFocus)
      window.removeEventListener('onBlur', onBlur)
      window.removeEventListener('onChange', onChange)
    }
  }, [])

  const initOption = () => {
    let countryOptions = []
    countryList.map((item) => {
      countryOptions.push({
        value: item.countryCode,
        label: item.name_en,
      })
    })
    handleCountryOption(countryOptions)
  }

  const initOrder = (id) => {
    let params = {
      id,
      channel: 'pt',
    }
    getCheckout(params)
      .then((response) => {
        let res = response.data
        if (res.code === 0) {
          handleLogo(res.result.logo)
          handleTitle(res.result.title)
          handleAmount(res.result?.amount || '')
          handleCurrency(res.result?.currency || '')
          handleReturnUrl(res.result?.return_url || '')
          handleClientSecret(
            res.result?.data?.airwallex_intent_order_dto.client_secret
          )
          handleAirId(res.result?.data?.airwallex_intent_order_dto.id)
          handleLastName(res.result?.data?.customer.last_name)
          handleFirstName(res.result?.data?.customer.first_name)
          handleCountry(res.result?.data?.customer.country)
          handleState(res.result?.data?.customer.state)
          handleCity(res.result?.data?.customer.city)
          handleAddress(res.result?.data?.customer.address)
          handleZipcode(res.result?.data?.customer.zipcode)
          handleEmail(res.result?.data?.customer.email)
          handlePhone(res.result?.data?.customer.phone)
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err.msg)
      })
  }

  const handleConfirm = async () => {
    setIsSubmitting(true)
    const cardNumElement = getElement('cardNumber')
    if (billingShow) {
      confirmPaymentIntent({
        element: cardNumElement,
        id: AirId,
        client_secret: clientSecret,
        payment_method_options: {
          card: {
            auto_capture: true,
          },
        },
        payment_method: {
          billing: {
            email: email,
            first_name: first_name,
            last_name: last_name,
            phone_number: phone,
            address: {
              city: city,
              country_code: country,
              postcode: zipcode,
              state: state,
              street: address,
            },
          },
        },
      })
        .then((response) => {
          setIsSubmitting(false)
          props.history.push(`../result-success/?id=${id}`)
        })
        .catch((error) => {
          setIsSubmitting(false)
          message.error(JSON.stringify(error.message))
        })
    } else {
      confirmPaymentIntent({
        element: cardNumElement,
        id: AirId,
        client_secret: clientSecret,
        payment_method_options: {
          card: {
            auto_capture: true,
          },
        },
      })
        .then((response) => {
          setIsSubmitting(false)
          props.history.push(`../result-success/?id=${id}`)
        })
        .catch((error) => {
          setIsSubmitting(false)
          message.error(JSON.stringify(error.message))
        })
    }
  }

  const allElementsReady = cardNumberReady && cvcReady && expiryReady
  const allElementsComplete =
    cardNumberComplete && cvcComplete && expiryComplete

  const renderLoading = () => (
    <div className='loadingDiv'>
      <div>{t('home.loading')}</div>
      <Icon
        type='loading'
        className='spe'
        size='lg'
        style={{ fill: '#0045ff', marginTop: '10px' }}
      />
    </div>
  )

  const goBack = () => {
    if (returnUrl) {
      window.location.href = returnUrl
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'airContainer-embedded' : ''
        } airContainer`}
      >
        <div className='payAirContainer'>
          <div>
            <div className='topSty'>
              <h2 className='amountTitle'>
                {amount} {currency}
              </h2>
              <div className='topStyRightContainer' onClick={goBack}>
                <img src={chaImg} />
              </div>
            </div>
            <span className='tipsCard'>#{id}</span>
          </div>
          <div className='bottomBorder airBottomBorder' />
          <div className='cardType'>
            <div className='cardTypeLeft'>
              <img src={cardType} alt='card' />
              <span>{t('Card.card')}</span>
            </div>
            <div className='cardTypeRight'>
              <img src={mastercard} alt='card' />
              <img src={visaCard} alt='card' />
              <img src={cardType3} alt='card' />
            </div>
          </div>
          <div style={{ display: allElementsReady ? 'block' : 'none' }}>
            <div className='field-container'>
              <div className='field-label'>{t('Card.cardNumber')}</div>
              <div
                id='cardNumber'
                className={cardNumberFocus ? 'airInputFocus' : 'airInput'}
              />
              {!cardNumberIsInit && !cardNumberComplete ? (
                <div className='errorTips'>
                  {t('Card.cardNumberIsRequired')}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='field-container'>
              <div className='field-label'>{t('Card.expirationDate')}</div>
              <div
                id='expiry'
                className={expiryFocus ? 'airInputFocus' : 'airInput'}
              />
              {!expiryIsInit && !expiryComplete ? (
                <div className='errorTips'>
                  {t('Card.expiryDateIsRequired')}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='field-container'>
              <div className='field-label'>{t('Card.SecurityCode')}</div>
              <div
                id='cvc'
                className={cvcFocus ? 'airInputFocus' : 'airInput'}
              />
              {!cvcIsInit && !cvcComplete ? (
                <div className='errorTips'>
                  {t('Card.SecurityCodeIsRequired')}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='billing'>{t('back_card.information_title')}</div>
            <input
              type='checkbox'
              id='agree'
              name='agree'
              value='agree'
              onClick={(e) => {
                handleBillingShow(!billingShow)
                handleBillingIsInit(false)
              }}
            />
            <div
              className={`${
                billingIsInit
                  ? 'initBillingContainer'
                  : billingShow
                  ? 'billingContainer'
                  : 'hideBillingContainer'
              }`}
            >
              <div className='field-container'>
                <div className='field-label'>{t('back_card.first_name')}</div>
                <InputItem
                  type='text'
                  value={first_name}
                  onChange={(value) => {
                    handleFirstName(value)
                  }}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('back_card.last_name')}</div>
                <InputItem
                  type='text'
                  value={last_name}
                  onChange={(value) => handleLastName(value)}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.streetAddress')}</div>
                <InputItem
                  type='text'
                  value={address}
                  onChange={(value) => {
                    handleAddress(value)
                  }}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.zipCode')}</div>
                <InputItem
                  type='text'
                  value={zipcode}
                  onChange={(value) => {
                    handleZipcode(value)
                  }}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.city')}</div>
                <InputItem
                  type='text'
                  value={city}
                  onChange={(value) => {
                    handleCity(value)
                  }}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.state')}</div>
                <InputItem
                  type='text'
                  value={state}
                  onChange={(value) => {
                    handleState(value)
                  }}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.countryRegion')}</div>
                <select
                  className='country-select-air'
                  value={country}
                  onChange={(value) => {
                    handleCountry(value.target.value)
                  }}
                >
                  <option value='' disabled={true} hidden={true}></option>
                  {countryOption.map((item) => {
                    return (
                      <option value={item.value} key={item.label}>
                        {item.label}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.email')}</div>
                <InputItem
                  type='text'
                  value={email}
                  onChange={(value) => {
                    handleEmail(value)
                  }}
                />
              </div>
              <div className='field-container'>
                <div className='field-label'>{t('Card.phone')}</div>
                <InputItem
                  type='text'
                  value={phone}
                  onChange={(value) => {
                    handlePhone(value)
                  }}
                />
              </div>
            </div>
            <Button
              type='primary'
              className='confirmBtnBottom airBtn'
              onClick={handleConfirm}
              disabled={!allElementsComplete || isSubmitting}
            >
              <img src={Lock} alt='lock' />
              {isSubmitting ? ' Loading' : ' Pay'} {amount}({currency})
            </Button>
          </div>
          {allElementsReady ? null : renderLoading()}
        </div>
      </div>
    </>
  )
}

export default withRouter(Airwallex)
