import React, { useState, useEffect } from 'react'
import i18n from '../../react-i18next-config'
import languageEarth from '../../assets/images/languageEarth.svg'
import { websiteUrl } from '../../utils/config'
import './index.css'

const Footer = () => {
  const [language, setLanguage] = useState('en')

  useEffect(() => {
    let type = localStorage.getItem('i18nextLng')
    if (type && type !== 'zh-CN') {
      setLanguage(type)
    }
  }, [])

  const changeLanguage = (value) => {
    setLanguage(value)
    i18n.changeLanguage(value)
  }

  return sessionStorage.getItem('embedded') ? null : (
    <div className='defaultFooter'>
      <div className='footerContainer'>
        <a className='footerLink' href='mailto:support@inst.money'>
          {i18n.t('Footer.support')}
        </a>
      </div>
      <div className='footerContainerRight'>
        <div className='top'>
          <img className='languageSty' src={languageEarth} alt='language' />
          <div className='languageText'>{language.toUpperCase()}</div>
        </div>
        <div className='tips'>
          <ul>
            <li onClick={() => changeLanguage('en')}>English</li>
            <li onClick={() => changeLanguage('it')}>Italian</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default Footer
