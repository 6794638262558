import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Select, Table, Button, message } from 'antd'
import BigNumber from 'bignumber.js'
import USD from '../../../../assets/images/USD.svg'
import EUR from '../../../../assets/images/EUR.svg'
import SepaIcon from '../../../../assets/images/sepa.png'
import ACHIcon from '../../../../assets/images/ACHIcon.jpg'
import MasterCard from '../../../../assets/images/masterCard1.svg'
import './index.css'

const Deposit = (props) => {
  const fromCurrencyRef = useRef()
  const fromCurrencyOption = [
    { value: 'USD', label: 'USD', logo: USD },
    { value: 'EUR', label: 'EUR', logo: EUR },
  ]

  const { Option } = Select
  const { Column } = Table

  const [isIos, handleIsIos] = useState(false)
  const [status, handleStatus] = useState(0)
  const [amount, handleAmount] = useState(0)
  const [fromCurrency, handleFromCurrency] = useState('EUR')
  const [fromCurrencyOptionOpen, handleFromCurrencyOptionOpen] = useState(false)

  const [settingData, handleSettingData] = useState([])
  const [walletList, handleWalletList] = useState([])
  const [creditData, handleCreditData] = useState([])
  const [bankCardsData, handleBankCardsData] = useState([])

  useEffect(() => {
    //判断是否为ios
    let u = navigator.userAgent
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    handleIsIos(isiOS)
  }, [])

  useEffect(() => {
    handleSettingData(props.settingData)
    handleWalletList(props.walletList)
    handleStatus(props.status)

    handleCreditData([
      {
        logo: MasterCard,
        feePercent: `${props.settingData?.card_fee_rate * 100}%`,
        completion: '0.25 - 8 hours',
        key: 'masterCard',
      },
    ])
    handleBankCardsData([
      {
        logo: SepaIcon,
        feePercent: `${props.settingData?.sepa_fee_rate * 100}%`,
        completion: '0 - 2 working days',
        deposit: 'SEPA',
        key: 'SEPA',
      },
      {
        logo: ACHIcon,
        feePercent: `${props.settingData?.ach_fee_rate * 100}%`,
        completion: '1 - 3 working days',
        deposit: 'ACH',
        key: 'ACH',
      },
    ])
  }, [props])

  const gotoVerify = () => {
    props.history.push('/buyCrypto/kycCenter')
  }

  const amountChange = (e) => {
    handleAmount(e.target.value)
  }

  const fromCurrencyChange = (value) => {
    if (isIos) {
      handleFromCurrencyOptionOpen(false)
      fromCurrencyRef.current.blur()
    }
    handleFromCurrency(value)
  }

  const renderFromCurrency = () => {
    if (isIos) {
      return (
        <Select
          ref={fromCurrencyRef}
          defaultValue='USD'
          value={fromCurrency}
          onChange={fromCurrencyChange}
          open={fromCurrencyOptionOpen}
          onFocus={() => handleFromCurrencyOptionOpen(true)}
          onBlur={() => handleFromCurrencyOptionOpen(false)}
        >
          {fromCurrencyOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                <img
                  src={item.logo}
                  alt={item.label}
                  style={{ width: '24px', marginRight: '10px' }}
                />
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    } else {
      return (
        <Select
          ref={fromCurrencyRef}
          defaultValue='USD'
          value={fromCurrency}
          onChange={fromCurrencyChange}
        >
          {fromCurrencyOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                <img
                  src={item.logo}
                  alt={item.label}
                  style={{ width: '24px', marginRight: '10px' }}
                />
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    }
  }

  const creditDeposit = (key, item) => {
    if (
      Number(amount) > Number(settingData?.card_max_amount) ||
      Number(amount) < Number(settingData?.card_min_amount)
    ) {
      message.error(
        `please enter an amount between ${settingData?.card_min_amount} and ${settingData?.card_max_amount}`
      )
    } else {
      let address = ''
      let fee = ''
      let creditAmount = ''
      walletList.map((item) => {
        if (item.currency === fromCurrency) {
          address = item.address
        }
      })
      fee = new BigNumber(settingData.card_fee_rate)
        .multipliedBy(amount)
        .toFixed(2 ,BigNumber.ROUND_DOWN)
      creditAmount =
        amount -
        BigNumber(settingData.card_fee_rate).multipliedBy(amount).toFixed(2 ,BigNumber.ROUND_DOWN)
      props.history.push(
        `/buyCrypto/wallet/creditDeposit?amount=${amount}&address=${address}&fee=${fee}&creditAmount=${creditAmount}&from_currency=${fromCurrency}`
      )
    }
  }

  const bankTransferDeposit = (key, item) => {
    if (
      Number(amount) > Number(settingData?.sepa_max_amount) ||
      Number(amount) < Number(settingData?.sepa_min_amount)
    ) {
      message.error(
        `please enter an amount between ${settingData?.sepa_min_amount} and ${settingData?.sepa_max_amount}`
      )
    } else {
      let address = ''
      walletList.map((item) => {
        if (item.currency === fromCurrency) {
          address = item.address
        }
      })
      props.history.push(
        `/buyCrypto/wallet/bankDeposit?amount=${amount}&from_currency=${fromCurrency}&bankName=${item.deposit}&address=${address}`
      )
    }
  }

  return (
    <div className='deposit_container'>
      {status !== 2 ? (
        <div className='tips_container'>
          <div className='tips'>
            In order to ensure the safety of your assets, to minimize the risk
            of the account, you need to complete the following conditions before
            you can carry out legal currency business operations：
          </div>
          <div className='tips_btn_container'>
            <div className='tips_btn'>
              <img src='../../../assets/tips_svg.svg' alt='' />
              <div className='tips_w'>Complete LV2 Authentication</div>
            </div>
            <div className='tips_btn tips_btn_right' onClick={gotoVerify}>
              <div className='tips_g'>Verify</div>
              <img src='../../../assets/tips_right.svg' alt='' />
            </div>
          </div>
        </div>
      ) : null}
      <div className='wrapper-left-item'>
        <div className='select-label'>Currency</div>
        <div className='select-container'>
          <Input
            placeholder='00.00'
            value={amount}
            size='large'
            onChange={amountChange}
            type='number'
          ></Input>
          {renderFromCurrency()}
        </div>
      </div>
      <div className='wrapper-left-item'>
        <div className='select-label'>Credit/debit cards</div>
        <div className='table-container'>
          <Table dataSource={creditData} pagination={false}>
            <Column
              title='Card'
              dataIndex='logo'
              key='logo'
              render={(key) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={key}
                    alt='logo'
                    style={{ width: '36px', marginRight: '4px' }}
                  />
                  MasterCard
                </div>
              )}
            />
            <Column title='Fee%' dataIndex='feePercent' key='feePercent' />
            <Column
              title='Completion'
              dataIndex='completion'
              key='completion'
            />
            <Column
              title='Operation'
              dataIndex='operation'
              key='operation'
              render={(key, item) => (
                <Button type='primary' onClick={() => creditDeposit(key, item)}>
                  Deposit
                </Button>
              )}
            />
          </Table>
        </div>
      </div>
      <div className='wrapper-left-item'>
        <div className='select-label'>Bank transfers</div>
        <div className='table-container'>
          <Table dataSource={bankCardsData} pagination={false}>
            <Column
              title='Bank'
              dataIndex='logo'
              key='logo'
              render={(key, item) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={key}
                    alt='logo'
                    style={{ width: '36px', marginRight: '4px' }}
                  />
                  {item.deposit}
                </div>
              )}
            />
            <Column title='Fee%' dataIndex='feePercent' key='feePercent' />
            <Column
              title='Completion'
              dataIndex='completion'
              key='completion'
            />
            <Column
              title='Operation'
              dataIndex='operation'
              key='operation'
              render={(key, item) => (
                <Button
                  type='primary'
                  disabled={
                    status !== 2 ||
                    (item.deposit === 'ACH' && fromCurrency === 'EUR') ||
                    (item.deposit === 'SEPA' && fromCurrency === 'USD')
                  }
                  onClick={() => bankTransferDeposit(key, item)}
                >
                  Deposit
                </Button>
              )}
            />
          </Table>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Deposit)
