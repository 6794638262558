import React, { useState, useEffect } from 'react'
import { Spin, Tabs, Pagination } from 'antd'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { userWallet, getWalletHistory } from '../../../utils/api'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import Deposit from './Components/Deposit'
import SuccessImg from '../../../assets/images/successImg.svg'
import PendingImg from '../../../assets/images/pendingImg.svg'
import ErrorImg from '../../../assets/images/errorImg.svg'
import ExpireImg from '../../../assets/images/expireImg.svg'
import USD from '../../../assets/images/USDNew.svg'
import EUR from '../../../assets/images/EUR.svg'
import './index.css'

const Wallet = (props) => {
  const [walletList, handleWalletList] = useState([])
  const [current, handleCurrent] = useState(1)
  const [page_num, handlePageNum] = useState(0)
  const [page_size, handlePageSize] = useState(5)
  const [listTab, handleListTab] = useState([])
  const [total, handleTotal] = useState(0)
  const [loading, handleLoading] = useState(false)

  const [status, handleStatus] = useState(0)
  const [settingData, handleSettingData] = useState({})
  const [activeKey, handleActiveKey] = useState('deposit')

  useEffect(() => {
    if (getParams()?.activeKey) {
      handleActiveKey(getParams()?.activeKey)
    }
  }, [])

  useEffect(() => {
    const authorization = localStorage.getItem('authorization')
    userWallet(authorization).then((res) => {
      let list = []
      if (res.code === 0) {
        handleStatus(res.result.status)
        handleSettingData(res.result.settings)
        res.result.wallets.map((item) => {
          if (item.currency === 'USD') {
            item.fiat = 'Dollar'
            item.logo = USD
          } else if (item.currency === 'EUR') {
            item.fiat = 'Euro'
            item.logo = EUR
          }
          list.push(item)
        })
        handleWalletList(list)
      }
    })
    let params = {
      page_num: page_num,
      page_size,
    }
    getHistory(params)
  }, [])

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  const getHistory = (params) => {
    const authorization = localStorage.getItem('authorization')
    handleLoading(true)
    getWalletHistory(params, authorization)
      .then((res) => {
        handleLoading(false)
        if (res.code === 0) {
          handleListTab(res.result?.records || [])
          handleTotal(res.result?.total)
        }
      })
      .catch(() => {
        handleLoading(false)
      })
  }

  const pageChange = (page) => {
    handleCurrent(page)
    let params = {
      page_num: page - 1,
      page_size,
    }
    getHistory(params)
  }

  const renderStatusImg = (status) => {
    if (status === 0) {
      return <img src={PendingImg} />
    } else if (status === 1) {
      return <img src={SuccessImg} />
    } else if (status === 2) {
      return <img src={PendingImg} />
    } else if (status === 3) {
      return <img src={SuccessImg} />
    } else if (status === 4) {
      return <img src={ErrorImg} />
    } else if (status === 5) {
      return <img src={ErrorImg} />
    } else if (status === 6) {
      return <img src={ExpireImg} />
    } else if (status === 7) {
      return <img src={PendingImg} />
    } else if (status === 8) {
      return <img src={SuccessImg} />
    } else if (status === 9) {
      return <img src={ErrorImg} />
    } else {
      return <img src={ErrorImg} />
    }
  }

  const renderType = (item) => {
    let returnText = '-'
    switch (item.tx_type) {
      case '01':
        returnText = 'Card Deposit'
        break
      case '02':
        returnText = 'Bank Deposit'
        break
      case '03':
        returnText = 'Transfer Out'
        break
      case '04':
        returnText = 'Transfer In'
        break
      case '05':
        returnText = 'Buy Crypto'
        break
    }
    return returnText
  }

  const renderList = () => {
    return listTab.map((item) => {
      return (
        <div
          className='listContainer'
          key={item.id}
          onClick={() => {
            props.history.push(`/buyCrypto/walletDetails?tx_id=${item.tx_id}`)
          }}
        >
          <div className='listLeft'>
            {renderStatusImg(item.status)}
            <div className='listLeftTitle'>
              <div className='listLeftTitleTop'>{renderType(item)}</div>
              <div className='listLeftTitleBottom'>
                {moment(item.create_time).format('YYYY/MM/DD HH:mm')}
              </div>
            </div>
          </div>
          <div className='listRight'>
            {item.amount} {item.currency}
          </div>
        </div>
      )
    })
  }

  const activeKeyChange = (e) => {
    handleActiveKey(e)
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'walletContainer-embedded' : ''
        } walletContainer`}
      >
        <HeaderTop title='Wallet' />
        {walletList.map((item) => {
          return (
            <div className='wallet-list-container' key={item.fiat}>
              <div className='top-title'>
                <div className='top-title-top'>
                  <img src={item.logo} alt='logo' />
                  {item.fiat}
                </div>
                <div>{item.balance}</div>
              </div>
              <div className='top-value'>
                <div>{item.address}</div>
                <div>{item.currency}</div>
              </div>
            </div>
          )
        })}
        <Tabs activeKey={activeKey} onChange={activeKeyChange}>
          <Tabs.TabPane tab='Deposit' key='deposit'>
            <Deposit
              status={status}
              settingData={settingData}
              walletList={walletList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='History' key='history'>
            <Spin spinning={loading}>
              <div className='listContent'>{renderList()}</div>
            </Spin>
            <div className='pageSty'>
              <Pagination
                simple
                current={current}
                total={total}
                onChange={pageChange}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(Wallet)
