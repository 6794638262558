import { useTranslation } from 'react-i18next'
import './index.css'
// import instLogo from '../../assets/images/logo.png'
import instLogo from '../../assets/images/logoNew.svg'

const Header = (props) => {
  const { t } = useTranslation()
  if (sessionStorage.getItem('embedded', true)) {
    //特殊处理内嵌样式
    document.body.style.backgroundColor = '#fff !important'
  } else {
    document.body.style.backgroundColor = '#f8f8f8 !important'
  }
  return sessionStorage.getItem('embedded') ? null : (
    <div className='defaultLayout'>
      <div className='headerContainer'>
        <div className='headerLeft'>
          {/* <img className='logoImage' src={props.logo || instLogo} alt='img' />
          <div className='title'>{props.title || 'Inst'}</div> */}
          <img className='logoImage' src={props.logo || instLogo} alt='img' />
        </div>
        {props.hasCancel ? (
          <div className='headerRight' onClick={() => props.cancel()}>
            {t('Header.cancelOrder')}
          </div>
        ) : null}
      </div>
      {props.children}
    </div>
  )
}
export default Header
