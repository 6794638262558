import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import history from './utils/history'
import {
  Home,
  Pay,
  BankCard,
  ThreeDS,
  Checkout,
  Airwallex,
  CloudFlashPayment,
  ResultSuccess,
  ResultTimeout,
  ResultFail,
  ResultCancel,
  ResultPaymentConfirming,
  ResultPaymentError,
  ResultPaymentInProgress,
  ResultRefundFail,
  ResultRefundInProgress,
  ResultRefundSuccess,
  Login,
  Verification,
  Kyc,
  KycCenter,
  KycResult,
  FastBuy,
  FastBuyAddress,
  Setting,
  FastBuyHistory,
  HistoryDetails,
  CardManagement,
  AddBindCard,
  BindCardResult,
  ChooseBindCard,
  Wallet,
  WalletHistoryDetails,
  CreditDeposit,
  BankDeposit,
  Sepa,
} from './Pages'

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/pay/:id'>
          <Pay />
        </Route>
        <Route exact path='/bank-card/:id'>
          <BankCard />
        </Route>
        <Route exact path='/3ds'>
          <ThreeDS />
        </Route>
        <Route exact path='/cko/:id'>
          <Checkout />
        </Route>
        <Route exact path='/awx/:id'>
          <Airwallex />
        </Route>
        <Route exact path='/quick-pass/:id'>
          <CloudFlashPayment />
        </Route>
        <Route exact path='/result-success'>
          <ResultSuccess />
        </Route>
        <Route exact path='/result-timeout'>
          <ResultTimeout />
        </Route>
        <Route exact path='/result-fail'>
          <ResultFail />
        </Route>
        <Route exact path='/result-cancel'>
          <ResultCancel />
        </Route>
        <Route exact path='/result-payment-confirming'>
          <ResultPaymentConfirming />
        </Route>
        <Route exact path='/result-payment-error'>
          <ResultPaymentError />
        </Route>
        <Route exact path='/result-payment-inProgress'>
          <ResultPaymentInProgress />
        </Route>
        <Route exact path='/result-refund-fail'>
          <ResultRefundFail />
        </Route>
        <Route exact path='/result-refund-inProgress'>
          <ResultRefundInProgress />
        </Route>
        <Route exact path='/result-refund-success'>
          <ResultRefundSuccess />
        </Route>
        <Route exact path='/buyCrypto/login'>
          <Login />
        </Route>
        <Route exact path='/buyCrypto/verification/:email'>
          <Verification />
        </Route>
        <Route exact path='/buyCrypto/kyc'>
          <Kyc />
        </Route>
        <Route exact path='/buyCrypto/kycCenter'>
          <KycCenter />
        </Route>
        <Route exact path='/buyCrypto/kycResult'>
          <KycResult />
        </Route>
        <Route exact path='/buyCrypto/fastBuy'>
          <FastBuy />
        </Route>
        <Route exact path='/buyCrypto/address'>
          <FastBuyAddress />
        </Route>
        <Route exact path='/buyCrypto/setting'>
          <Setting />
        </Route>
        <Route exact path='/buyCrypto/history'>
          <FastBuyHistory />
        </Route>
        <Route exact path='/buyCrypto/historyDetails'>
          <HistoryDetails />
        </Route>
        <Route exact path='/buyCrypto/cardManagement'>
          <CardManagement />
        </Route>
        <Route exact path='/buyCrypto/addBindCard'>
          <AddBindCard />
        </Route>
        <Route exact path='/buyCrypto/bindCardResult'>
          <BindCardResult />
        </Route>
        <Route exact path='/buyCrypto/chooseCard'>
          <ChooseBindCard />
        </Route>
        <Route exact path='/buyCrypto/wallet'>
          <Wallet />
        </Route>
        <Route exact path='/buyCrypto/walletDetails'>
          <WalletHistoryDetails />
        </Route>
        <Route exact path='/buyCrypto/wallet/creditDeposit'>
          <CreditDeposit />
        </Route>
        <Route exact path='/buyCrypto/wallet/bankDeposit'>
          <BankDeposit />
        </Route>
        <Route exact path='/sepa/:id'>
          <Sepa />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
