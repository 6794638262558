import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import Header from '../Components/Header'
import { getCheckout } from '../../utils/api'
import {  Button } from 'antd-mobile'
import './index.css'

const Sepa = (props) => {
  const { t } = useTranslation()
  const [amount, handleAmount] = useState('')
  const [currency, handleCurrency] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')
  const [id, handleId] = useState('')

  const [speaData, setSpeaData] = useState({ bank: {} });

  useEffect(() => {
    if (props.match.params.id) {
      handleId(props.match.params.id)
      initOrder(props.match.params.id)
    } else {
      message.error('order number no exist')
    }
  }, [])

  const initOrder = (id) => {
    let params = {
      id,
      channel: 'pt',
    }
    getCheckout(params)
      .then((response) => {
        let res = response.data
        if (res.code === 0) {
          if (res.result.status === 6) {
            props.history.push(`result-timeout/?id=${id}`)
          } else {
            handleAmount(res.result.amount)
            handleCurrency(res.result.currency)
            handleLogo(res.result.logo)
            handleTitle(res.result.title)
            console.log(res.result?.data?.sepa_data)
            setSpeaData(res.result?.data?.sepa_data);
          }
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${sessionStorage.getItem('embedded') ? 'payContainer-embedded' : ''
          } payContainer`}
      >
        <div className='payCurrencyContainer'>
          <div>
            <h2 className='amountTitle'>
              {amount} {currency}
            </h2>
            <span className='tipsCard'>#{id}</span>
          </div>
          <div className='bottomBorder' />
          <div className='sepaFormContainer'>
            <div className="attention_tips">
              Please transfer the fund within 4 hours. Send the order reference code
              with the transaction so we can know it’s from you.
            </div>
            <div className="attention_tips_tips">
              Make a payment to Inst using the following bank account details .
            </div>
            <div className="info_title">Bank information</div>
            <div className="deposit_bank_msg_container">
              <div className="deposit_bank_msg_container_left">
                <div className="deposit_bank_msg_container_line">
                  <div className="line_left">Account Name</div>
                  <div className="line_right">
                    {speaData?.account_name}
                    <span className="copyBtn"></span>
                  </div>
                </div>
                <div className="deposit_bank_msg_container_line">
                  <div className="line_left">IBAN</div>
                  <div className="line_right">
                    {speaData?.account_id}
                    <span className="copyBtn"></span>
                  </div>
                </div>
                <div className="deposit_bank_msg_container_line">
                  <div className="line_left">Bank Name</div>
                  <div className="line_right">
                    {speaData?.bank_name}
                    <span className="copyBtn"></span>
                  </div>
                </div>
                <div className="deposit_bank_msg_container_line">
                  <div className="line_left">BIC(SWIFT Code)</div>
                  <div className="line_right">
                    {speaData?.bic}
                    <span className="copyBtn"></span>
                  </div>
                </div>
                <div className="deposit_bank_msg_container_line">
                  <div className="line_left">Bank Address</div>
                  <div className="line_right">
                    {speaData?.bank_address}
                    <span className="copyBtn"></span>
                  </div>
                </div>
                <div className="deposit_bank_msg_container_line">
                  <div className="line_left">Reference Code</div>
                  <div className="line_right">
                    {speaData?.postscript}
                    <span className="copyBtn"></span>
                  </div>
                </div>

              </div>

              <div class="cus-alert" role="alert">
                  <div class="cus-alert-content">
                    <div class="cus-alert-description">Reference code MUST be written correctly to accept your deposit.</div>
                  </div>
                </div>

            </div>
            
          </div>



        <Button
              type='primary'
              className='confirmBtnBottom airBtn'
              onClick={() => {
                props.history.push(`/?id=${id}`)
              }}
            >
              Confirm
            </Button>
        </div>


      </div>
      
    </>
  )
}

export default withRouter(Sepa)
