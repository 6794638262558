import React, { useState, useEffect } from 'react'
import { Input, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { RegCoin } from '../../../utils/reg.js'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import WarningImg from '../../../assets/images/warningImg.svg'
import NEO from '../../../assets/images/NEO.png'
import BNEO from '../../../assets/images/BNEO.png'
import USDT from '../../../assets/images/USDT.png'
import GAS from '../../../assets/images/gasImg.svg'
import FLM from '../../../assets/images/FLM.png'

import './index.css'

const FastBuyAddress = (props) => {
  const [channel_name, handleChannelName] = useState('')
  const [amount, handleAmount] = useState('')
  const [approximatelyAmount, handleApproximatelyAmount] = useState('')
  const [fromCurrency, handleFromCurrency] = useState('USD')
  const [toCurrency, handleToCurrency] = useState('')
  const [rate, handleRate] = useState('')
  const [walletAddress, handleWalletAddress] = useState('')
  const [showAddressTips, handleShowAddressTips] = useState(false)
  const [payDisabled, handlePayDisabled] = useState(false)
  const [returnUrl, handleReturnUrl] = useState('')
  const [custOrderId, handleCustOrderId] = useState('')

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  useEffect(() => {
    if (getParams().from_currency) {
      handleFromCurrency(getParams().from_currency)
    }
    if (getParams().to_crypto) {
      handleToCurrency(getParams().to_crypto)
    }
    if (getParams().amount) {
      handleAmount(getParams().amount)
    }
    if (getParams().approximatelyAmount) {
      handleApproximatelyAmount(getParams().approximatelyAmount)
    }
    if (getParams().channel_name) {
      handleChannelName(getParams().channel_name)
    }
    if (getParams().rate) {
      handleRate(getParams().rate)
    }
    if (getParams().walletAddress) {
      handleWalletAddress(getParams().walletAddress)
    }
    if (getParams().return_url) {
      handleReturnUrl(getParams().return_url)
    }
    if (getParams().custOrderId) {
      handleCustOrderId(getParams().custOrderId)
    }
  }, [])

  const toAddressChange = (value) => {
    handleWalletAddress(value.target.value)
    if (RegCoin[toCurrency].test(value.target.value) || !value.target.value) {
      handleShowAddressTips(false)
    } else {
      handleShowAddressTips(true)
    }
  }

  const submitGetOrder = () => {
    if (!walletAddress || showAddressTips) {
      handleShowAddressTips(true)
    } else {
      props.history.push(
        `/buyCrypto/chooseCard?amount=${amount}&approximatelyAmount=${approximatelyAmount}&channel_name=${channel_name}&to_crypto=${toCurrency}&from_currency=${fromCurrency}&walletAddress=${walletAddress}&rate=${rate}&custOrderId=${custOrderId}&return_url=${returnUrl}`
      )
    }
  }

  const renderAfterImg = () => {
    if (toCurrency === 'NEO') {
      return <img src={NEO} className='afterImg' />
    } else if (toCurrency === 'USDT-ERC20') {
      return <img src={USDT} className='afterImg' />
    } else if (toCurrency === 'GAS') {
      return <img src={GAS} className='afterImg' />
    } else if (toCurrency === 'bNEO') {
      return <img src={BNEO} className='afterImg' />
    } else if (toCurrency === 'FLM') {
      return <img src={FLM} className='afterImg' />
    } else if (toCurrency === 'fUSDT') {
      return <img src={USDT} className='afterImg' />
    } else {
      return null
    }
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'FastBuyAddressContainer-embedded'
            : ''
        } FastBuyAddressContainer`}
      >
        <HeaderTop title={`Enter Wallet Address`} />
        <div className='titleATips'>
          If your payment is successful, the cryptos will be sent to your wallet
          between 15 minutes to 8 hours. Kindly direct any inquiries to
          support@inst.money.
        </div>
        <div className='wrapper-left-item'>
          <div className='select-label'>{toCurrency} Wallet Address</div>
          <div className='select-container'>
            <Input
              placeholder='Wallet Address'
              value={walletAddress}
              size='large'
              disabled={getParams().walletAddress}
              onChange={toAddressChange}
              suffix={renderAfterImg()}
            ></Input>
          </div>
          <p className='addressTips'>
            {showAddressTips
              ? 'Please input correct wallet walletAddress'
              : null}
          </p>
          <div className='buyWarning'>
            <div className='warningTop'>
              <img src={WarningImg} alt='warning' />
              Attention! Beware of scams!
            </div>
            <div className='warningBottom'>
              Please make sure you have the credentials of the wallet you
              entered, and you have control over the funds in it. Do not enter
              an walletAddress that is given to you.
            </div>
          </div>
        </div>
        <Button
          loading={payDisabled}
          disabled={payDisabled}
          type='primary'
          className='goToBtn'
          onClick={() => submitGetOrder()}
        >
          Confirm
        </Button>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(FastBuyAddress)
