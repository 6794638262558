import USD from '../assets/images/USDNew.svg'
import EUR from '../assets/images/EUR.svg'
import GBP from '../assets/images/GBP.svg'

import NEO from '../assets/images/NEO.png'
import GAS from '../assets/images/gasImg.svg'
import USDT from '../assets/images/USDT.png'

export const fromCurrencyNEOOption = [
  { value: 'USD', label: 'USD', logo: USD },
  { value: 'EUR', label: 'EUR', logo: EUR },
  // { value: 'GBP', label: 'GBP', logo: GBP },
]

export const toCurrencyNEOOption = [
  { value: 'NEO', label: 'NEO(N3)', logo: NEO },
  { value: 'GAS', label: 'GAS(N3)', logo: GAS },
  { value: 'USDT-TRC20', label: 'USDT(TRC20)', logo: USDT },
]
