import React, { useState, useEffect, useRef } from 'react'
import {
  message,
  Steps,
  Input,
  Select,
  Upload,
  Spin,
  Button,
  Checkbox,
} from 'antd'
import { withRouter } from 'react-router-dom'
import { getBindCardInfo, bindCard } from '../../../utils/api'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import countryList from '../../../utils/Country.json'
import baseAdminUrl from '../../../utils/baseAdminUrl'
import { getParams } from '../../../utils/commonFun'
import CloudUpload from '../../../assets/images/cloudUpload.svg'
import selfieCard from '../../../assets/images/selfieCard.svg'
import TipsIconNew from '../../../assets/images/tipsIconNew.svg'
import './index.css'

const { Step } = Steps

const uploadUrl = `${baseAdminUrl}/v1/common/file/public`
const headers = {
  authorization: localStorage.getItem('payone_access_token') || '',
}

const AddBindCard = (props) => {
  const countryRef = useRef()
  const uploadRef = useRef()

  const [isIos, handleIsIos] = useState(false)
  const [firstName, handleFirstName] = useState('')
  const [lastName, handleLastName] = useState('')
  const [cardNumber, handleCardNumber] = useState('')
  const [btnTitle, handleBtnTitle] = useState('Next')
  const [current, handleCurrent] = useState(0)
  const [status, handleStatus] = useState(null)
  const [cardInfo, handleCardInfo] = useState({})
  const [country, handleCountry] = useState(null)
  const [countryOptionOpen, handleCountryOptionOpen] = useState(false)
  const [countryOption, handleCountryOption] = useState([])
  const [street, handleStreet] = useState(null)
  const [city, handleCity] = useState(null)
  const [postalCode, handlePostalCode] = useState(null)

  const [passportLoading, handlePassportLoading] = useState(false)
  const [passportHttpImgUrl, handlePassportHttpImgUrl] = useState(false)
  const [passportImgUrl, handlePassportImgUrl] = useState(false)
  const [checked, handleChecked] = useState(false)
  const [showCheckedTips, handleShowCheckedTips] = useState(false)

  useEffect(() => {
    //判断是否为ios
    let u = navigator.userAgent
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    handleIsIos(isiOS)
    let countryOptions = []
    countryList.map((item) => {
      countryOptions.push({
        value: item.countryCode,
        label: item.name_en,
      })
    })
    handleCountryOption(countryOptions)
  }, [])

  useEffect(() => {
    if (getParams(props).id) {
      getBindCardInfo(getParams(props).id).then((res) => {
        if (res.code === 0) {
          handleFirstName(res.result.first_name)
          handleLastName(res.result.last_name)
          handleCardNumber(res.result.card_number)
          handleCountry(res.result.country)
          handleStreet(res.result.address)
          handleCity(res.result.city)
          handlePostalCode(res.result.zipcode)
          handlePassportHttpImgUrl(res.result.front_doc)
          handleCardInfo(res.result)
          handleStatus(res.result.status)
        }
      })
    }
  }, [])

  const checkoutChange = (e) => {
    handleChecked(e.target.checked)
    handleShowCheckedTips(!e.target.checked)
  }

  const countryChange = (value) => {
    if (isIos) {
      handleCountryOptionOpen(false)
      countryRef.current.blur()
    }
    handleCountry(value)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt4M = file.size / 1024 / 1024 < 4
    if (!isLt4M) {
      message.error('Image must smaller than 4MB!')
    }
    return isJpgOrPng && isLt4M
  }

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    action: uploadUrl,
    headers: headers,
    beforeUpload: beforeUpload,
    onChange(info) {
      if (info.file.status === 'uploading') {
        handlePassportLoading(true)
        return
      }
      if (info.file.status === 'done') {
        handlePassportLoading(false)
        if (info?.file?.response?.code === 0) {
          handlePassportHttpImgUrl(info?.file?.response?.result)
          getBase64(info.file.originFileObj, (imageUrl) => {
            handlePassportImgUrl(imageUrl)
          })
        }
      }
    },
  }

  const submitInfo = () => {
    let params = {
      ...cardInfo,
      first_name: firstName,
      last_name: lastName,
      card_number: cardNumber,
      status: 2,
    }
    if (current === 0) {
      if (!firstName) {
        message.error('Please input your first name', 3)
      } else if (!lastName) {
        message.error('Please input your last name', 3)
      } else if (cardNumber.length !== 16) {
        message.error('Please input your Correct card number', 3)
      } else {
        bindCard(params).then((res) => {
          if (res.code === 0) {
            handleCurrent(1)
            handleBtnTitle('Submit')
          }
        })
      }
    } else {
      params.country = country
      params.city = city
      params.address = street
      params.zipcode = postalCode
      params.front_doc = passportHttpImgUrl
      params.status = 3
      if (!checked) {
        handleShowCheckedTips(true)
      } else if (!country) {
        message.error('Please select your country', 3)
      } else if (!city) {
        message.error('Please input your city', 3)
      } else if (!street) {
        message.error('Please input your address', 3)
      } else if (!postalCode) {
        message.error('Please input your zipcode', 3)
      } else if (!passportHttpImgUrl) {
        message.error('Please upload your selfie with passport and card', 3)
      } else {
        bindCard(params).then((res) => {
          if (res.code === 0) {
            props.history.push(`/buyCrypto/bindCardResult?id=${res.result}`)
          }
        })
      }
    }
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'addCardContainer-embedded' : ''
        } addCardContainer`}
      >
        <HeaderTop title='Add new Card' />
        {/* <div className='stepContainer'>
          <Steps current={current} direction='horizontal'>
            <Step title='Card Information' />
            <Step title='Billing Address' />
          </Steps>
        </div> */}
        {current === 0 ? (
          <>
            <div className='inputContainer'>
              <div className='label'>First name</div>
              <Input
                placeholder='First name'
                value={firstName}
                size='large'
                onChange={(e) => handleFirstName(e.target.value)}
              ></Input>
            </div>
            <div className='inputContainer'>
              <div className='label'>Last name</div>
              <Input
                placeholder='Last name'
                value={lastName}
                size='large'
                onChange={(e) => handleLastName(e.target.value)}
              ></Input>
            </div>
            <div className='inputContainer inputContainerLast'>
              <div className='label'>Card Number</div>
              <Input
                placeholder='Card Number'
                value={cardNumber}
                size='large'
                onChange={(e) => handleCardNumber(e.target.value)}
                type='number'
                disabled={status === 1}
              ></Input>
            </div>
          </>
        ) : (
          <>
            <div className='inputContainer'>
              <div className='label'>Country</div>
              {isIos ? (
                <Select
                  ref={countryRef}
                  value={country}
                  placeholder='Country'
                  onChange={countryChange}
                  open={countryOptionOpen}
                  onFocus={() => handleCountryOptionOpen(true)}
                  onBlur={() => handleCountryOptionOpen(false)}
                  options={countryOption}
                />
              ) : (
                <Select
                  ref={countryRef}
                  value={country}
                  placeholder='Country'
                  onChange={countryChange}
                  options={countryOption}
                />
              )}
            </div>
            <div className='inputContainer'>
              <div className='label'>City</div>
              <Input
                placeholder='City'
                value={city}
                size='large'
                onChange={(e) => handleCity(e.target.value)}
              ></Input>
            </div>
            <div className='inputContainer'>
              <div className='label'>Street</div>
              <Input
                placeholder='Street'
                value={street}
                size='large'
                onChange={(e) => handleStreet(e.target.value)}
              ></Input>
            </div>
            <div className='inputContainer'>
              <div className='label'>Postal code</div>
              <Input
                placeholder='Postal code'
                value={postalCode}
                size='large'
                onChange={(e) => handlePostalCode(e.target.value)}
              ></Input>
            </div>
            <div className='inputContainer uploadContainer'>
              <div className='label'>Selfie with passport and card</div>
              <Upload {...uploadProps} ref={uploadRef} className='needsclick'>
                <div className='uploadBtn needsclick' type='primary'>
                  <span className='needsclick'>
                    upload
                    <img
                      className='needsclick'
                      src={CloudUpload}
                      alt='upload'
                    />
                  </span>
                </div>
              </Upload>
            </div>
            {/* <div className='uploadTips'>
              Physical card: hand-held passport and card to be bound Virtual
              Card: hand-held Passport and hand-written card number
            </div> */}
            <div className='idCardContainer'>
              <Spin spinning={passportLoading}>
                {passportHttpImgUrl || passportImgUrl ? (
                  <img
                    src={passportHttpImgUrl || passportHttpImgUrl}
                    alt='front card'
                  />
                ) : (
                  <img src={selfieCard} alt='selfie card' />
                )}
              </Spin>
            </div>
            <div className='uploadTips'>
              <p>
                1. You must provide a passport that belong to you. Make sure the
                text on the passport is clear.
              </p>
              <p>
                2. Plastic card: Hold the passport and the card. Make sure your
                face, your passport and the card are clearly visible.
              </p>
              <p>
                3. Virtual card: Hold the passport and handwritten virtual card
                number. Make sure your face, your passport and the card number
                are clearly visible.
              </p>
            </div>
            <div className='checkContainer'>
              <Checkbox onChange={checkoutChange} className='checkSty' />
              <div>
                I confirm to be the person in the photo, and the owner of the
                passport and the card.
              </div>
            </div>
            <div className='showEmailTips'>
              {showCheckedTips ? (
                <div className='showEmailTipsContainer'>
                  <img src={TipsIconNew} />
                  <span>Please agree to the terms of service first</span>
                </div>
              ) : null}
            </div>
          </>
        )}
        <div className='addContainer'>
          <Button type='primary' className='goToBtn' onClick={submitInfo}>
            {btnTitle}
          </Button>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(AddBindCard)
