import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import HeaderTop from "../Components/HeaderTop";
import { getFastBuyHistory , getWalletHistory } from "../../../utils/api";
import copyImg from "../../../assets/images/copy.png";
import "./index.css";
import SepaModal from '../../Components/SepaModal/index'
import BigNumber from "bignumber.js";

const HistoryDetails = (props) => {
  const [details, handleDetails] = useState({});
  const [sepaDetails, handleSepaDetails] = useState({bank:{}});
  const [sepaModalVisible, setSepaModalVisible] = useState(false);
  useEffect(() => {
    if(getParams().type==='2'){
      // sepa
      getTransitions();
    }
    getHistory();
  }, []);

  const getParams = () => {
    var params = {};
    if (
      props.location.search.indexOf("?") === 0 &&
      props.location.search.indexOf("=") > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split("&");
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split("=")[0]] = currentValue.split("=")[1];
        });
      }
    }
    return params;
  };

  const getHistory = () => {
    let params = {
      order_no: getParams().id,
    };
    getFastBuyHistory(params).then((res) => {
      if (res.code === 0) {
        handleDetails(res.result?.records[0] || {});
      }
    });
  };

  const getTransitions = () => {
    const authorization = localStorage.getItem('authorization')
    let params = {
      tx_id: getParams().tx_id,
    }
    getWalletHistory(params, authorization).then((res) => {
      if (res.code === 0) {
        handleSepaDetails(res.result?.records[0] || {})
      }
    })
  }

  const renderPaymentStatus = (status) => {
    let returnText = "-";
    switch (status) {
      case 0:
        returnText = "Pending";
        break;
      case 1:
        returnText = "Succeed";
        break;
      case 2:
        returnText = "Confirming";
        break;
      case 3:
        returnText = "Abnormal";
        break;
      case 4:
        returnText = "Failed";
        break;
      case 5:
        returnText = "Cancelled";
        break;
      case 6:
        returnText = "Expire";
        break;
      case 7:
        returnText = "Refund in progress";
        break;
      case 8:
        returnText = "Refund success";
        break;
      case 9:
        returnText = "Refund Failed";
        break;
      default:
        break;
    }
    return returnText;
  };

  const renderTransferStatus = (status) => {
    let returnText = "-";
    switch (status) {
      case 0:
        returnText = "Pending";
        break;
      case 1:
        returnText = "Succeed";
        break;
      case 2:
        returnText = "Failed";
        break;
      case 3:
        returnText = "Submitted";
        break;
      case 4:
        returnText = "Pending";
        break;
      case 5:
        returnText = "Cancelled";
        break;
      case 6:
        returnText = "Pending";
        break;
      default:
        break;
    }
    return returnText;
  };

  const goResultPage = (status, order_no) => {
    let url = "";
    switch (status) {
      case 0:
        url = `../result-payment-inProgress/?id=${order_no}`;
        break;
      case 1:
        url = `../result-success/?id=${order_no}`;
        break;
      case 2:
        url = `../result-payment-confirming/?id=${order_no}`;
        break;
      case 3:
        url = `../result-payment-error/?id=${order_no}`;
        break;
      case 4:
        url = `../result-fail/?id=${order_no}`;
        break;
      case 5:
        url = `../result-cancel/?id=${order_no}`;
        break;
      case 6:
        url = `../result-timeout/?id=${order_no}`;
        break;
      case 7:
        url = `../result-refund-inProgress/?id=${order_no}`;
        break;
      case 8:
        url = `../result-refund-success/?id=${order_no}`;
        break;
      case 9:
        url = `../result-refund-fail/?id=${order_no}`;
        break;
      default:
        break;
    }
    props.history.push(url);
  };

  const renderDetails = () => {
    return (
      <div>
        <div className="detailsList">
          <div className="title">Order No</div>
          <div
            className="tips tipsLink"
            onClick={() =>{
              if (getParams().type==='2') {
                setSepaModalVisible(true);
                return ;

              }
              goResultPage(details.cust_order?.settle_status, details.order_no)
            }}
          >
            {details.order_no || "-"}
          </div>
        </div>
        <div className="detailsList">
          <div className="title"> Wallet Transaction ID</div>
          <div className="tips">
            {details?.wallet_order?.external_tx_id || "-"}
          </div>
        </div>
        <div className="detailsList">
          <div className="title">Sending to</div>
          <div className="addressCopy">
            <div className="tips">{details.address || "-"}</div>
            <CopyToClipboard
              text={details.address}
              onCopy={(value, a) => {
                message.success("Copied");
              }}
            >
              <img className="copyImage" src={copyImg} />
            </CopyToClipboard>
          </div>
        </div>
        <div className="detailsList">
          <div className="title">Crypto</div>
          <div className="tips">{details.coin_type || "-"}</div>
        </div>
        <div className="detailsList">
          <div className="title">Amount</div>
          <div className="tips">{BigNumber(details.amount).toFixed(2, BigNumber.ROUND_DOWN) || "-"}</div>
        </div>
        <div className="detailsList">
          <div className="title">Payment status</div>
          <div className="tips">
            {renderPaymentStatus(details.cust_order?.settle_status)}
          </div>
        </div>
        <div className="detailsList">
          <div className="title">Transfer Status</div>
          <div className="tips">
            {renderTransferStatus(details.transfer_status)}
          </div>
        </div>
        <div className="detailsList">
          <div className="title">Transaction Hash</div>
          <div className="tips">{details.tx_hash || "-"}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem("embedded")
            ? "historyDetailsContainer-embedded"
            : ""
        } historyDetailsContainer`}
      >
        <HeaderTop title="Your transaction" />
        {renderDetails()}
        <div className="bottomTips">
          If You have any questions,we are here to help.
        </div>
      </div>
      <Footer />
      <SepaModal  
        visible={sepaModalVisible}
        speaData={sepaDetails}
        
        cancel={() => {
            setSepaModalVisible(false);
          }}/>
    </>
  );
};

export default withRouter(HistoryDetails);
