import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Button, message } from 'antd'
import { useTranslation } from 'react-i18next'
import Header from '../Components/Header'
import { getCheckout, channelPayment } from '../../utils/api'
import mcSecureCode from '../../assets/images/mc-secure-code.svg'
import verifiedByVisa from "../../assets/images/verified-by-visa.svg";

import pci from '../../assets/images/pci.svg'
import cardType from '../../assets/images/cardType.svg'
import visaCard from '../../assets/images/cardType1.svg'
import mastercard from '../../assets/images/cardType2.svg'
import cardType3 from '../../assets/images/cardType3.svg'
import Lock from '../../assets/images/lock.svg'

import { loadJS } from "../../utils/index";
import './index.css'

var errors = {}
errors['card-number'] = 'Please enter a valid card number'
errors['expiry-date'] = 'Please enter a valid expiry date'
errors['cvv'] = 'Please enter a valid cvv code'
let checkoutApiKey = ''
const Checkout = (props) => {
  const { t } = useTranslation()
  const [amount, handleAmount] = useState('')
  const [currency, handleCurrency] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')
  const [id, handleId] = useState('')

  const [buttonDisable, handleButtonDisable] = useState('')
  const [payButton, handlePayButton] = useState(null)
  const [payForm, handlePayForm] = useState(null)

  const [loading ,setLoading ] = useState(false)
  const [apiLoading ,setApiLoading ] = useState(false)

  useEffect(() => {
    if (props.match.params.id) {
      handleId(props.match.params.id)
      initOrder(props.match.params.id)
    } else {
      message.error('order number no exist')
    }
  }, [])

  useEffect(() => {
    handlePayButton(document.getElementById('pay-button'))
    handlePayForm(document.getElementById('payment-form'))
    loadJS("https://cdn.checkout.com/js/framesv2.min.js", () => {
    });

  }, [])

  const initCheckout = () => {
    if(!checkoutApiKey){
      message.error('api_key cannot be null')
      return ;
    }

    window.Frames.init(checkoutApiKey)
    window.Frames.removeAllEventHandlers(window.Frames.Events.FRAME_VALIDATION_CHANGED)
    window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_VALIDATION_CHANGED)
    window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_TOKENIZATION_FAILED)
    window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_TOKENIZED)
    window.Frames.removeAllEventHandlers(window.Frames.Events.PAYMENT_METHOD_CHANGED)

    window.Frames.addEventHandler(
      window.Frames.Events.FRAME_VALIDATION_CHANGED,
      onValidationChanged
    )
    window.Frames.addEventHandler(
      window.Frames.Events.CARD_VALIDATION_CHANGED,
      cardValidationChanged
    )
    window.Frames.addEventHandler(
      window.Frames.Events.CARD_TOKENIZATION_FAILED,
      onCardTokenizationFailed
    )
    window.Frames.addEventHandler(
      window.Frames.Events.CARD_TOKENIZED,
      onCardTokenized
    )

    window.Frames.addEventHandler(
      window.Frames.Events.PAYMENT_METHOD_CHANGED,
      paymentMethodChanged
    )
    // document.getElementById('payment-form').addEventListener('submit', onSubmit)
  }

  const initOrder = (id) => {
    let params = {
      id,
      channel: 'pt',
    }
    getCheckout(params)
      .then((response) => {
        let res = response.data
        if (res.code === 0) {
          if (res.result.status === 6) {
            //超时
            props.history.push(`../result-timeout/?id=${id}`)
          } 
          else if (res.result?.status === 4) {
            props.history.push(`../result-fail/?id=${id}`);
          }
          else if (res.result?.status === 0 ) {
                props.history.push(`../result-payment-inProgress/?id=${id}`);
          } 
          else  if (res.result?.status === 1)  {
            props.history.push(`../result-success/?id=${id}`);
          }
          
          else {
            handleAmount(res.result.amount)
            handleCurrency(res.result.currency)
            handleLogo(res.result.logo)
            handleTitle(res.result.title)
            checkoutApiKey = res.result.checkout_api_key
            console.log(res.result.checkout_api_key)

            if(window.Frames){
             initCheckout()
            }
            else{
              loadJS("https://cdn.checkout.com/js/framesv2.min.js", () => {
                console.log("js load");
                if(checkoutApiKey){
                  initCheckout()
                }
              });
            }
            
          }
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  const onValidationChanged = (event) => {
    var e = event.element

    if (event.isValid || event.isEmpty) {
      if (e === 'card-number' && !event.isEmpty) {
        // showPaymentMethodIcon()
      }
      // setDefaultIcon(e)
      // clearErrorIcon(e)
      clearErrorMessage(e)
    } else {
      if (e === 'card-number') {
        clearPaymentMethodIcon()
      }
      // setDefaultErrorIcon(e)
      // setErrorIcon(e)
      setErrorMessage(e)
    }
  }

  const cardValidationChanged = () => {
    handleButtonDisable(!window.Frames.isCardValid())
  }

  const onCardTokenizationFailed = (error) => {
    window.Frames.enableSubmitForm()
  }
  const onCardTokenized = (event) => {
    console.log('event',event)
    payIt(event)
  }

  const payIt = (event) => {
    let id = props.match.params.id
    let params = {
      pay_type: 'Checkout',
      checkout_token: event.token,
      currency: 'FIAT',
      id,
    }

    if(apiLoading ){
      message.error("loading")

      return ;
    }
    setApiLoading(true)
    channelPayment(params)
      .then((response) => {
        const res = response.data
        console.log('res',res)
        if (res.code === 0) {
          if (res.result?.acs_url) {
            window.location.href = res.result.acs_url;
          } else if (res.result?.front_url) {
            window.location.href = `${res.result?.front_url}`;
          } 
          else if (res.result?.status === 4) {
            props.history.push(`../result-fail/?id=${id}`);
          }
          else if (res.result?.status === 0 ) {
                props.history.push(`../result-payment-inProgress/?id=${id}`);
          } 
          else  if (res.result?.status === 1)  {
            props.history.push(`../result-success/?id=${id}`);
          }
          else {
            message.error(res.result.msg);
          }
        } else if (res.code === 111072) {
          message.error(res.msg)
        } else {

          message.error(res.msg)
        }
        setApiLoading(false)
        setLoading(false)
        window.Frames.enableSubmitForm();
      })
      .catch((err) => {
        window.Frames.enableSubmitForm();
        setApiLoading(false)
        setLoading(false)
        window.Frames.enableSubmitForm();
        message.error(err)
      })
  }

  const paymentMethodChanged = (event) => {
    var pm = event.paymentMethod
    // let container = document.querySelector('.icon-container.payment-method')

    if (!pm) {
      // clearPaymentMethodIcon(container)
    } else {
      // clearErrorIcon('card-number')
      // showPaymentMethodIcon(container, pm)
    }
  }

  const clearErrorMessage = (el) => {
    var selector = '.error-message__' + el
    var message = document.querySelector(selector)
    message.textContent = ''
  }



  const clearPaymentMethodIcon = (parent) => {
    if (parent) parent.classList.remove('show')

    var logo = document.getElementById('logo-payment-method')
    logo.style.setProperty('display', 'none')
  }

  const setErrorMessage = (el) => {
    var selector = '.error-message__' + el
    var message = document.querySelector(selector)
    message.textContent = errors[el]
  }


  const onSubmit = (event) => {
    if(loading){
      message.error('loading')
      return ;
    }
    setLoading(true)
    event.preventDefault()
    window.Frames.submitCard()
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'payContainer-embedded' : ''
        } payContainer`}
      >
        <div className='payCurrencyContainer'>
          <div>
            <h2 className='amountTitle'>
              {amount} {currency}
            </h2>
            <span className='tipsCard'>#{id}</span>
          </div>
          <div className='bottomBorder' />
          <div className='checkoutFormContainer'>
            <div className='cardType'>
              <div className='cardTypeLeft'>
                <img src={cardType} alt='card' />
                <span>{t('Card.card')}</span>
              </div>
              <div className='cardTypeRight'>
                <img src={mastercard} alt='card' />
                <img src={visaCard} alt='card' />
                <img src={cardType3} alt='card' />
              </div>
            </div>
            <form
              id='payment-form'
              method='POST'
              action='https://merchant.com/charge-card'
            >
              <label for='card-number'>Card number</label>
              <div className='input-container card-number'>

                <div className='card-number-frame'></div>
                <div className='icon-container payment-method'>
                  <img id='logo-payment-method' />
                </div>
              </div>
              <div className='date-and-code'>
                <div>
                  <label for='expiry-date'>Expiry date</label>
                  <div className='input-container expiry-date'>
                    <div className='expiry-date-frame'></div>
                    <div className='icon-container'>
                      {/* <img id='icon-expiry-date-error' src={errorImg} /> */}
                    </div>
                  </div>
                </div>
                <div>
                  <label for='cvv'>Security code</label>
                  <div className='input-container cvv'>
                    {/* <div className='icon-container'>
                      <img id='icon-cvv' src={cvvImg} alt='CVV' />
                    </div> */}
                    <div className='cvv-frame'></div>
                    <div className='icon-container'>
                      {/* <img id='icon-cvv-error' src={errorImg} /> */}
                    </div>
                  </div>
                </div>
              </div>
              <Button
                loading={loading}
                className='confirmBtnBottom airBtn'
                id='pay-button'
                type='button'
                disabled={buttonDisable}
                onClick={onSubmit}
              >
                <img src={Lock} alt='lock' />
                {amount} {currency}
              </Button>
              <div>
                <span className='error-message error-message__card-number'></span>
                <span className='error-message error-message__expiry-date'></span>
                <span className='error-message error-message__cvv'></span>
              </div>

              <p className='success-payment-message'></p>
            </form>
          </div>
        </div>
        <div className='cardImgContainer'>
          <img className='payRightImg' src={pci} alt='img' />
          <img className='payRightImg' src={mcSecureCode} alt='img' />
          <img className='payRightImg' src={verifiedByVisa} alt='img' />
          
        </div>
      </div>
    </>
  )
}

export default withRouter(Checkout)
