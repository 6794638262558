import React from "react";
import { Modal } from "antd";
import "./sepa.css";

const SepaModal = (props) => {
  const { visible, cancel, speaData } = props;

  return (
    <>
      <Modal
        visible={visible}
        title="Funding Your Account with Bank Transfer(SPEA)"
        centered
        footer={null}
        width="450"
        onCancel={cancel}
        zIndex="20"
      >
        <div className="info_title">Order information</div>
        <div className="deposit_bank_msg_container">
          <div className="deposit_bank_msg_container_left">
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Order No.</div>
              <div className="line_right">{speaData?.order_no}</div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Wallet ID</div>
              <div className="line_right">{speaData?.address}</div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Amount</div>
              <div className="line_right">
                {speaData?.amount} {speaData?.currency}
              </div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Reference Code</div>
              <div className="line_right">
                {speaData?.postscript}
                <span className="copyBtn"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="attention_tips">
          Please transfer the fund within 4 hours. Send the order reference code
          with the transaction so we can know it’s from you.
        </div>
        <div className="attention_tips_tips">
          Make a payment to Inst using the following bank account details .
        </div>
        <div className="info_title">Bank information</div>
        <div className="deposit_bank_msg_container">
          <div className="deposit_bank_msg_container_left">
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Account Name</div>
              <div className="line_right">
                {speaData?.bank?.account_name}
                <span className="copyBtn"></span>
              </div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">IBAN</div>
              <div className="line_right">
                {speaData?.bank?.account_id}
                <span className="copyBtn"></span>
              </div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Bank Name</div>
              <div className="line_right">
                {speaData?.bank?.bank_name}
                <span className="copyBtn"></span>
              </div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">BIC(SWIFT Code)</div>
              <div className="line_right">
                {speaData?.bank?.bic}
                <span className="copyBtn"></span>
              </div>
            </div>
            <div className="deposit_bank_msg_container_line">
              <div className="line_left">Bank Address</div>
              <div className="line_right">
                {speaData?.bank?.bank_address}
                <span className="copyBtn"></span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SepaModal;
