import React, { useState, useEffect } from 'react'
import { Result } from 'antd-mobile'
import {
  ClockCircleTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import HeaderTop from '../Components/HeaderTop'
import { getBindCardInfo } from '../../../utils/api'
import { getParams } from '../../../utils/commonFun'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { reasonOptions } from '../../../utils/enum'
import './index.css'

const BindCardResult = (props) => {
  const [reason, handleReason] = useState('')
  const [status, handleStatus] = useState(null)
  const [id, handleId] = useState(null)

  useEffect(() => {
    if (getParams(props).id) {
      handleId(getParams(props).id)
      cardInfo(getParams(props).id)
    }
  }, [])

  const cardInfo = (id) => {
    getBindCardInfo(id).then((res) => {
      if (res.code === 0) {
        handleStatus(res.result.status)
        handleReason(res.result.memo)
      }
    })
  }

  const messageNode = () => {
    if (status === 3) {
      return (
        <div className='top'>
          <ClockCircleTwoTone
            style={{
              fontSize: '48px',
              color: '#0045ff',
              margin: '24px auto 48px',
            }}
          />
          <p>Your card verification is under review.</p>
        </div>
      )
    } else if (status === 1) {
      return (
        <div className='top'>
          <CheckCircleTwoTone
            style={{
              fontSize: '48px',
              color: '#0045ff',
              margin: '24px auto 48px',
            }}
          />
          <h4>Your card verification is verified.</h4>
        </div>
      )
    } else if (status === 4) {
      return (
        <div className='top'>
          <CloseCircleTwoTone
            style={{
              fontSize: '48px',
              color: '#0045ff',
              margin: '24px auto 48px',
            }}
          />
          <h4>Sorry, your application was rejected.</h4>
          <p>Reason: {reason}</p>
        </div>
      )
    }
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'bindCardResultContainer-embedded'
            : ''
        } bindCardResultContainer`}
      >
        <div className='kycResultTop'>
          <HeaderTop title='Payment Method' />
        </div>
        <Result
          title=''
          message={messageNode()}
          buttonText={status === 4 ? 'Reapply' : null}
          buttonType='primary'
          onButtonClick={() => {
            props.history.push(`../buyCrypto/addBindCard?id=${id}`)
          }}
        />
      </div>
      <Footer />
    </>
  )
}

export default withRouter(BindCardResult)
