import React, { useState, useEffect } from 'react'
import { Result } from 'antd-mobile'
import { message } from 'antd'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { sendEmail } from '../../../utils/api'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import './index.css'

const KycResult = (props) => {
  const { t } = useTranslation()
  const [email, handleEmail] = useState('')
  const [authorization, handleAuthorization] = useState('')
  const [currency, handleCurrency] = useState('')
  const [crypto, handleCrypto] = useState('')
  const [cryptoAmount, handleCryptoAmount] = useState('')
  const [amount, handleAmount] = useState('')
  const [walletAddress, handleWalletAddress] = useState('')
  const [returnUrl, handleReturnUrl] = useState('')
  const [custOrderId, handleCustOrderId] = useState('')

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  useEffect(() => {
    if (localStorage.getItem('authorization')) {
      let url = '/buyCrypto/fastBuy?'
      if (getParams().from_currency) {
        url += `from_currency=${getParams().from_currency}&`
      }
      else if(getParams().currency){
        url += `from_currency=${getParams().currency}&`
      }
      if (getParams().to_crypto) {
        url += `to_crypto=${getParams().to_crypto}&`
      }
      else if (getParams().crypto) {
        url += `to_crypto=${getParams().crypto}&`
      }

      if (getParams().cryptoAmount) {
        url += `cryptoAmount=${getParams().cryptoAmount}&`
      }
      if (getParams().amount) {
        url += `amount=${getParams().amount}&`
      }
      if (getParams().walletAddress) {
        url += `walletAddress=${getParams().walletAddress}&`
      }
      if (getParams().custOrderId) {
        url += `custOrderId=${getParams().custOrderId}&`
      }
      if (getParams().returnUrl) {
        url += `returnUrl=${getParams().returnUrl}&`
      }
      props.history.push(url)
    } else {
      handleAuthorization(getParams().authorization)
      if (getParams().from_currency) {
        handleCurrency(getParams().from_currency)
      }
      else if (getParams().currency) {
        handleCurrency(getParams().currency)
      }

      if (getParams().to_crypto) {
        handleCrypto(getParams().to_crypto)
      }
      else if (getParams().crypto) {
        handleCrypto(getParams().crypto)
      }

      if (getParams().cryptoAmount) {
        handleCryptoAmount(getParams().cryptoAmount)
      }
      if (getParams().amount) {
        handleAmount(getParams().amount)
      }
      if (getParams().walletAddress) {
        handleWalletAddress(getParams().walletAddress)
      }
      if (getParams().returnUrl) {
        handleReturnUrl(getParams().returnUrl)
      }
      if (getParams().custOrderId) {
        handleCustOrderId(getParams().custOrderId)
      }
    }
  }, [])

  const messageNode = () => {
    return (
      <>
        <div className='top'>
          <p>{t('Login.tips1')}</p>
        </div>
        <div className='enterEmailContainer'>
          <p>{t('Login.tips2')}</p>
          <Input
            autoComplete='email'
            type='email'
            placeholder='hello@example.com'
            value={email}
            size='large'
            onChange={(value) => handleEmail(value.target.value)}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'loginContainer-embedded' : ''
        } loginContainer`}
      >
        <div style={{ padding: '0 15px', marginBottom: '48px' }}>
          <HeaderTop title='Checkout with inst' />
        </div>
        <Result
          message={messageNode()}
          buttonText={t('Login.Confirm')}
          buttonType='primary'
          onButtonClick={() => {
            if (!email) {
              return
            } else {
              let params = {
                email: email,
              }
              sendEmail(params).then((res) => {
                if (res.code == 0) {
                  message.success('Send email success')
                  localStorage.setItem('emailCode', res.result)
                  props.history.push(
                    `/buyCrypto/verification/${email}?authorization=${authorization}&from_currency=${currency}&to_crypto=${crypto}&amount=${amount}&cryptoAmount=${cryptoAmount}&walletAddress=${walletAddress}&custOrderId=${custOrderId}&returnUrl=${returnUrl}`
                  )
                }
              })
            }
          }}
        />
      </div>
      <Footer />
    </>
  )
}

export default withRouter(KycResult)
