import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Spin } from 'antd'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import { getKycInfo } from '../../../utils/api'
import StarIcon from '../../../assets/images/starIcon.svg'
import './index.css'

const KycCenter = (props) => {
  const [loading, handleLoading] = useState(false)
  const [kycLevel, handleKycLevel] = useState(null)
  const [status, handleStatus] = useState(null)
  const [drivingStatus, handleDrivingStatus] = useState(null)
  const [level_three_status, handleLevelThreeStatus] = useState(null)
  const [level_four_status, handleLevelFourStatus] = useState(null)
  useEffect(() => {
    getKyc()
  }, [])

  const getKyc = () => {
    handleLoading(true)
    getKycInfo().then((res) => {
      handleLoading(false)
      if (res.code === 0) {
        handleKycLevel(res.result?.kyc_level)
        handleStatus(res.result?.status)
        handleDrivingStatus(res.result?.driver_status)
        handleLevelThreeStatus(res.result?.level_three_status)
        handleLevelFourStatus(res.result?.level_four_status)
      }
    })
  }

  const goLevel1Verify = () => {
    if (drivingStatus === 0 || drivingStatus === 7) {
      props.history.push('/buyCrypto/kycResult')
    } else {
      props.history.push('/buyCrypto/kyc?kyc_level=1')
    }
  }

  const goLevel2Verify = () => {
    if (status === 0 || status === 7) {
      props.history.push('/buyCrypto/kycResult')
    } else {
      props.history.push('/buyCrypto/kyc?kyc_level=2')
    }
  }

  const goLevel3Verify = () => {
    if (level_three_status === 0 || level_three_status === 7) {
      props.history.push('/buyCrypto/kycResult')
    } else {
      props.history.push('/buyCrypto/kyc?kyc_level=3')
    }
  }

  const goLevel4Verify = () => {
    props.history.push('/buyCrypto/kyc?kyc_level=4')
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'KycCenterContainer-embedded'
            : ''
        } KycCenterContainer`}
      >
        <HeaderTop title='Personal Identity Verification ' />
        <div className='titleTips'>
          You may skip Lv.1 and complete the Lv2 verification directly to enjoy
          the maximum purchase limit or complete the Lv.1 verification first and
          then upgrade to Lv.2.
        </div>
        <Spin spinning={loading}>
          <div className='levelSty'>
            <div className='levelLeft'>Current Level</div>
            <div className='levelRight'>
              <img src={StarIcon} />
              <div className='levelRightText'>LV{kycLevel || 0}</div>
            </div>
          </div>
          {/* <div className='textTips'>
            <img src={TipsIcon} />
            <div className='textStyContainer'>
              <div className='textSty'>
                You may skip Lv.1 and complete the Lv2 verification directly to
                enjoy the maximum purchase limit or complete the Lv.1
                verification first and then upgrade to Lv.2.
              </div>
            </div>
          </div> */}
          <div className='levelContainer'>
            <div className='containerTop'>
              <img className='starIconSty' src={StarIcon} />
              <div className='levelTitle'>LV1</div>
              <div className='levelTips'>Basic</div>
            </div>
            <div className='containerCenter'>
              <div className='centerList'>
                <div className='left'>Required document:</div>
                <div className='right'>Driving license Or ID Card</div>
              </div>
              <div className='centerList'>
                <div className='left'>Benefit:</div>
                <div className='right'>200 USD daily of purchase limit</div>
              </div>
              <Button
                disabled={
                  (kycLevel === '2' && status !== 0) ||
                  drivingStatus === 2 ||
                  kycLevel === '3' ||
                  kycLevel === '4'
                }
                type='primary'
                className={
                  (kycLevel === '2' && status !== 0) ||
                  drivingStatus === 2 ||
                  kycLevel === '3' ||
                  kycLevel === '4'
                    ? 'goToBtnDisabled'
                    : 'goToBtn'
                }
                onClick={() => goLevel1Verify()}
              >
                {drivingStatus === 2 || status === 2
                  ? 'Verified'
                  : drivingStatus === 7
                  ? 'Failed'
                  : !drivingStatus
                  ? 'Verify'
                  : 'Under Review'}
              </Button>
            </div>
          </div>
          <div className='levelContainer'>
            <div className='containerTop'>
              <img className='starIconSty' src={StarIcon} />
              <div className='levelTitle'>LV2</div>
              <div className='levelTips'>Intermediate</div>
            </div>
            <div className='containerCenter'>
              <div className='centerList'>
                <div className='left'>Required document:</div>
                <div className='right'>Passport</div>
              </div>
              <div className='centerList'>
                <div className='left'>Benefit:</div>
                <div className='right'>500 USD daily of purchase limit</div>
              </div>
              <Button
                disabled={
                  (kycLevel === '1' && drivingStatus !== 2) || status === 2
                }
                type='primary'
                className={
                  (kycLevel === '1' && drivingStatus !== 2) || status === 2
                    ? 'goToBtnDisabled'
                    : 'goToBtn'
                }
                onClick={() => goLevel2Verify()}
              >
                {status === 2
                  ? 'Verified'
                  : status === 7
                  ? 'Failed'
                  : !status
                  ? 'Verify'
                  : 'Under Review'}
              </Button>
            </div>
          </div>

          <div className='levelContainer'>
            <div className='containerTop'>
              <img className='starIconSty' src={StarIcon} />
              <div className='levelTitle'>LV3</div>
              <div className='levelTips'>Advanced</div>
            </div>
            <div className='containerCenter'>
              <div className='centerList'>
                <div className='left'>Required:</div>
                <div className='right'>
                  1. Achieve Lv.2 before applying for Lv.3.
                </div>
                <div className='right'>
                  2. The account must be registered more than one month
                </div>
                <div className='right'>3. Selfie with passport</div>
              </div>
              <div className='centerList'>
                <div className='left'>Benefit:</div>
                <div className='right'>1000 USD daily of purchase limit</div>
              </div>
              <Button
                disabled={
                  (kycLevel === '3' && level_three_status === 0) ||
                  status !== 2 ||
                  level_three_status === 2
                }
                className={
                  (kycLevel === '3' && level_three_status === 0) ||
                  status !== 2 ||
                  level_three_status === 2
                    ? 'goToBtnDisabled'
                    : 'goToBtn'
                }
                type='primary'
                onClick={() => goLevel3Verify()}
              >
                {level_three_status === 2
                  ? 'Verified'
                  : level_three_status === 7
                  ? 'Failed'
                  : level_three_status === null
                  ? 'Verify'
                  : 'Under Review'}
              </Button>
            </div>
          </div>

          <div className='levelContainer'>
            <div className='containerTop'>
              <img className='starIconSty' src={StarIcon} />
              <div className='levelTitle'>LV4</div>
              <div className='levelTips'>Top</div>
            </div>
            <div className='containerCenter'>
              <div className='centerList'>
                <div className='left'>Required document:</div>
                <div className='right'>
                  <div>1.</div>
                  <div>Achieve Lv.3 before applying for Lv.4.</div>
                </div>
                <div className='right'>
                  <div>2. </div>
                  <div>Send required documents by email.</div>
                </div>
              </div>
              <div className='centerList'>
                <div className='left'>Benefit:</div>
                <div className='right'>3000 USD daily of purchase limit</div>
              </div>
              <Button
                type='primary'
                className='goToBtn'
                onClick={() => goLevel4Verify()}
              >
                Verify
              </Button>
            </div>
          </div>
        </Spin>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(KycCenter)
