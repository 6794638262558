export const getParams = (props) => {
  var params = {}
  if (
    props.location.search.indexOf('?') == 0 &&
    props.location.search.indexOf('=') > 1
  ) {
    var paramArray = unescape(props.location.search)
      .substring(1, props.location.search.length)
      .split('&')
    if (paramArray.length > 0) {
      paramArray.forEach(function (currentValue) {
        params[currentValue.split('=')[0]] = currentValue.split('=')[1]
      })
    }
  }
  return params
}
