import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { submitCreateOrder } from '../../../../utils/api'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import HeaderTop from '../../Components/HeaderTop'
import RightG from '../../../../assets/images/right_g.svg'
import './index.css'

const daily_amount = localStorage.getItem('daily_amount')

const CreditDeposit = (props) => {
  const [btnLoading, handleBtnLoading] = useState(false)
  const [amount, handleAmount] = useState('')
  const [address, handleAddress] = useState('')
  const [fee, handleFee] = useState('')
  const [creditAmount, handleCreditAmount] = useState('')
  const [fromCurrency, handleFromCurrency] = useState('')

  useEffect(() => {
    handleAmount(getParams()?.amount)
    handleAddress(getParams()?.address)
    handleFee(getParams()?.fee)
    handleCreditAmount(getParams()?.creditAmount)
    handleFromCurrency(getParams()?.from_currency)
  }, [])

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  const submitFun = () => {
    handleBtnLoading(true)
    const params = {
      currency: fromCurrency,
      amount: amount,
      channel_name: 'unionintl',
      cust_order_id: Date.now(),
    }
    const authorization = localStorage.getItem('authorization')
    submitCreateOrder(params, authorization)
      .then((res) => {
        handleBtnLoading(false)
        if (res.code === 0) {
          props.history.push(`/?id=${res.result.order_id}`)
        }
      })
      .catch(() => {
        handleBtnLoading(false)
      })
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'creditDepositContainer-embedded'
            : ''
        } creditDepositContainer`}
      >
        <HeaderTop title='Deposit with a Credit/debit card' />
        <div className='deposit_credit_container'>
          <h3>Requirements:</h3>
          <div className='deposit_steps'>
            <img src={RightG} alt='' />
            <span>This is your card</span>
          </div>
          <div className='deposit_steps'>
            <img src={RightG} alt='' />
            <span>
              Total amount of deposit by card does not exceed
              {daily_amount} USD today.
            </span>
          </div>
          <div className='deposit_steps'>
            <img src={RightG} alt='' />
            <span>You use max 3 different cards in the last 60 days</span>
          </div>
          <h3>
            The deposit will be canceled if any of the above requirements is not
            met.
          </h3>
          <div className='tips_container tips_container_tips'>
            <div className='title_tips'>Attention:</div>
            After pre-authorization, you may go through a quick verification of
            your card payment. It's part of our effort against credit card
            fraud. Once completed, your fund will be deposited.
          </div>

          <div className='tips_container'>
            <div className='tips_container_center'>
              <h3>Your payment</h3>
              <div>
                <div className='tips_line'>
                  <span className='tips_line_left'>Wallet ID</span>
                  <span className='tips_line_right'>{address}</span>
                </div>
                <div className='tips_line'>
                  <span className='tips_line_left'>Credit Amount</span>
                  <span className='tips_line_right'>
                    {creditAmount} {fromCurrency}
                  </span>
                </div>
                <div className='tips_line'>
                  <span className='tips_line_left'>Debit Amount</span>
                  <span className='tips_line_right'>
                    ~ {amount} {fromCurrency}
                  </span>
                </div>
                <div className='tips_line'>
                  <span className='tips_line_left'>FEE</span>
                  <span className='tips_line_right'>
                    ~ {fee} {fromCurrency}
                  </span>
                </div>
              </div>
            </div>
            <h4>
              By clicking the "Continue" button you agree to all conditions
              listed above.
            </h4>
            <div className='tips_bottom'>
              Using cards you can deposit up to {daily_amount} USD (or its
              equivalent amount in other currencies) per day. This deposit limit
              resets every 24 hours.
            </div>
          </div>
          <div className='confirm_container'>
            <Button
              type='primary'
              className='confirm-btn'
              onClick={submitFun}
              loading={btnLoading}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(CreditDeposit)
