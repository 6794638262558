import React, { useState, useEffect, useRef, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import numeral from 'numeral'
import { Input, Select, Button, message, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { regNumber } from '../../../utils/reg.js'
import {
  getRates,
  getCustCrypto,
  getUserStatusController,
  userFeedback,
} from '../../../utils/api'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import Jinggao from '../../../assets/images/jinggao.svg'
import NEO from '../../../assets/images/NEO.png'
import BNEO from '../../../assets/images/BNEO.png'
import GAS from '../../../assets/images/gasImg.svg'
import USDT from '../../../assets/images/USDT.png'
import FLM from '../../../assets/images/FLM.png'
import BTC from '../../../assets/images/BTC.svg'
import ETH from '../../../assets/images/ETH.svg'
import instLogo from '../../../assets/images/logoNew.svg'
import passwordHoldImg from '../../../assets/images/passwordHoldImg.png'
import { fromCurrencyNEOOption } from '../../../utils/CoinImg'
import './index.css'

const FastBuy = (props) => {

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }
  
  const { t } = useTranslation()
  const toCurrencyRef = useRef()
  const fromCurrencyRef = useRef()
  const [rate, handleRate] = useState('')
  const [network_fee, handleNetworkFee] = useState('')
  const [network_fee_amount, handleNetwork_fee_amount] = useState('')
  const [processing_fee, handleProcessingFee] = useState('')
  const [processing_fee_amount, handleProcessing_fee_amount] = useState('')
  const [maxAmount, handleMaxAmount] = useState(0)
  const [minAmount, handleMinAmount] = useState(0)
  const [channel_name, handleChannelName] = useState('')
  const [authorization, handleAuthorization] = useState('')
  const [amount, handleAmount] = useState('')
  const [selectDisabled, handleSelectDisabled] = useState(true)
  const [fromCurrencyOption, handleFromCurrencyOption] = useState([])

  const [toCurrency, handleToCurrency] = useState('NEO')
  const [toCurrencyOption, handleToCurrencyOption] = useState([])
  const [approximatelyAmount, handleApproximatelyAmount] = useState(10)
  const [walletAddress, handleWalletAddress] = useState('')
  const [returnUrl, handleReturnUrl] = useState('')
  const [custOrderId, handleCustOrderId] = useState('')
  const [fromCurrencyDisabled, handleFromCurrencyDisabled] = useState(false)
  const [toCryptoDisabled, handleToCryptoDisabled] = useState(false)
  const [showMaxErrorTips, handleShowMaxErrorTips] = useState(false)
  const [showMinErrorTips, handleShowMinErrorTips] = useState(false)

  const [fromCurrencyOptionOpen, handleFromCurrencyOptionOpen] = useState(false)
  const [toCurrencyOptionOpen, handleToCurrencyOptionOpen] = useState(false)

  const [isIos, handleIsIos] = useState(false)

  const [blankListVisible, handleBlankListVisible] = useState(false)
  const [reviewListVisible, handleReviewListVisible] = useState(false)
  const [sepaOnly , setSepaOnly ] = useState(sessionStorage.getItem('sepa'))
  const [hiddenProcessFee, setHiddenProcessFee] = useState(false)
  const [fromCurrency, handleFromCurrency] = useState(sessionStorage.getItem('sepa') ?'EUR':'USD')
  const { Option } = Select

  const filterFromCurrencyOption = useMemo(()=> {

    if(!sepaOnly){
      return fromCurrencyOption
    }
    let list = fromCurrencyOption.filter((item)=>{
      return item.value === 'EUR'
    })
    console.log(fromCurrencyOption, list)
    return list

  }, [sepaOnly, fromCurrencyOption])





  useEffect(() => {
    if (localStorage.getItem('authorization')) {
      getUserStatusController().then((res) => {
        if (res.code === 310002) {
          handleBlankListVisible(true)
        } else if (res.code === 310001) {
          handleReviewListVisible(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    //判断是否为ios
    let u = navigator.userAgent
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    handleIsIos(isiOS)
    if (getParams().sepa) {
      sessionStorage.setItem('sepa', true)
      
    }
    if(sessionStorage.getItem('sepa')){
      setSepaOnly(true)
      handleFromCurrency('EUR')
      console.log(fromCurrencyRef)
      // fromCurrencyRef?.current.value = 'EUR'
    }
    
    if (getParams().embedded) {
      //特殊处理内嵌样式
      sessionStorage.setItem('embedded', true)
      let domRoot = document.getElementById('root')
      domRoot.style.backgroundColor = '#fff'
      document.body.style.backgroundColor = '#fff !important'
    } else {
      document.body.style.backgroundColor = '#f8f8f8 !important'
    }
    if (getParams().authorization) {
      handleAuthorization(getParams().authorization)
    }
    if (getParams().walletAddress) {
      handleWalletAddress(getParams().walletAddress)
    }
    if (getParams().returnUrl) {
      handleReturnUrl(getParams().returnUrl)
    }
    if (getParams().custOrderId) {
      handleCustOrderId(getParams().custOrderId)
    }
    if (getParams().cryptoAmount) {
      handleApproximatelyAmount(getParams().cryptoAmount)
    }
    if (getParams().amount) {
      handleAmount(getParams().amount)
    }
    initOption()
  }, [])

  const initOption = () => {
    let fromOption = []
    let toOption = []
    fromCurrencyNEOOption.map((item) => {
        fromOption.push({ value: item.value, label: item.label, logo: item.logo })
      return []

    })
    
    handleFromCurrencyOption(fromOption)
    const authorization =
      getParams().authorization || localStorage.getItem('authorization')
    getCustCrypto(authorization).then((res) => {
      if (res.code === 0) {
        res.result.map((item) => {
          if (item === 'NEO') {
            toOption.push({ value: item, label: 'NEO(N3)', logo: NEO })
          } else if (item === 'GAS') {
            toOption.push({ value: item, label: 'GAS(N3)', logo: GAS })
          } else if (item === 'USDT-TRC20') {
            toOption.push({ value: item, label: 'USDT-TRC20', logo: USDT })
          } else if (item === 'bNEO') {
            toOption.push({ value: item, label: 'bNEO', logo: BNEO })
          } else if (item === 'FLM') {
            toOption.push({ value: item, label: 'FLM', logo: FLM })
          } else if (item === 'fUSDT') {
            toOption.push({ value: item, label: 'fUSDT', logo: USDT })
          } else if (item === 'ETH') {
            toOption.push({ value: item, label: 'ETH', logo: ETH })
          } else if (item === 'BTC') {
            toOption.push({ value: item, label: 'BTC', logo: BTC })
          } else {
            toOption.push({ value: item, label: item, logo: '' })
          }
        })
        initParams(res.result)
      } else {
        message.error(res.msg)
      }
    })
    handleToCurrencyOption(toOption)
  }

  const initParams = (cryptoParams) => {
    let params = {}
    if (getParams().currency) {
      handleFromCurrency(getParams().currency)
      params.from_currency = getParams().currency
      handleFromCurrencyDisabled(true)
    } else {
      params.from_currency = fromCurrency
    }
    if (getParams().crypto) {
      if (cryptoParams.indexOf(getParams().crypto) !== -1) {
        handleToCurrency(getParams().crypto)
        params.to_crypto = getParams().crypto
        handleToCryptoDisabled(true)
        if (getParams().crypto === 'NEO') {
          handleSelectDisabled(false)
        } else {
          handleSelectDisabled(true)
        }
      } else {
        handleToCurrency(cryptoParams[0])
        params.to_crypto = cryptoParams[0]
        handleSelectDisabled(true)
      }
    } else {
      params.to_crypto = cryptoParams[0]
      handleToCurrency(cryptoParams[0])
      if (cryptoParams[0] === 'NEO') {
        handleSelectDisabled(false)
      } else {
        handleSelectDisabled(true)
      }
    }
    let ApproximatelyAmount = 10
    if (getParams().cryptoAmount) {
      ApproximatelyAmount = getParams().cryptoAmount
    }
    initRate(params, ApproximatelyAmount)
  }

  const initRate = (params, approximatelyAmount) => {
    console.log(params)
    const authorization =
      getParams().authorization || localStorage.getItem('authorization')
    getRates(params, authorization)
      .then((response) => {
        const res = response.data
        if (res.code === 0) {
          if (res.result.rates.length) {
            let indexOfMax = 0
            let max_amount = res.result.rates[indexOfMax].max_amount
            let min_amount = res.result.rates[indexOfMax].min_amount
            res.result.rates.reduce(
              // eslint-disable-next-line no-return-assign
              (a, c, i) =>
                c.priority > a ? ((indexOfMax = i), c.priority) : a,
              0
            )
            let payment_methods = []
            res.result.rates.forEach((item)=>{
              payment_methods = payment_methods.concat(item.payment_methods||[])
              

            })
            if(payment_methods.includes('sepa')){
              setHiddenProcessFee(true)
            }
            else{
              setHiddenProcessFee(false)
            }
            localStorage.setItem('payment_methods' , JSON.stringify(payment_methods))
            //网络费率
            handleNetworkFee(res.result.network_fee)
            //网络费
            let networkFeeAmount = new BigNumber(
              res.result.rates[indexOfMax].rate
            )
              .multipliedBy(new BigNumber(res.result.network_fee))
              .toFixed(2)
            handleNetwork_fee_amount(networkFeeAmount)
            //币种汇率
            handleRate(res.result.rates[indexOfMax].rate)
            //币种最大值
            handleMaxAmount(max_amount)
            //币种最小值
            handleMinAmount(min_amount)
            //通道名称
            handleChannelName(res.result.rates[indexOfMax].channel_name)
            let defAmount = 0
            if (params.to_crypto === 'NEO') {
              if (!getParams().cryptoAmount) {
                handleApproximatelyAmount(10)
                //法币金额
                defAmount = new BigNumber(res.result.rates[indexOfMax].rate)
                  .multipliedBy(10)
                  .plus(
                    new BigNumber(
                      res.result.rates[indexOfMax].rate
                    ).multipliedBy(res.result.network_fee)
                  )
                  .toFixed(2)
                handleAmount(defAmount)
              } else {
                //法币金额
                defAmount = new BigNumber(res.result.rates[indexOfMax].rate)
                  .multipliedBy(approximatelyAmount)
                  .plus(
                    new BigNumber(
                      res.result.rates[indexOfMax].rate
                    ).multipliedBy(res.result.network_fee)
                  )
                  .toFixed(2)
                handleAmount(defAmount)
              }
            } else {
              if (getParams().amount) {
                defAmount = getParams().amount
                let approximatelyAmountInit = new BigNumber(getParams().amount)
                  .minus(
                    new BigNumber(
                      res.result.rates[indexOfMax].rate
                    ).multipliedBy(res.result.network_fee)
                  )
                  .dividedBy(new BigNumber(res.result.rates[indexOfMax].rate))
                  .toFixed(2, BigNumber.ROUND_DOWN)
                handleApproximatelyAmount(approximatelyAmountInit)
              } else {
                //法币金额
                defAmount = new BigNumber(res.result.rates[indexOfMax].rate)
                  .multipliedBy(approximatelyAmount)
                  .plus(
                    new BigNumber(
                      res.result.rates[indexOfMax].rate
                    ).multipliedBy(res.result.network_fee)
                  )
                  .toFixed(2)
                handleAmount(defAmount)
              }
            }
            if (defAmount > max_amount) {
              handleShowMaxErrorTips(true)
            } else {
              handleShowMaxErrorTips(false)
            }
            if (defAmount < min_amount) {
              handleShowMinErrorTips(true)
            } else {
              handleShowMinErrorTips(false)
            }
            handleProcessingFee(res.result.processing_fee_rate)
            handleProcessing_fee_amount(
              new BigNumber(defAmount)
                .multipliedBy(BigNumber(res.result.processing_fee_rate))
                .toFixed(4)
            )
          } else {
            message.error('currency not support')
          }
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  //I want to spend USD
  const amountChange = (e) => {
    handleAmount(e.target.value)
    handleApproximatelyAmount(
      e.target.value
        ? `${new BigNumber(e.target.value)
            .dividedBy(new BigNumber(rate))
            .toFixed(2, BigNumber.ROUND_DOWN)}`
        : ''
    )
    handleProcessing_fee_amount(
      new BigNumber(e.target.value)
        .multipliedBy(BigNumber(processing_fee))
        .toFixed(4)
    )
    if (e.target.value) {
      if (e.target.value > maxAmount) {
        handleShowMaxErrorTips(true)
      } else {
        handleShowMaxErrorTips(false)
      }
      if (e.target.value < minAmount) {
        handleShowMinErrorTips(true)
      } else {
        handleShowMinErrorTips(false)
      }
    }
  }

  //I want to buy NEO
  const approximatelyAmountChange = (e) => {
    if (toCurrency === 'NEO' && !regNumber.test(e.target.value)) {
      //TODO:
    } else {
      handleApproximatelyAmount(e.target.value)
      handleProcessing_fee_amount(
        new BigNumber(e.target.value)
          .multipliedBy(rate)
          .multipliedBy(BigNumber(processing_fee))
          .toFixed(4)
      )
      if (e.target.value) {
        handleAmount(
          new BigNumber(e.target.value).multipliedBy(rate).toFixed(2)
        )
      } else {
        handleAmount('0')
      }
      if (
        new BigNumber(e.target.value).multipliedBy(rate).toFixed(2) > maxAmount
      ) {
        handleShowMaxErrorTips(true)
      } else {
        handleShowMaxErrorTips(false)
      }
      if (
        new BigNumber(e.target.value).multipliedBy(rate).toFixed(2) < minAmount
      ) {
        handleShowMinErrorTips(true)
      } else {
        handleShowMinErrorTips(false)
      }
    }
  }

  const fromCurrencyChange = (value) => {
    if (isIos) {
      handleFromCurrencyOptionOpen(false)
      fromCurrencyRef.current.blur()
    }

    handleShowMaxErrorTips(false)
    handleShowMinErrorTips(false)
    let params = {
      from_currency: value,
      to_crypto: toCurrency,
    }
    handleFromCurrency(value)
    initRate(params, approximatelyAmount)
  }

  const toCurrencyChange = (value) => {
    if (isIos) {
      handleToCurrencyOptionOpen(false)
      toCurrencyRef.current.blur()
    }

    let params = {
      from_currency: fromCurrency,
      to_crypto: value,
    }
    handleToCurrency(value)
    if (value === 'NEO') {
      handleSelectDisabled(false)
    } else {
      handleSelectDisabled(true)
    }
    initRate(params, approximatelyAmount)
  }

  const renderFromCurrency = () => {
    if (isIos) {
      return (
        <Select
          ref={fromCurrencyRef}
          defaultValue={sepaOnly?'EUR':'USD'}
          value={fromCurrency}
          onChange={fromCurrencyChange}
          disabled={fromCurrencyDisabled}
          open={fromCurrencyOptionOpen}
          onFocus={() => handleFromCurrencyOptionOpen(true)}
          onBlur={() => handleFromCurrencyOptionOpen(false)}
        >
          {filterFromCurrencyOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                <img
                  src={item.logo}
                  alt={item.label}
                  style={{ width: '24px', marginRight: '10px' }}
                />
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    } else {
      return (
        <Select
          ref={fromCurrencyRef}
          defaultValue={sepaOnly?'EUR':'USD'}
          value={fromCurrency}
          onChange={fromCurrencyChange}
          disabled={fromCurrencyDisabled}
        >
          {filterFromCurrencyOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                <img
                  src={item.logo}
                  alt={item.label}
                  style={{ width: '24px', marginRight: '10px' }}
                />
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    }
  }

  const renderToCurrency = () => {
    if (isIos) {
      return (
        <Select
          ref={toCurrencyRef}
          // defaultValue='NEO'
          defaultValue={sepaOnly?'USDT-TRC20':'NEO'}
          value={toCurrency}
          onChange={toCurrencyChange}
          disabled={toCryptoDisabled}
          open={toCurrencyOptionOpen}
          onFocus={() => handleToCurrencyOptionOpen(true)}
          onBlur={() => handleToCurrencyOptionOpen(false)}
        >
          {toCurrencyOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                <img
                  src={item.logo}
                  alt={item.label}
                  style={{
                    width: '24px',
                    marginRight: '10px',
                  }}
                />
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    } else {
      return (
        <Select
          ref={toCurrencyRef}
          defaultValue={sepaOnly?'USDT-TRC20':'NEO'}
          value={toCurrency}
          onChange={toCurrencyChange}
          disabled={toCryptoDisabled}
        >
          {toCurrencyOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                <img
                  src={item.logo}
                  alt={item.label}
                  style={{
                    width: '24px',
                    marginRight: '10px',
                  }}
                />
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    }
  }

  const setUserFeedback = (value) => {
    let params = {
      status: value,
    }
    userFeedback(params).then((res) => {
      if (res.code === 0) {
        message.success('success')
        handleReviewListVisible(false)
      } else {
        message.error(`${res.msg}`)
      }
    })
  }

  const submitGetOrder = () => {
    if (!localStorage.getItem('authorization')) {
      props.history.push(
        `/buyCrypto/login?authorization=${authorization}&amount=${new BigNumber(
          amount
        ).toFixed(
          2
        )}&cryptoAmount=${approximatelyAmount}&crypto=${toCurrency}&currency=${fromCurrency}&walletAddress=${walletAddress}&custOrderId=${custOrderId}&returnUrl=${returnUrl}`
      )
    } else {
      if (showMaxErrorTips) {
        message.error(
          `Maximum allowed: ${numeral(maxAmount).format('0,0')} ${fromCurrency}`
        )
      } else if (showMinErrorTips) {
        message.error(
          `Minimum allowed: ${numeral(minAmount).format('0,0')} ${fromCurrency}`
        )
      } else if (!Number(amount)) {
        message.error('Please enter amount')
      } else {
        props.history.push(
          `/buyCrypto/address?amount=${new BigNumber(amount).toFixed(
            2
          )}&approximatelyAmount=${approximatelyAmount}&channel_name=${channel_name}&to_crypto=${toCurrency}&from_currency=${fromCurrency}&walletAddress=${walletAddress}&rate=${rate}&custOrderId=${custOrderId}&return_url=${returnUrl}`
        )
      }
    }
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'FastBuyContainer-embedded' : ''
        } FastBuyContainer`}
      >
        <HeaderTop title={t('FastBuy.title')} />
        <div className='wrapper-left-item'>
          <div className='select-label'>I want to spend</div>
          <div className='select-container'>
            <Input
              placeholder='00.00'
              value={amount}
              size='large'
              onChange={amountChange}
              disabled={
                !selectDisabled ||
                getParams().cryptoAmount ||
                getParams().amount
              }
              type='number'
            ></Input>
            {renderFromCurrency()}
          </div>
          <div className='errorTipsContent'>
            {showMaxErrorTips ? (
              <>
                <div className='triangle' />
                <div className='maxTips'>
                  Maximum allowed: {numeral(maxAmount).format('0,0')}{' '}
                  {fromCurrency}
                </div>
              </>
            ) : null}
            {showMinErrorTips ? (
              <>
                <div className='triangle' />
                <div className='maxTips'>
                  Minimum allowed: {numeral(minAmount).format('0,0')}{' '}
                  {fromCurrency}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className='wrapper-left-item wrapper-left-item-last'>
          <div className='select-label'>I want to buy</div>
          <div className='select-container'>
            <Input
              placeholder='00.00'
              value={approximatelyAmount}
              size='large'
              onChange={approximatelyAmountChange}
              disabled={
                selectDisabled || getParams().cryptoAmount || getParams().amount
              }
              type='number'
            ></Input>
            {renderToCurrency()}
          </div>
        </div>
        <div className='wrapper-left-item wrapper-left-item-last'>
          {toCurrency === 'NEO' ? (
            <div className='rateTipsLeft'>
              <img src={Jinggao} alt='jinggao' />
              <span className='rateTipsTitle rateTipsTitleLeft'>
                NEO is an indivisible currency. It means that it should
                represent a whole number.
              </span>
            </div>
          ) : null}
          <div className='rateTips'>
            <div className='rateTipsList'>
              <div className='rateTipsListLeft'>Purchase limit:</div>
              <div className='rateTipsListRight'>
                {localStorage.getItem('daily_amount') || '--'} {fromCurrency}{' '}
                limit
              </div>
            </div>
            <div className='rateTipsList'>
              <div className='rateTipsListLeft'>Final exchange rate:</div>
              <div className='rateTipsListRight'>
                1 {toCurrency} ≈ {Number(rate).toFixed(4)} {fromCurrency}
              </div>
            </div>
            <div className='rateTipsList' hidden={hiddenProcessFee}>
              <div className='rateTipsListLeft'>
                Processing fee:
                <Tooltip title='If the orders is refunded,the processing fee will not be returned'>
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
              <div className='rateTipsListRight'>
                {processing_fee_amount} {fromCurrency}
              </div>
            </div>
            <div className='rateTipsList'>
              <div className='rateTipsListLeft'>Network fee:</div>
              <div className='rateTipsListRight'>
                ~ {network_fee_amount} {fromCurrency}
              </div>
            </div>
            <div className='rateTipsList'>
              <div className='rateTipsListLeft'>Delivery time:</div>
              <div className='rateTipsListRight'>0.25 - 8 hours</div>
            </div>
          </div>
        </div>
        <Button
          type='primary'
          className='goToBtn'
          onClick={() => submitGetOrder()}
        >
          Continue
        </Button>
        <div className='powerContainer'>
          Powered by
          <img src={instLogo} alt='logo' />
        </div>
      </div>
      <Footer />
      <Modal
        title='The account is disabled'
        visible={blankListVisible}
        footer={null}
        onCancel={() => {
          handleBlankListVisible(false)
          props.history.push('/buyCrypto/setting')
        }}
        className='blankListContainer'
      >
        <div className='tipsContact'>
          Your account has been disabled due to violation of terms and
          conditions. Please contact us at
          <a href='mailto:support@inst.money'> support@inst.money</a> if you
          have any questions.
        </div>
      </Modal>
      <Modal
        title='Risk warning'
        visible={reviewListVisible}
        footer={null}
        onCancel={() => {
          handleReviewListVisible(false)
        }}
        className='blankListContainer'
      >
        <div className='wrapper-left-risk'>
          <div className='risk-text'>
            <div className='risk-title-tips'>
              1. You have a high-risk order, please send a photo as specified
              below to
              <a className='footerLink' href='mailto:support@inst.money'>
                &nbsp;support@inst.money
              </a>
              &nbsp;within 48 hours.
            </div>
            <div className='risk-tips'>
              &nbsp;&nbsp;(1) A photo of you holding your passport and a
              handwritten note.
            </div>
            <div className='risk-tips'>
              &nbsp;&nbsp;(2) Content on the note: Received item purchased from
              INST, by [your name]
            </div>
            <div className='risk-tips'>
              &nbsp;&nbsp;(3) Make sure your face on the photo, your photo on
              the passport and the content on the note are visible and clear.
            </div>
            <div className='risk-tips risk-tips-img'>
              <img src={passwordHoldImg} alt='' />
            </div>
            <div className='risk-tips risk-tips-weight'>
              2. If you don’t provide the photo within the time limit, we will
              refund this order, and the processing fee will not be returned.
            </div>
            <div className='risk-bottom'>
              <Button
                type='primary'
                className='risk-button'
                danger
                onClick={() => setUserFeedback(1)}
              >
                Refund
              </Button>
              <Button
                type='primary'
                className='risk-button'
                onClick={() => setUserFeedback(99)}
              >
                <a href='mailto:support@inst.money'> Get Support </a>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default withRouter(FastBuy)
