import React, { useState, useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { withRouter } from 'react-router-dom'
import BackImg from '../../../../assets/images/back.svg'
import ListImg from '../../../../assets/images/list.svg'
import './index.css'

const HeaderTop = (props) => {
  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  return (
    <div className='titleContainer'>
      <div className='titleContainerLeft'>
        <img src={BackImg} alt='back' onClick={() => props.history.go(-1)} />
        <span className='title'>{props?.title}</span>
      </div>
      <div className='titleContainerRight'>
        <img
          src={ListImg}
          alt='list'
          onClick={() => {
            if (!localStorage.getItem('authorization')) {
              props.history.push(
                `/buyCrypto/login/?authorization=${
                  getParams().authorization
                }&amount=${new BigNumber(getParams()?.amount).toFixed(
                  2
                )}&cryptoAmount=${getParams()?.cryptoAmount}&crypto=${
                  getParams()?.crypto
                }&currency=${getParams()?.currency}&walletAddress=${
                  getParams()?.walletAddress
                }&custOrderId=${getParams()?.custOrderId}&returnUrl=${
                  getParams()?.returnUrl
                }`
              )
            } else {
              props.history.push('/buyCrypto/setting')
            }
          }}
        />
      </div>
    </div>
  )
}

export default withRouter(HeaderTop)
