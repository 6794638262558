import React, { useState, useEffect } from 'react'
import { message, Modal, Spin, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import {
  getKycInfo,
  getUserStatusController,
  userFeedback,
} from '../../../utils/api'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import chaImg from '../../../assets/images/cha.svg'
import RightJiantou from '../../../assets/images/rightJiantou.svg'
import FastBuyImg from '../../../assets/images/fastBuyImg.svg'
import WalletHei from '../../../assets/images/walletHei.svg'
import HistoryImg from '../../../assets/images/historyImg.svg'
import cardImg from '../../../assets/images/cardImg.svg'
import KycImg from '../../../assets/images/kycImg.svg'
import SignOutImg from '../../../assets/images/signOutImg.svg'
import KefuImg from '../../../assets/images/kefuImg.svg'
import passwordHoldImg from '../../../assets/images/passwordHoldImg.png'
import './index.css'

const Setting = (props) => {
  const { t } = useTranslation()
  const [title, handleTitle] = useState('')
  const [authorization, handleAuthorization] = useState('')
  const [status, handleStatus] = useState(null)
  const [blankListVisible, handleBlankListVisible] = useState(false)
  const [reviewListVisible, handleReviewListVisible] = useState(false)
  const [loading, handleLoading] = useState(false)
  const [buyOneOrderEnable, handleBuyOneOrderEnable] = useState(false)

  const listTab = [
    { title: 'Buy Crypto', logo: RightJiantou, leftImg: FastBuyImg },
    { title: 'Wallet', logo: RightJiantou, leftImg: WalletHei },
    { title: 'Trade History', logo: RightJiantou, leftImg: HistoryImg },
    { title: 'Payment Method', logo: RightJiantou, leftImg: cardImg },
    { title: 'Identification', logo: RightJiantou, leftImg: KycImg },
    { title: 'Customer Care', logo: RightJiantou, leftImg: KefuImg },
    { title: 'Sign Out', logo: RightJiantou, leftImg: SignOutImg },
  ]
  useEffect(() => {
    if (localStorage.getItem('inst_payone_pay_mail')) {
      handleTitle(localStorage.getItem('inst_payone_pay_mail'))
      handleAuthorization(localStorage.getItem('authorization'))
    }
    getKyc()
  }, [])

  const getKyc = () => {
    getKycInfo().then((res) => {
      if (res.code === 0) {
        handleStatus(res.result?.basic_kyc_status)
        handleBuyOneOrderEnable(res.result?.buy_one_order_enable)
      }
    })
  }

  const renderList = () => {
    return listTab.map((item) => {
      return (
        <div
          className='listContainer'
          onClick={() => goRouter(item)}
          key={item.title}
        >
          <div className='listLeft'>
            <img src={item.leftImg} />
            <span>{item.title}</span>
          </div>
          <div className='listRight'>
            <img src={item.logo} alt='img' />
          </div>
        </div>
      )
    })
  }

  const goRouter = (item) => {
    if (item.title === 'Buy Crypto' || item.title === 'Wallet') {
      handleLoading(true)
      getUserStatusController()
        .then((res) => {
          handleLoading(false)
          if (res.code === 310002) {
            handleBlankListVisible(true)
          } else {
            if (item.title === 'Buy Crypto') {
              props.history.push(`/buyCrypto/fastBuy`)
            } else {
              props.history.push(`/buyCrypto/wallet`)
            }
          }
        })
        .catch(() => {
          handleLoading(false)
        })
    } else if (item.title === 'Trade History') {
      const canBuyCrypto = status === 2 || buyOneOrderEnable 
      if (canBuyCrypto) {
        props.history.push(`/buyCrypto/history`)
      } else {
        message.error('Your identity verification is under review')
      }
    } else if (item.title === 'Payment Method') {
      props.history.push('/buyCrypto/cardManagement')
    } else if (item.title === 'Identification') {
      props.history.push('/buyCrypto/kycCenter')
    } else if (item.title === 'Sign Out') {
      localStorage.clear()
      props.history.push(`/buyCrypto/login?authorization=${authorization}`)
    } else if (item.title === 'Customer Care') {
      window.location.href = 'mailto:support@inst.money'
    }
  }

  const setUserFeedback = (value) => {
    let params = {
      status: value,
    }
    userFeedback(params).then((res) => {
      if (res.code === 0) {
        message.success('success')
        handleReviewListVisible(false)
      } else {
        message.error(`${res.msg}`)
      }
    })
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'settingContainer-embedded' : ''
        } settingContainer`}
      >
        <div className='titleContainer'>
          <div className='titleContainerLeft'>
            <span className='title'>{title ? `${title}` : ''}</span>
          </div>
          <div className='titleContainerRight'>
            <img
              src={chaImg}
              style={{ width: '16px' }}
              alt='list'
              onClick={() => {
                props.history.go(-1)
              }}
            />
          </div>
        </div>
        <Spin spinning={loading}>{title ? renderList() : null}</Spin>
      </div>
      <Footer />
      <Modal
        title='The account is disabled'
        visible={blankListVisible}
        footer={null}
        onCancel={() => {
          handleBlankListVisible(false)
        }}
        className='blankListContainer'
      >
        <div className='tipsContact'>
          Your account has been disabled due to violation of terms and
          conditions. Please contact us at
          <a href='mailto:support@inst.money'> support@inst.money</a> if you
          have any questions.
        </div>
      </Modal>
      <Modal
        title='Risk warning'
        visible={reviewListVisible}
        footer={null}
        onCancel={() => {
          handleReviewListVisible(false)
        }}
        className='blankListContainer'
      >
        <div className='wrapper-left-risk'>
          <div className='risk-text'>
            <div className='risk-title-tips'>
              1. You have a high-risk order, please send a photo as specified
              below to
              <a className='footerLink' href='mailto:support@inst.money'>
                &nbsp;support@inst.money
              </a>
              &nbsp;within 48 hours.
            </div>
            <div className='risk-tips'>
              &nbsp;&nbsp;(1) A photo of you holding your passport and a
              handwritten note.
            </div>
            <div className='risk-tips'>
              &nbsp;&nbsp;(2) Content on the note: Received item purchased from
              INST, by [your name]
            </div>
            <div className='risk-tips'>
              &nbsp;&nbsp;(3) Make sure your face on the photo, your photo on
              the passport and the content on the note are visible and clear.
            </div>
            <div className='risk-tips risk-tips-img'>
              <img src={passwordHoldImg} alt='' />
            </div>
            <div className='risk-tips risk-tips-weight'>
              2. If you don’t provide the photo within the time limit, we will
              refund this order, and the processing fee will not be returned.
            </div>
            <div className='risk-bottom'>
              <Button
                type='primary'
                className='risk-button'
                onClick={() => setUserFeedback(1)}
              >
                Refund
              </Button>
              <Button
                type='primary'
                className='risk-button'
                onClick={() => setUserFeedback(99)}
              >
                <a href='mailto:support@inst.money'> Get Support </a>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default withRouter(Setting)
