const authBefore = 'Inst:8c9a0c618e144f5ab76fc35d706fbec9:'
const authAfter = ':yYXX2O6Pn0PVFDpXeSYodHrlUk5URKrO2akSH4drLJ0='

const neoAuthBefore = 'Inst:8f8c23fd5bfc4f34a7bfd9746596a2f9:'

const payDefaultUrl = 'https://pay.inst.money/buyCrypto/history'

const websitePrivacyUrl = 'https://inst.money/#/privacy'
const websiteTermsUrl = 'https://inst.money/#/terms'
const websiteConditionUrl = 'https://inst.money/#/conditions'

const siteIdV1 = '0a06199cac43'
const siteIdV2 = '602432b6ac22'

export {
  authBefore,
  authAfter,
  neoAuthBefore,
  payDefaultUrl,
  websitePrivacyUrl,
  websiteTermsUrl,
  websiteConditionUrl,
  siteIdV1,
  siteIdV2,
}
