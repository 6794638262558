import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Checkbox } from 'antd-mobile'
import { Spin, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './index.css'
import Header from '../../Components/Header'
import CircleClock from '../../../utils/canvas'
import { getCheckout, choosePayment, cancelPayment } from '../../../utils/api'
import Footer from '../../Components/Footer'
import i18n from '../../../react-i18next-config'
import { CoinJson, CoinPay } from '../../../utils/coinJson'
import visa from '../../../assets/images/visa.svg'
import visaMaster from '../../../assets/images/visa.png'
import cardTips1 from '../../../assets/images/cardTips1.png'
import cardTips2 from '../../../assets/images/cardTips2.png'
import cardTips3 from '../../../assets/images/cardTips3.png'
import cardTips4 from '../../../assets/images/cardTips4.png'
import closeImg from '../../../assets/images/close.png'
import NewPaymentChannel from "./NewPaymentChannel"

const CheckboxItem = Checkbox.CheckboxItem

const CurrencyList = (props) => {
  const { t } = useTranslation()
  const actionRef = useRef()
  const actionRefPayWay = useRef()
  const actionRefDataCash = useRef()
  const actionRefWaafipay = useRef()
  const [id, handleId] = useState('')
  const [loading, handleLoading] = useState(true)
  const [subLoading, handleSubLoading] = useState(false)
  const [url, handleUrl] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')
  const [pageFrom, handlePageFrom] = useState(null)

  const [cryptos, handleCryptos] = useState([])
  const [payType, handlePayType] = useState([])
  const [merchant_name, handleMerchant_name] = useState('')
  const [amount, handleAmount] = useState('')
  const [currency, handleCurrency] = useState('')
  const [email, handleEmail] = useState('josh_chow@163.com')
  const [ac_sci_name, handleAc_sci_name] = useState('inst.money')
  const [ac_order_id, handleAc_order_id] = useState('')
  const [ac_sign, handleAc_sign] = useState('')
  const [chooseItemIndex, handleChooseItemIndex] = useState(null)
  const [chooseCardTypeIndex, handleChooseCardTypeIndex] = useState(null)
  const [chooseItem, handleChooseItem] = useState(null)
  const [chooseCard, handleChooseCard] = useState(false)
  const [chooseCardType, handleChooseCardType] = useState(false)

  //payWay参数
  const [hash, handleHash] = useState('')
  const [tranId, handleTranId] = useState('')
  const [payWayAmount, handlePayWayAmount] = useState('')
  const [items, handleItems] = useState('')
  const [continueSuccessUrl, handleContinueSuccessUrl] = useState('')
  const [firstName, handleFirstName] = useState('')
  const [lastName, handleLastName] = useState('')
  const [phone, handlePhone] = useState('')
  const [payWayEmail, handlePayWayEmail] = useState('')
  const [country, handleCountry] = useState('')
  const [state, handleState] = useState('')
  const [city, handleCity] = useState('')
  const [address, handleAddress] = useState('')
  const [zipcode, handleZipcode] = useState('')

  //DataCash参数
  const [vpc_access_code, handleVpcAccessCode] = useState('')
  const [vpc_amount, handleVpcAmount] = useState('')
  const [vpc_command, handleVpcCommand] = useState('')
  const [vpc_locale, handleVpcLocale] = useState('')
  const [vpc_merch_txn_ref, handleVpcMerchTxnRef] = useState('')
  const [vpc_merchant, handleVpcMerchant] = useState('')
  const [vpc_order_info, handleVpcOrderInfo] = useState('')
  const [vpc_return_url, handleVpcReturnUrl] = useState('')
  const [vpc_secure_hash, handleVpcSecureHash] = useState('')
  const [vpc_version, handleVpcVersion] = useState('')
  const [vpc_SecureHashType, handleVpcSecureHashType] = useState('')
  const [vpc_Currency, handleVpcCurrency] = useState('')
  const [vpc_ReturnAuthResponseData, handleVpc_ReturnAuthResponseData] =
    useState('')
  const [vpc_Gateway, handleVpcGateway] = useState('')
  const [vpc_Card, handleVpcCard] = useState('')

  //Waafipay参数
  const [waafipayUrl, handleWaafipayUrl] = useState('')
  const [waafipayRequestId, handleWaafipayRequestId] = useState('')
  const [waafipayReferenceId, handleWaafipayReferenceId] = useState('')

  // newChannel
  const [paymentChannel, handlePaymentChannel] = useState('')
  const [paymentChannelTarget, handlePaymentChannelTarget] = useState({})
  const newPaymentChannelRef = useRef()


  const visaMasterData = [
    {
      label: t('home.visaMasterData_label1'),
      img: cardTips3,
      id: 1,
    },
    {
      label: t('home.visaMasterData_label2'),
      img: cardTips2,
      id: 2,
    },
    {
      label: t('home.visaMasterData_label3'),
      img: cardTips1,
      id: 3,
    },
  ]

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  useEffect(() => {
    if (getParams().lang) {
      i18n.changeLanguage(getParams().lang)
    }
    if (getParams().pageFrom) {
      handlePageFrom(getParams().pageFrom)
    }
    judgePayment()
  }, [])

  //区分不同支付方式
  const judgePayment = () => {
    if (getParams().status === '5' && getParams().id) {
      cancel(getParams().id)
    } else if (getParams().id) {
      handleId(getParams().id)
      //TODO:
      //url后面currency参数功能
      if (getParams().currency === 'fiat') {
        //选择的法币支付
        let params = {
          id: getParams().id,
          currency: 'FIAT',
        }
        chooseFiatPayment(params)
      } else if (getParams().currency !== 'fiat' && !!getParams().currency) {
        //未选择法币但有currency参数
        judgeCheckout(getParams().id)
      } else {
        //无currency参数
        chooseDefaultPayment()
      }
    } else {
      message.error('order number no exist')
      handleLoading(false)
    }
  }

  const chooseFiatPayment = (params) => {
    choosePayment(params)
      .then((response) => {
        const res = response.data
        if (res.code === 0) {
          props.history.push(`/pay/${id}`)
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  const judgeCheckout = (id) => {
    let params = { id }
    getCheckout(params).then((response) => {
      let res = response.data
      if (res.code === 0) {
        let isSupport = false
        res.result?.cryptos.map((item) => {
          if (item.symbol === getParams().currency) {
            isSupport = true
            let params = {
              id,
              currency: item.symbol,
            }
            choosePayment(params)
              .then((response) => {
                const res = response.data
                if (res.code === 0) {
                  props.history.push(`/pay/${id}`)
                } else {
                  message.error(res.msg)
                }
              })
              .catch((err) => {
                message.error(err)
              })
          }
        })
        if (!isSupport) {
          chooseDefaultPayment()
        }
      }
    })
  }

  const chooseDefaultPayment = () => {
    let params = { id: getParams().id }
    getCheckout(params)
      .then((response) => {
        let res = response.data
        if (res.code === 0) {
          if (res.result.status === 23) {
            //订单为支付中,选过通道了
          
            if(res.result.inte_type){
              handlePaymentChannel(res.result.inte_type)
            }
            

            if (res.result.inte_type === 'crypto') {
              //订单为虚拟币
              props.history.push(`/pay/${getParams().id}`)
            } else if (res.result.inte_type === 'post_payway') {
              //订单为法币,payWay支付
              handlePayType(res.result.pay_type)
              handleAmount(res.result.amount)
              handleMerchant_name(res.result.merchant_name)
              handleHash(res.result.data.post_payway.hash)
              handleTranId(res.result.data.post_payway.tran_id)
              handlePayWayAmount(res.result.data.post_payway.amount)
              handleItems(res.result.data.post_payway.items)
              handleContinueSuccessUrl(
                res.result.data.post_payway.continue_success_url
              )
              handleFirstName(res.result.data.customer.first_name)
              handleLastName(res.result.data.customer.last_name)
              handlePhone(res.result.data.customer.phone)
              handlePayWayEmail(res.result.data.customer.email)
              handleCountry(res.result.data.customer.country)
              handleState(res.result.data.customer.state)
              handleCity(res.result.data.customer.city)
              handleAddress(res.result.data.customer.address)
              handleZipcode(res.result.data.customer.zipcode)
              actionRefPayWay.current.submit()
            } else if (res.result.inte_type === 'post_datacash') {
              handleVpcAccessCode(
                res.result.data.post_data_cash_dto.vpc_AccessCode
              )
              handleVpcAmount(res.result.data.post_data_cash_dto.vpc_Amount)
              handleVpcCommand(res.result.data.post_data_cash_dto.vpc_Command)
              handleVpcLocale(res.result.data.post_data_cash_dto.vpc_Locale)
              handleVpcMerchTxnRef(
                res.result.data.post_data_cash_dto.vpc_MerchTxnRef
              )
              handleVpcMerchant(res.result.data.post_data_cash_dto.vpc_Merchant)
              handleVpcOrderInfo(
                res.result.data.post_data_cash_dto.vpc_OrderInfo
              )
              handleVpcReturnUrl(
                res.result.data.post_data_cash_dto.vpc_ReturnURL
              )
              handleVpcSecureHash(
                res.result.data.post_data_cash_dto.vpc_SecureHash
              )
              handleVpcVersion(res.result.data.post_data_cash_dto.vpc_Version)
              handleVpcSecureHashType('SHA256')
              handleVpcCurrency(res.result.data.post_data_cash_dto.vpc_Currency)
              handleVpc_ReturnAuthResponseData(
                res.result.data.post_data_cash_dto.vpc_ReturnAuthResponseData
              )
              handleVpcGateway(res.result.data.post_data_cash_dto.vpc_Gateway)
              handleVpcCard(res.result.data.post_data_cash_dto.vpc_Card)
              if (res.result?.data?.post_data_cash_dto?.location) {
                window.location.href =
                  res.result?.data?.post_data_cash_dto?.location
              } else {
                actionRefDataCash.current.submit()
              }
            } else if (res.result.inte_type === 'post_airwallex') {
              handleAmount(res.result.amount)
              handleMerchant_name(res.result.merchant_name)
              handleCurrency(res.result.currency)
              window.location.href = res.result.data.airwallex_order_dto.url
            } else if (res.result.inte_type === 'post_airwallex_intent') {
              props.history.push(`/awx/${getParams().id}`)
            } else if (res.result.inte_type === 'post_waafipay') {
              handleWaafipayUrl(res.result.data?.post_waafipay?.hpp_url)
              handleWaafipayRequestId(
                res.result.data?.post_waafipay?.hpp_request_id
              )
              handleWaafipayReferenceId(
                res.result.data?.post_waafipay?.reference_id
              )
              actionRefWaafipay.current.submit()
            } else if (res.result.inte_type === 'post_sathapana') {
              window.location.href = res.result.data.post_sathapana.redirect
              // window.Checkout.configure({
              //   session: {
              //     id: res.result.data?.post_sathapana.session_id,
              //   },
              //   interaction: {
              //     merchant: {
              //       name: 'TINHTINH_SHOP',
              //     },
              //   },
              //   order: {
              //     description: getParams().id,
              //   },
              // })
              // setTimeout(() => window.Checkout.showPaymentPage(), 500)
            } 
            else if (res.result.inte_type === "post_securepay") {
              if(res?.result?.data?.post_secure_pay){
                handlePaymentChannelTarget(res.result.data.post_secure_pay)
                  newPaymentChannelRef.current.submit()
              }
            } 
            else if(res.result.inte_type === "post_jdbpay"){
              if (res.result.data.redirect) {
                window.location.href = res.result.data.redirect
              }
            }
            else if(res.result.inte_type === "Checkout"){
              props.history.push(`/cko/${getParams().id}`)
            }
            
            else {
              //订单为法币
              if (res.result.inte_type === 'post_adv') {
                //订单为法币,adv支付
                //TODO:区分adv-master和adv-visa
                handlePayType(res.result.pay_type)
                handleAc_order_id(getParams().id)
                handleAmount(res.result.amount)
                handleMerchant_name(res.result.merchant_name)
                handleCurrency(res.result.currency)
                handleEmail(res.result.data.post_adv.email)
                handleAc_sci_name(res.result.data.post_adv.sci_name)
                handleAc_sign(res.result.data.post_adv.signature)
                handleUrl(res.result.return_url)
                actionRef.current.submit()
              } else if (
                res.result.inte_type === 'publickey' ||
                res.result.inte_type === 'publickey-verify' ||
                res.result.inte_type === 'publickey-withname'
              ) {
                props.history.push(
                  `/bank-card/${getParams().id}?type=${
                    res.result.inte_type
                  }&dev_fingerprint_url=${
                    res.result?.data?.dev_fingerprint_url || ''
                  }`
                )
              } else if (res.result.inte_type === 'post_checkout') {
                props.history.push(`/cko/${getParams().id}`)
              } 
              else if (res.result.inte_type === 'post_checkout_intent') {
                props.history.push(
                  `result-payment-inProgress/?id=${getParams().id}`
                )
              } 
              else if (res.result.inte_type === 'qrcode') {
                props.history.push(`/quick-pass/${getParams().id}`)
              } else if (res.result.inte_type === 'redirect') {
                if (res.result.data.redirect) {
                  window.location.href = res.result.data.redirect
                }
              } else if (res.result.inte_type === 'post_photon') {
                console.log('window===>', window.window.cashierAPI)
                // console.log(
                //   'window2===>',
                //   window.window.cashierAPI.payout({
                //     authCode: 'bqywvkuT0Q5edc',
                //     payMethod: 'Credit Card',
                //   })
                // )
              }
              else if(res.result.inte_type === 'post_meapay' ){
                props.history.push(
                  `/bank-card/${getParams().id}?type=${
                    res.result.inte_type
                  }`
                )
              }
              else if(res.result.inte_type === 'post_jdbpay_direct' ){
                props.history.push(
                  `/bank-card/${getParams().id}?type=${
                    res.result.inte_type
                  }`
                )
              }
              else if(res.result.inte_type === 'post_sepa' ){
                props.history.push(`/sepa/${getParams().id}`)
              }
              else{
                console.log('loading')
                // props.history.push(`/result-payment-inProgress/?id=${getParams().id}`)
              }
            }
          } else if (res.result.status === 26) {
            //订单为没选过通道
            if (res.result.choose_pay_type) {
              //代表自动选择法币通道de
              let params = {
                id: getParams().id,
                currency: 'FIAT',
                pay_type: res.result.choose_pay_type,
              }
              judgeChannel(params)
            } else {
              handleLoading(false)
              handleCurrency(res.result.currency)
              handleAmount(res.result.amount)
              handleMerchant_name(res.result.merchant_name)
              handleUrl(res.result.return_url)
              drawCanvas(res.result.expires_at)
              handleLogo(res.result.logo)
              handleTitle(res.result.title)
              handlePayType(res.result.pay_type)
              handleCryptos(res.result.cryptos)
            }
          } else {
            handleLoading(false)
            if (res.result.status === 0) {
              props.history.push(
                `result-payment-inProgress/?id=${getParams().id}`
              )
            }
            if (res.result.status === 1) {
              props.history.push(`result-success/?id=${getParams().id}`)
            }
            if (res.result.status === 2) {
              props.history.push(
                `result-payment-confirming/?id=${getParams().id}`
              )
            }
            if (res.result.status === 3) {
              props.history.push(`result-payment-error/?id=${getParams().id}`)
            }
            if (res.result.status === 4) {
              props.history.push(`result-fail/?id=${getParams().id}`)
            }
            if (res.result.status === 5) {
              props.history.push(`result-cancel/?id=${getParams().id}`)
            }
            if (res.result.status === 6) {
              props.history.push(`result-timeout/?id=${getParams().id}`)
            }
            if (res.result.status === 7) {
              props.history.push(
                `result-refund-inProgress/?id=${getParams().id}`
              )
            }
            if (res.result.status === 8) {
              props.history.push(`result-refund-success/?id=${getParams().id}`)
            }
            if (res.result.status === 9) {
              props.history.push(`result-refund-fail/?id=${getParams().id}`)
            }
          }
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        handleLoading(false)
        message.error(err)
      })
  }

  const drawCanvas = (expires_at) => {
    let dataTime = Date.now()
    if (Number(expires_at) > dataTime) {
      if (24 * 60 * 60 > (Number(expires_at) - dataTime) / 1000) {
        const circleClock = new CircleClock('canvas')
        circleClock.run((Number(expires_at) - dataTime) / 1000, () => {
          //TODO:
          console.log('倒计时执行完毕')
        })
      }
    }
  }

  const judgeChannel = (params) => {
    choosePayment(params)
      .then((response) => {
        handleSubLoading(false)
        let res = response.data
        if (res.code === 0) {
          if(res.result.inte_type){
            handlePaymentChannel(res.result.inte_type)
          }
          if (params.pay_type === 'Payway') {
            handleHash(res.result.data.post_payway.hash)
            handleTranId(res.result.data.post_payway.tran_id)
            handlePayWayAmount(res.result.data.post_payway.amount)
            handleItems(res.result.data.post_payway.items)
            handleContinueSuccessUrl(
              res.result.data.post_payway.continue_success_url
            )
            handleFirstName(res.result.data.customer.first_name)
            handleLastName(res.result.data.customer.last_name)
            handlePhone(res.result.data.customer.phone)
            handlePayWayEmail(res.result.data.customer.email)
            handleCountry(res.result.data.customer.country)
            handleState(res.result.data.customer.state)
            handleCity(res.result.data.customer.city)
            handleAddress(res.result.data.customer.address)
            handleZipcode(res.result.data.customer.zipcode)
            actionRefPayWay.current.submit()
          } else if (params.pay_type === 'Datacash') {
            handleVpcAccessCode(
              res.result.data.post_data_cash_dto.vpc_AccessCode
            )
            handleVpcAmount(res.result.data.post_data_cash_dto.vpc_Amount)
            handleVpcCommand(res.result.data.post_data_cash_dto.vpc_Command)
            handleVpcLocale(res.result.data.post_data_cash_dto.vpc_Locale)
            handleVpcMerchTxnRef(
              res.result.data.post_data_cash_dto.vpc_MerchTxnRef
            )
            handleVpcMerchant(res.result.data.post_data_cash_dto.vpc_Merchant)
            handleVpcOrderInfo(res.result.data.post_data_cash_dto.vpc_OrderInfo)
            handleVpcReturnUrl(res.result.data.post_data_cash_dto.vpc_ReturnURL)
            handleVpcSecureHash(
              res.result.data.post_data_cash_dto.vpc_SecureHash
            )
            handleVpcVersion(res.result.data.post_data_cash_dto.vpc_Version)
            handleVpcSecureHashType('SHA256')
            handleVpcCurrency(res.result.data.post_data_cash_dto.vpc_Currency)
            handleVpc_ReturnAuthResponseData(
              res.result.data.post_data_cash_dto.vpc_ReturnAuthResponseData
            )
            handleVpcGateway(res.result.data.post_data_cash_dto.vpc_Gateway)
            handleVpcCard(res.result.data.post_data_cash_dto.vpc_Card)
            if (res.result?.data?.post_data_cash_dto?.location) {
              window.location.href =
                res.result?.data?.post_data_cash_dto?.location
            } else {
              actionRefDataCash.current.submit()
            }
          } else if (params.pay_type === 'Waafipay') {
            handleWaafipayUrl(res.result.data?.post_waafipay?.hpp_url)
            handleWaafipayRequestId(
              res.result.data?.post_waafipay?.hpp_request_id
            )
            handleWaafipayReferenceId(
              res.result.data?.post_waafipay?.reference_id
            )
            actionRefWaafipay.current.submit()
          } else if (params.pay_type === 'Airwallex') {
            window.location.href = res.result.data.airwallex_order_dto.url
          } else if (params.pay_type === 'Saebo') {
            window.location.href = res.result.data.saebo_resp_dto.url
          } else if (params.pay_type === 'Airwallex Intent') {
            props.history.push(`/awx/${getParams().id}`)
          } else if (params.pay_type === 'Sathapana') {
            window.location.href = res.result.data.post_sathapana.redirect
            // chooseSathapana(res.result)
          } else if (params.pay_type === 'Checkout') {
            props.history.push(`/cko/${getParams().id}`)
          } 
           else if (
            res.result.inte_type === 'publickey' ||
            res.result.inte_type === 'publickey-verify' ||
            res.result.inte_type === 'publickey-withname'
          ) {
            props.history.push(
              `/bank-card/${getParams().id}?type=${
                res.result.inte_type
              }&dev_fingerprint_url=${
                res.result?.data?.dev_fingerprint_url || ''
              }`
            )
          } else if (res.result.inte_type === 'Checkout') {
            props.history.push(`/cko/${getParams().id}`)
          } else if (res.result.inte_type === 'qrcode') {
            //TODO:云闪付
            props.history.push(`/quick-pass/${getParams().id}`)
          } else if (res.result.inte_type === 'redirect') {
            //TODO:银联
            if (res.result.data.redirect) {
              window.location.href = res.result.data.redirect
            }
          } 
          else if (res.result.inte_type === "post_securepay") {
            if(res?.result?.data?.post_secure_pay){
              handlePaymentChannelTarget(res.result.data.post_secure_pay)
                newPaymentChannelRef.current.submit()
            }
          }
          else if(res.result.inte_type === "post_jdbpay"){
            if (res.result.data.redirect) {
              window.location.href = res.result.data.redirect
            }
          }
          else if(res.result.inte_type === 'post_meapay' ){
            props.history.push(
              `/bank-card/${getParams().id}?type=${
                res.result.inte_type
              }`
            )
          }
          else if(res.result.inte_type === 'post_sepa' ){
            props.history.push(`/sepa/${getParams().id}`)
          }
          else if(res.result.inte_type === 'post_jdbpay_direct' ){
            props.history.push(
              `/bank-card/${getParams().id}?type=${
                res.result.inte_type
              }`
            )
          } 
          else {
            handleAc_order_id(getParams().id)
            handleAmount(res.result.amount)
            handleCurrency(res.result.currency)
            handleEmail(res.result.data.post_adv.email)
            handleAc_sci_name(res.result.data.post_adv.sci_name)
            handleAc_sign(res.result.data.post_adv.signature)
            handleUrl(res.result.return_url)
            actionRef.current.submit()
          }
        } else {
          handleSubLoading(false)
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  // const chooseSathapana = (params) => {
  //   window.Checkout.configure({
  //     session: {
  //       id: params.data.post_sathapana.session_id,
  //     },
  //     interaction: {
  //       merchant: {
  //         name: 'tinhtinh shop',
  //         address: {
  //           line1: '200 Sample st',
  //           line2: '1234 Example Town',
  //         },
  //         email: '123@123.com',
  //         phone: '+855 0123123123',
  //         logo: 'https://tinhtinhimg.zxkjcn.cn/service_logo.png',
  //       },
  //       locale: 'en_US',
  //       // style: {
  //       // 	theme: 'default'
  //       // },
  //       displayControl: {
  //         billingAddress: 'HIDE',
  //         customerEmail: 'HIDE',
  //         shipping: 'HIDE',
  //       },
  //     },
  //   })
  //   window.Checkout.showPaymentPage()
  // }

  const choose = async (item) => {
    console.log(item)
    handleSubLoading(true)
    if (chooseCard) {
      if (chooseCardType === 'Checkout') {
        props.history.push(`/cko/${id}`)
      } else {
        let params = {
          id,
          currency: 'FIAT',
          pay_type: chooseCardType,
        }
        judgeChannel(params)
      }
    } else {
      let params = {
        id,
        currency: item.symbol,
      }
      choosePayment(params)
        .then((response) => {
          console.log('response',response)
          handleSubLoading(false)
          const res = response.data
          if (res.code === 0) {
            props.history.push(`/pay/${id}`)
          } else {
            message.error(res.msg)
          }
        })
        .catch((err) => {
          handleSubLoading(false)
          message.error(err)
        })
    }
  }

  const renderLeftTitle = (item) => {
    if (item.inte_type === 'gateway') {
      if (
        item.symbol === 'USDT' ||
        item.symbol === 'BUSD-BEP20' ||
        item.symbol === 'USDT-BEP20' ||
        item.symbol === 'USDT-TRC20' ||
        item.symbol === 'DAI'
      ) {
        return (
          <div className='listItemLeftUsdt'>
            <div className='listItemLeftUsdtContainer'>
              <div className='listItemLeft'>
                <img className='listItemImg' src={visaMaster} alt='img'></img>
              </div>
              <div className='listItemRight'>Visa/MasterCard</div>
            </div>
          </div>
        )
      } else {
        return (
          <>
            <div className='listItemLeft'>{renderImg(item.symbol)}</div>
            <div className='listItemRight'>{item.name}</div>
          </>
        )
      }
    } else {
      return (
        <>
          <div className='listItemLeft'>{renderImg(item.symbol)}</div>
          <div className='listItemRight'>{item.name}</div>
        </>
      )
    }
  }

  const renderContainer = () =>
    cryptos.map((item, index) => {
      return (
        <div
          className={chooseItemIndex === index ? 'listItemChoose' : 'listItem'}
          key={item.name}
          onClick={() => {
            handleChooseItemIndex(index)
            handleChooseItem(item)
            handleChooseCardTypeIndex(null)
            handleChooseCardType(null)
            handleChooseCard(false)
          }}
        >
          <div className='listItemContainer'>
            <div className='listItemTop'>
              <div className='listItemLeft'>{renderLeftTitle(item)}</div>
              <div className='listItemLeftContainer'>
                {chooseItemIndex === index ? (
                  <CheckboxItem checked={true} />
                ) : (
                  <CheckboxItem checked={false} />
                )}
              </div>
            </div>
            {item.inte_type === 'gateway' && chooseItemIndex === index ? (
              <div className='listItemBottomTipsContainer'>
                <img src={cardTips4} className='cardTips4' alt='img' />
                <div className='listItemCardTipsText'>
                  {item.symbol === 'USDT' ||
                  item.symbol === 'BUSD-BEP20' ||
                  item.symbol === 'USDT-BEP20' ||
                  item.symbol === 'USDT-TRC20'
                    ? `${t('home.cryptos_tips1')} (${item.name}).`
                    : `${t('home.cryptos_tips2')} (${item.name}).`}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )
    })

  const renderImg = (symbol) => {
    return <img className='listItemImg' src={CoinJson[symbol]} alt='img'></img>
  }

  const onSubmits = () => {
    handleChooseCard(true)
    handleChooseItemIndex(null)
    handleChooseItem(null)
  }

  const renderPayType = () => {
    return payType.map((item, index) => {
      if (item === 'Visa' || item === 'Visa/Master') {
        return (
          <>
            <form
              method='post'
              action='https://wallet.inst.money/sci/'
              ref={actionRef}
              key={item}
            >
              <input type='hidden' name='ac_ps' value='MASTERCARD' />
              <input type='hidden' name='ac_account_email' value={email} />
              <input type='hidden' name='ac_sci_name' value={ac_sci_name} />
              <input
                type='hidden'
                name='ac_amount'
                value={amount}
                className='input'
              />
              <input
                type='hidden'
                name='ac_currency'
                value={currency}
                className='input'
              />
              <input
                type='hidden'
                name='ac_order_id'
                value={ac_order_id}
                className='input'
              />
              <input
                type='hidden'
                name='ac_sign'
                value={ac_sign}
                className='input'
              />
              <input
                type='hidden'
                name='ac_success_url'
                value={url}
                disabled={true}
              />
              <input
                type='hidden'
                name='ac_fail_url'
                value={url}
                disabled={true}
              />
              <input
                type='hidden'
                name='ac_status_url'
                value={url}
                disabled={true}
              />
              <div
                className={
                  chooseCardTypeIndex === index ? 'listItemChoose' : 'listItem'
                }
                onClick={() => {
                  handleChooseCardType(item)
                  handleChooseCardTypeIndex(index)
                  onSubmits()
                }}
              >
                <div className='listItemContainer'>
                  <div className='listItemTop'>
                    <div className='listItemLeft'>
                      <img
                        src={item === 'Visa' ? visa : visaMaster}
                        alt='visa'
                        className='listItemImg'
                      ></img>
                      <div className='listItemRight'>{item.toUpperCase()}</div>
                    </div>
                    <div className='listItemLeftContainer'>
                      {chooseCardTypeIndex === index ? (
                        <CheckboxItem checked={true} />
                      ) : (
                        <CheckboxItem checked={false} />
                      )}
                    </div>
                  </div>
                  {chooseCardTypeIndex === index && item === 'Visa/Master' ? (
                    <div className='listItemBottom'>
                      <div className='listItemBottomTips'>
                        {t('home.payment_process')}
                      </div>
                      {visaMasterData.map((item) => {
                        return (
                          <div
                            className='listItemBottomTipsContainer'
                            key={item.id}
                          >
                            <img src={item.img} alt='img' />
                            <div className='listItemBottomTipsText'>
                              {item.label}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </>
        )
      } else if (item === 'Crypto') {
        return null
      } else {
        return (
          <div
            key={item}
            className={
              chooseCardTypeIndex === index ? 'listItemChoose' : 'listItem'
            }
            onClick={() => {
              handleChooseCardType(item)
              handleChooseCardTypeIndex(index)
              onSubmits()
            }}
          >
            <div className='listItemLeft'>
              <img src={CoinPay[item]} alt={item} className={`listItemImg listItemImg${item}`} />
              <div className='listItemRight'>
                {item === 'Payway' ||
                item === 'Datacash' ||
                item === 'Airwallex Intent' ||
                item === 'Airwallex' ||
                item === 'Saebo' ||
                item === 'Checkout' ||
                item === 'Sathapana' ||
                item === 'Waafipay' ||
                item === 'Stanbicbank'
                  ? 'Visa/MasterCard'
                  : item === 'Unionintl'
                  ? 'MasterCard'
                  : item.toUpperCase()}
              </div>
            </div>
            <div className='listItemLeftContainer'>
              {chooseCardTypeIndex === index ? (
                <CheckboxItem checked={true} />
              ) : (
                <CheckboxItem checked={false} />
              )}
            </div>
          </div>
        )
      }
    })
  }

  const cancel = (id) => {
    let params = { id }
    cancelPayment(params)
      .then((response) => {
        let res = response.data
        if (res.code === 0) {
          window.location.href = `${url}?id=${getParams().id}&status=cancel`
        } else {
          message.error(res.msg)
        }
      })
      .catch((err) => {
        message.error(err)
      })
  }

  return (
    <>
      {loading ? null : (
        <>
          <Helmet>
            <title>{title}</title>
            <link rel='icon' href={logo} />
          </Helmet>
        </>
      )}
      <Spin spinning={loading}>
        <Header
          hasCancel={true}
          cancel={() => cancel(id)}
          logo={logo}
          title={title}
        />
        <div
          className={`${
            sessionStorage.getItem('embedded')
              ? 'currencyContainer-list-embedded'
              : ''
          } currencyContainer-list`}
        >
          {pageFrom === 'buyCrypto' ? (
            <img
              className='currencyCloseImg'
              src={closeImg}
              alt='img'
              onClick={() => props.history.go(-1)}
            />
          ) : null}
          <div className='canvasContainer'>
            <h2>{t('home.title')}</h2>
            <canvas id='canvas' width='80' height='80'></canvas>
          </div>
          <span className='tipsCard'>#{id}</span>
          <div className='bottomBorder' />
          <div className='currencyTop'>
            <div className='topLeft'>
              <div className='topLeftTextTitle'>{t('home.paying_to')}</div>
              <span className='topLeftText'>{merchant_name}</span>
            </div>
            <div className='topRight'>
              <span className='topRightAmount'>{amount}</span>
              {currency}
            </div>
          </div>
          <div className='currencyCenter'>
            <div className='listContainer-list'>
              {renderPayType()}
              {renderContainer()}
              <div className='btnContainer airBtn'>
                {!!chooseItem || chooseCard ? (
                  <Button
                    className='confirmBtnBottom airBtn'
                    loading={subLoading}
                    onClick={() => {
                      choose(chooseItem)
                    }}
                  >
                    {t('home.confirm')}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Spin>
      <form
        method='post'
        action='https://payway.ababank.com/api/pwtinhttinht/'
        ref={actionRefPayWay}
        target='_self'
      >
        <input type='hidden' name='hash' value={hash} />
        <input type='hidden' name='tran_id' value={tranId} />
        <input type='hidden' name='amount' value={payWayAmount} />
        <input type='hidden' name='items' value={items} />
        <input
          type='hidden'
          name='continue_success_url'
          id='continue_success_url'
          value={continueSuccessUrl}
        />
        <input
          type='hidden'
          name='firstname'
          className='input'
          value={firstName}
        />
        <input
          type='hidden'
          name='lastname'
          value={lastName}
          className='input'
        />
        <input type='hidden' name='phone' value={phone} className='input' />
        <input
          type='hidden'
          name='email'
          value={payWayEmail}
          className='input'
        />
        <input type='hidden' name='country' value={country} className='input' />
        <input type='hidden' name='state' value={state} className='input' />
        <input type='hidden' name='city' value={city} className='input' />
        <input type='hidden' name='address' value={address} className='input' />
        <input type='hidden' name='zipcode' value={zipcode} className='input' />
      </form>
      <form
        method='get'
        action='https://migs.mastercard.com.au/vpcpay'
        ref={actionRefDataCash}
        target='_self'
      >
        <input
          type='hidden'
          name='vpc_Version'
          value={vpc_version}
          className='input'
        />
        <input type='hidden' name='vpc_Command' value={vpc_command} />
        <input type='hidden' name='vpc_AccessCode' value={vpc_access_code} />
        <input type='hidden' name='vpc_MerchTxnRef' value={vpc_merch_txn_ref} />
        <input type='hidden' name='vpc_Merchant' value={vpc_merchant} />
        <input
          type='hidden'
          name='vpc_OrderInfo'
          value={vpc_order_info}
          className='input'
        />
        {vpc_return_url ? (
          <input
            type='hidden'
            name='vpc_ReturnURL'
            value={vpc_return_url}
            className='input'
          />
        ) : null}
        <input type='hidden' name='vpc_Amount' value={vpc_amount} />
        <input type='hidden' name='vpc_Locale' value={vpc_locale} />
        <input
          type='hidden'
          name='vpc_Currency'
          value={vpc_Currency}
          className='input'
        />
        {vpc_Gateway
          ? [
              <input
                type='hidden'
                name='vpc_Gateway'
                value={vpc_Gateway}
                className='input'
              />,
              <input
                type='hidden'
                name='vpc_Card'
                value={vpc_Card}
                className='input'
              />,
            ]
          : null}

        <input
          type='hidden'
          name='vpc_SecureHash'
          value={vpc_secure_hash}
          className='input'
        />
        <input
          type='hidden'
          name='vpc_SecureHashType'
          value={vpc_SecureHashType}
          className='input'
        />
        <input
          type='hidden'
          name='vpc_ReturnAuthResponseData'
          value={vpc_ReturnAuthResponseData}
          className='input'
        />
      </form>

      <form method='POST' action={waafipayUrl} ref={actionRefWaafipay}>
        <input
          type='hidden'
          name='hppRequestId'
          id='hppRequestId'
          value={waafipayRequestId}
        />
        <input
          type='hidden'
          name='referenceId'
          id='referenceId'
          value={waafipayReferenceId}
        />
      </form>
      <NewPaymentChannel formProps={paymentChannelTarget} ref={newPaymentChannelRef}/>
    </>
  )
}

export default withRouter(CurrencyList)
