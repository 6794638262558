import React, { useState, useEffect } from 'react'
import { Result } from 'antd-mobile'
import {
  ClockCircleTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import HeaderTop from '../Components/HeaderTop'
import { getKycInfo } from '../../../utils/api'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { reasonOptions } from '../../../utils/enum'
import './index.css'

const KycResult = (props) => {
  const { t } = useTranslation()
  const [reason, handleReason] = useState('')
  const [status, handleStatus] = useState('')
  const [drivingStatus, handleDrivingStatus] = useState('')
  const [level_three_status, handleLevelThreeStatus] = useState('')
  const [level_four_status, handleLevelFourStatus] = useState('')
  const [kycLevel, handleKycLevel] = useState(null)

  useEffect(() => {
    getKyc()
  }, [])

  const getKyc = () => {
    getKycInfo().then((res) => {
      if (res.code === 0) {
        handleStatus(res.result.status)
        handleDrivingStatus(res.result.driver_status)
        handleLevelThreeStatus(res.result.level_three_status)
        handleLevelFourStatus(res.result.level_four_status)
        handleKycLevel(res.result.kyc_level)
        if (res.result.reason) {
          reasonOptions.map((item) => {
            if (res.result.reason === item.value) {
              handleReason(item.en)
            }
          })
        }
      }
    })
  }

  const messageNode = () => {
    if (
      kycLevel === '0' ||
      (kycLevel === '1' && status === 0) ||
      (kycLevel === '2' && level_three_status === 0) ||
      (kycLevel === '3' && level_four_status === 0)
    ) {
      return (
        <div className='top'>
          <ClockCircleTwoTone
            style={{
              fontSize: '48px',
              color: '#0045ff',
              margin: '24px auto 48px',
            }}
          />
          <p>Your identity verification is under review.</p>
        </div>
      )
    } else if (
      kycLevel === '1' ||
      kycLevel === '2' ||
      kycLevel === '3' ||
      kycLevel === '4'
    ) {
      return (
        <div className='top'>
          <CheckCircleTwoTone
            style={{
              fontSize: '48px',
              color: '#0045ff',
              margin: '24px auto 48px',
            }}
          />
          <h4>Your identity is verified.</h4>
        </div>
      )
    } else if (
      (kycLevel === '0' && drivingStatus === 7) ||
      (kycLevel === '1' && status === 7) ||
      (kycLevel === '2' && level_three_status === 7) ||
      (kycLevel === '3' && level_four_status === 7)
    ) {
      return (
        <div className='top'>
          <CloseCircleTwoTone
            style={{
              fontSize: '48px',
              color: '#0045ff',
              margin: '24px auto 48px',
            }}
          />
          <h4>Sorry, your application was rejected.</h4>
          <p>Reason: {reason}</p>
        </div>
      )
    }
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'kycResultContainer-embedded'
            : ''
        } kycResultContainer`}
      >
        <div className='kycResultTop'>
          <HeaderTop title='Identification' />
        </div>
        <Result
          title=''
          message={messageNode()}
          buttonText={
            status === 7 || drivingStatus === 7 || level_three_status === 7
              ? 'Reapply'
              : null
          }
          buttonType='primary'
          onButtonClick={() => {
            props.history.push(`../buyCrypto/kyc?kyc_level=${kycLevel}`)
          }}
        />
      </div>
      <Footer />
    </>
  )
}

export default withRouter(KycResult)
