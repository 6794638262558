import React, { useState, useEffect } from 'react'
import { Button, Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import { createBankTransfer } from '../../../../utils/api'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import HeaderTop from '../../Components/HeaderTop'
import Jinggao from '../../../../assets/images/jinggao.svg'
import './index.css'

const BankDeposit = (props) => {
  const [loading, handleLoading] = useState(false)
  const [amount, handleAmount] = useState('')
  const [fromCurrency, handleFromCurrency] = useState('')
  const [bankName, handleBankName] = useState('')
  const [address, handleAddress] = useState('')

  const [tx_id, handleTxId] = useState('')
  const [bankTransferNote, handleBankTransferNote] = useState({})
  const [postscript, handlePostscript] = useState('')

  useEffect(() => {
    handleLoading(true)
    handleAmount(getParams()?.amount)
    handleFromCurrency(getParams()?.from_currency)
    handleBankName(getParams()?.bankName)
    handleAddress(getParams()?.address)
    const authorization = localStorage.getItem('authorization')
    const email = localStorage.getItem('inst_payone_pay_mail')
    let params = {
      cust_order_id: Date.now(),
      email,
      currency: getParams()?.from_currency,
      amount: getParams()?.amount,
      method: getParams()?.bankName,
    }
    createBankTransfer(params, authorization)
      .then((res) => {
        handleLoading(false)
        if (res.code === 0) {
          handleTxId(res.result?.tx_id || '-')
          handleBankTransferNote(res.result?.bank || {})
          handlePostscript(res.result?.postscript || '-')
        }
      })
      .catch(() => {
        handleLoading(false)
      })
  }, [])

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  const viewHistory = () => {
    props.history.push('/buyCrypto/wallet?activeKey=history')
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'creditDepositContainer-embedded'
            : ''
        } creditDepositContainer`}
      >
        <HeaderTop
          title={`Funding Your Account with Bank Transfer (${bankName})`}
        />
        <Spin spinning={loading}>
          <div className='deposit_credit_container'>
            <div className='tips_container tips_container_tips'>
              <div className='title_tips'>{bankName} Transfer Notice:</div>
              {bankName === 'SEPA' ? (
                <div>
                  <div className='tips'>
                    · Funds must be transferred from bank accounts under your
                    name.
                  </div>
                  <div className='tips'>
                    · Only EUR Transfers via SEPA / SEPA Instant are accepted
                    for this account (transfers via SWIFT will not be accepted).
                  </div>
                  <div className='tips'>
                    · SEPA Transfers normally arrive within 0-2 working days.
                  </div>
                </div>
              ) : (
                <div className='tips'>
                  · Individual users can only deposit USD for now.
                </div>
              )}
            </div>
            {bankName === 'SEPA' ? (
              <div>
                <div className='info_title'>Order information</div>
                <div className='deposit_bank_msg_container'>
                  <div className='deposit_bank_msg_container_left'>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Order ID</div>
                      <div className='line_right'>{tx_id}</div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Wallet ID</div>
                      <div className='line_right'>{address}</div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Amount</div>
                      <div className='line_right'>
                        {amount} {fromCurrency}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='attention_tips'>
                  Please transfer the fund within 4 hours. Send the order
                  reference code with the transaction so we can know it’s from
                  you.
                </div>
                <div className='info_title'>Bank information</div>
                <div className='deposit_bank_msg_container'>
                  <div className='deposit_bank_msg_container_left'>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Account Name</div>
                      <div className='line_right'>
                        {bankTransferNote?.account_name}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>IBAN</div>
                      <div className='line_right'>
                        {bankTransferNote?.account_id}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Bank Name</div>
                      <div className='line_right'>
                        {bankTransferNote?.bank_name}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>BIC(SWIFT Code)</div>
                      <div className='line_right'>{bankTransferNote?.bic}</div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Bank Address</div>
                      <div className='line_right'>
                        {bankTransferNote?.bank_address}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>
                        <div>Reference Code</div>
                      </div>
                      <div className='line_right'>{postscript}</div>
                    </div>
                    <div className='rateTipsLeft'>
                      <img src={Jinggao} alt='jinggao' />
                      <span className='rateTipsTitle rateTipsTitleLeft'>
                        Reference code MUST be written correctly to accept your
                        deposit.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className='attention_tips'>
                  To initiate a ACH Transfer , sign in to your online bank
                  account, locate the transfer menu, and enter the information
                  below.
                </div>
                <div className='deposit_bank_msg_container'>
                  <div className='deposit_bank_msg_container_left'>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Account Name</div>
                      <div className='line_right'>
                        {bankTransferNote?.account_name}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Bank Account Number</div>
                      <div className='line_right'>
                        {bankTransferNote?.account_num}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Bank Name</div>
                      <div className='line_right'>
                        {bankTransferNote?.bank_name}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>BIC(SWIFT Code)</div>
                      <div className='line_right'>{bankTransferNote?.bic}</div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>Bank Address</div>
                      <div className='line_right'>
                        {bankTransferNote?.bank_address}
                      </div>
                    </div>
                    <div className='deposit_bank_msg_container_line'>
                      <div className='line_left'>
                        <div>Bank Code</div>
                      </div>
                      <div className='line_right'>
                        {bankTransferNote?.account_desc}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='attention_tips'>
                  ACH Verification - some banks may ask you to send 2 test
                  transfers, and tell them what the exact amounts are (e.g:
                  $0.02 and $0.54) You'll need to use these numbers to verify
                  the ACH setting at your banking system
                </div>
                <div className='rateTipsLeft'>
                  <img src={Jinggao} alt='jinggao' />
                  <span className='rateTipsTitle rateTipsTitleLeft'>
                    Please Email support@inst.money and ask for the exact
                    amounts, if you didn't receive an Email notification.
                  </span>
                </div>
              </div>
            )}
            <div className='confirm_container'>
              <Button
                type='primary'
                className='confirm-btn'
                onClick={viewHistory}
              >
                View History
              </Button>
            </div>
          </div>
        </Spin>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(BankDeposit)
