import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import HeaderTop from '../../Components/HeaderTop'
import { getWalletHistory } from '../../../../utils/api'
import copyImg from '../../../../assets/images/copy.png'
import './index.css'
import SepaModal from '../../../Components/SepaModal/index'

const HistoryDetails = (props) => {
  const [details, handleDetails] = useState({})
  const [sepaModalVisible, setSepaModalVisible] = useState(false);


  useEffect(() => {
    getHistory()
  }, [])

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') === 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  const getHistory = () => {
    const authorization = localStorage.getItem('authorization')
    let params = {
      tx_id: getParams().tx_id,
    }
    getWalletHistory(params, authorization).then((res) => {
      if (res.code === 0) {
        handleDetails(res.result?.records[0] || {})
      }
    })
  }

  const renderPaymentStatus = (status) => {
    let returnText = '-'
    switch (status) {
      case 0:
        returnText = 'Pending'
        break
      case 1:
        returnText = 'Succeed'
        break
      case 2:
        returnText = 'Confirming'
        break
      case 3:
        returnText = 'Abnormal'
        break
      case 4:
        returnText = 'Failed'
        break
      case 5:
        returnText = 'Cancelled'
        break
      case 6:
        returnText = 'Expire'
        break
      case 7:
        returnText = 'Refund in progress'
        break
      case 8:
        returnText = 'Refund success'
        break
      case 9:
        returnText = 'Refund Failed'
        break
      default:
        break;
    }
    return returnText
  }

  const renderTransferStatus = (status) => {
    let returnText = '-'
    switch (status) {
      case 0:
        returnText = 'Pending'
        break
      case 1:
        returnText = 'Succeed'
        break
      case 2:
        returnText = 'Failed'
        break
      case 3:
        returnText = 'Submitted'
        break
      case 4:
        returnText = 'Pending'
        break
      case 5:
        returnText = 'Cancelled'
        break
      case 6:
        returnText = 'Pending'
        break
      default:
        break;
    }
    return returnText
  }

  const goResultPage = (status, order_no) => {
    let url = ''
    switch (status) {
      case 0:
        url = `../result-payment-inProgress/?id=${order_no}`
        break
      case 1:
        url = `../result-success/?id=${order_no}`
        break
      case 2:
        url = `../result-payment-confirming/?id=${order_no}`
        break
      case 3:
        url = `../result-payment-error/?id=${order_no}`
        break
      case 4:
        url = `../result-fail/?id=${order_no}`
        break
      case 5:
        url = `../result-cancel/?id=${order_no}`
        break
      case 6:
        url = `../result-timeout/?id=${order_no}`
        break
      case 7:
        url = `../result-refund-inProgress/?id=${order_no}`
        break
      case 8:
        url = `../result-refund-success/?id=${order_no}`
        break
      case 9:
        url = `../result-refund-fail/?id=${order_no}`
        break
      default:
        break;
    }
    props.history.push(url)
  }

  const renderDetails = () => {


    
    return (
      <div>
        <div className='detailsList'>
          <div className='title'>Order No</div>
          <div
            className='tips tipsLink'
            onClick={() => {
              if (details.tx_type === '01') {
                goResultPage(details[0].status, details[0].order_no)
              }
              else if(details.tx_type === '02'){
                setSepaModalVisible(true);
              }
            }}
          >
            {details.order_no || '-'}
          </div>
        </div>
        <div className='detailsList'>
          <div className='title'> Amount</div>
          <div className='tips'>
            {details?.amount} {details?.currency}
          </div>
        </div>
        <div className='detailsList'>
          <div className='title'>Payment status</div>
          <div className='tips'>{renderPaymentStatus(details?.status)}</div>
        </div>
        {details.details ? (
          <>
            <div className='detailsList'>
              <div className='title'> Purchase</div>
              <div className='tips'>
                {details?.details?.amount} {details?.details?.coin_type}
              </div>
            </div>
            <div className='detailsList'>
              <div className='title'>Address</div>
              <div className='addressCopy'>
                <div className='tips'>
                  {details?.details?.address.substr(0, 8)}...
                  {details?.details?.address.substr(
                    details?.details?.address.length - 8,
                    8
                  )}
                </div>
                <CopyToClipboard
                  text={details?.details?.address}
                  onCopy={(value, a) => {
                    message.success('Copied')
                  }}
                >
                  <img className='copyImage' src={copyImg} />
                </CopyToClipboard>
              </div>
            </div>
            <div className='detailsList'>
              <div className='title'>Txn Hash</div>
              <div className='tips'>{details?.details?.tx_hash}</div>
            </div>
            <div className='detailsList'>
              <div className='title'>Transfer Status</div>
              <div className='tips'>
                {renderTransferStatus(details?.details?.status)}
              </div>
            </div>
          </>
        ) : null}
        <div className='detailsList'>
          <div className='title'>Wallet Txn ID</div>
          <div className='tips'>{details.external_tx_id || '-'}</div>
        </div>
        <div className='detailsList'>
          <div className='title'>Amount</div>
          <div className='tips'>
            {details?.amount} {details?.currency}
          </div>
        </div>
        <div className='detailsList'>
          <div className='title'>Balance</div>
          <div className='tips'>
            {details.balance || '-'} {details.currency}
          </div>
        </div>
        <div className='detailsList'>
          <div className='title'>create_time</div>
          <div className='tips'>
            {moment(details.create_time).format('YYYY/MM/DD HH:mm')}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'walletHistoryDetailsContainer-embedded'
            : ''
        } walletHistoryDetailsContainer`}
      >
        <HeaderTop title='Your transaction' />
        {renderDetails()}
        <div className='bottomTips'>
          If You have any questions,we are here to help.
        </div>
      </div>
      <SepaModal  
        visible={sepaModalVisible}
        speaData={details}
        
        cancel={() => {
            setSepaModalVisible(false);
          }}/>
      <Footer />
    </>
  )
}

export default withRouter(HistoryDetails)
