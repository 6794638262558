import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { getBindCardList } from '../../../utils/api'
import { bindCardLength } from '../../../utils/enum'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import masterCard from '../../../assets/images/masterCard1.svg'
import editImg from '../../../assets/images/editImg.svg'
import noDataIcon from '../../../assets/images/noDataIcon.svg'
import './index.css'

const CardManagement = (props) => {
  const [cardList, handleCardList] = useState([])
  const [cardListLoading, handleCardListLoading] = useState(false)

  useEffect(() => {
    handleCardListLoading(true)
    getBindCardList()
      .then((res) => {
        handleCardListLoading(false)
        if (res.code === 0) {
          handleCardList(res.result)
        }
      })
      .catch(() => {
        handleCardListLoading(false)
      })
  }, [])

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'cardManagementContainer-embedded'
            : ''
        } cardManagementContainer`}
      >
        <HeaderTop title='Payment Method' />
        <div className='titleTips'>
          Complete your card information for a higher purchase limit.
        </div>
        <Spin spinning={cardListLoading}>
          <div className='cardListContainer'>
            {cardList.length > 0 ? (
              cardList.map((item) => {
                return (
                  <div className='cardContainer' key={item.id}>
                    <div className='cardTop'>
                      <div className='cardTopContainerLeft'>
                        <img src={masterCard} />
                        MasterCard
                      </div>
                      <div className='cardTopContainerRight'>
                        {item.status !== 3 ? (
                          <img
                            src={editImg}
                            onClick={() => {
                              if (item.status === 4) {
                                props.history.push(
                                  `/buyCrypto/bindCardResult?id=${item.id}`
                                )
                              } else {
                                props.history.push(
                                  `/buyCrypto/addBindCard?id=${item.id}`
                                )
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className='cardBottom'>
                      <div className='cardBottomNumber'>****</div>
                      <div className='cardBottomNumber'>****</div>
                      <div className='cardBottomNumber'>****</div>
                      <div className='cardBottomNumber'>
                        {item?.card_number?.substring(
                          item?.card_number?.length - 4,
                          item?.card_number?.length
                        )}
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className='cardListNoContainer'>
                <img src={noDataIcon} alt='no card' className='noCardImg' />
                <div className='noCardText'>No Card</div>
              </div>
            )}
          </div>
        </Spin>
        {cardList.length >= bindCardLength ? (
          <div className='addContainer'>
            <Button
              type='primary'
              className='goToBtn'
              onClick={() => {
                props.history.push(`/buyCrypto/addBindCard`)
              }}
            >
              Add new card
            </Button>
          </div>
        ) : null}
      </div>
      <Footer />
    </>
  )
}

export default withRouter(CardManagement)
