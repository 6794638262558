import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";
import "./index.css";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import {Base64} from 'js-base64'
const ThreeDS = (props) => {
  const [logo, handleLogo] = useState("");
  const [title, handleTitle] = useState("");
  const ref = useRef();
  const getParams = () => {
    var params = {};
    if (
      props.location.search.indexOf("?") == 0 &&
      props.location.search.indexOf("=") > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split("&");
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split("=")[0]] = currentValue.split("=")[1];
        });
      }
    }
    return params;
  };



  useEffect(() => {
    if (getParams().acs_url && getParams().creq) {
      var temp_form = document.createElement("form");
      temp_form.action = window.atob(getParams().acs_url);
      //如需打开新窗口，form的target属性要设置为'_blank'
      temp_form.target = "_self";
      temp_form.method = "post";
      temp_form.style.display = "none";
      //添加参数
      var opt = document.createElement("textarea");
      opt.name = "creq";
      opt.value = getParams().creq;
      temp_form.appendChild(opt);
      document.body.appendChild(temp_form);
      //提交数据
      temp_form.submit();
    } else if (getParams().creq) {
    //此时creq为一个包含script标签和form标签的表单
      //html><head></head><body>
      //<form name='creqForm' method='POST' action='xxx'>
      //<input type='hidden' name='creq' value='xxx'>
      //<input type='hidden' name='threeDSSessionData' value='xxx'>
      //<noscript><button>Please click here to continue</button></noscript>
      //</form>
      //<script type='text/javascript'>document.creqForm.submit();</script></body></html> 
      console.log(getParams().creq)
      // const htmlRaw = window.atob(getParams().creq);
      const htmlRaw = Base64.decode(getParams().creq)
      console.log(htmlRaw)
      const node = document.createRange().createContextualFragment(htmlRaw);
      ref.current.appendChild(node);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem("embedded") ? "threeDS_container-embedded" : ""
        } threeDS_container`}
      >
        <Spin spinning={true}></Spin>
      </div>

      <div ref={ref} id="creqHtmlForm"></div>
    </>
  );
};

export default withRouter(ThreeDS);
