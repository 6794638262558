function addParamToUrl(url, param) {
  const parsedUrl = new URL(url);
  const params = new URLSearchParams(parsedUrl.search);

  params.append(param.key, param.value);
  parsedUrl.search = params.toString();

  return parsedUrl.toString();
}

export const handleReturnUrl = (url, params) => {
  if(!url){
    return false
  }
  let finalUrl = url;
  for (let key in params) {
    finalUrl = addParamToUrl(finalUrl, {
      key,
      value: params[key],
    });
  }
  return finalUrl;
};
