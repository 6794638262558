import BitCoin from '../assets/images/BitCoin.png'
import USDT from '../assets/images/USD.png'
import ETH from '../assets/images/ETH.svg'
import BUSD from '../assets/images/BUSD.png'
import DAI from '../assets/images/DAI.png'

// import visaMaster from '../assets/images/visaNew.svg'
import visaMaster from '../assets/images/visa.png'
import uniMaster from '../assets/images/uniMaster.svg'
import visa from '../assets/images/visa.svg'
import sepa from '../assets/images/sepa.png'
import SepaLogoEN from '../assets/images/SepaLogoEN.jpg'
import masterCard from '../assets/images/masterCard1.svg'
import yunPay from '../assets/images/yunPay.svg'
import UnionPay from '../assets/images/UnionPay.svg'
import Inst from '../assets/images/instTitleLogo.png'

import AUD from '../assets/images/AUD.svg'
import BGN from '../assets/images/BGN.svg'
import BRL from '../assets/images/BRL.svg'
import CAD from '../assets/images/CAD.svg'
import CHF from '../assets/images/CHF.svg'
import CNY from '../assets/images/CNY.svg'
import COP from '../assets/images/COP.svg'
import CZK from '../assets/images/CZK.svg'
import DKK from '../assets/images/DKK.svg'
import DOP from '../assets/images/DOP.svg'
import EGP from '../assets/images/EGP.svg'
import EUR from '../assets/images/EUR.svg'
import GBP from '../assets/images/GBP.svg'
import GHS from '../assets/images/GHS.png'
import HKD from '../assets/images/HKD.svg'
import HRK from '../assets/images/HRK.svg'
import IDR from '../assets/images/IDR.svg'
import ILS from '../assets/images/ILS.svg'
import JOD from '../assets/images/JOD.svg'
import JPY from '../assets/images/JPY.svg'
import KES from '../assets/images/KES.svg'
import KRW from '../assets/images/KRW.svg'
import KWD from '../assets/images/KWD.svg'
import KZT from '../assets/images/KZT.png'
import LKR from '../assets/images/LKR.svg'
import MAD from '../assets/images/MAD.svg'
import MXN from '../assets/images/MXN.svg'
import MYR from '../assets/images/MYR.svg'
import NGN from '../assets/images/NGN.svg'
import NOK from '../assets/images/NOK.svg'
import NZD from '../assets/images/NZD.svg'
import OMR from '../assets/images/OMR.svg'
import PEN from '../assets/images/PEN.svg'
import PKR from '../assets/images/PKR.svg'
import PLN from '../assets/images/PLN.svg'
import RON from '../assets/images/RON.svg'
import RUB from '../assets/images/RUB.svg'
import SEK from '../assets/images/SEK.svg'
import SGD from '../assets/images/SGD.svg'
import THB from '../assets/images/THB.svg'
import TRY from '../assets/images/TRY.svg'
import TWD from '../assets/images/TWD.svg'
import TZS from '../assets/images/TZS.png'
import UAH from '../assets/images/UAH.svg'
import UGX from '../assets/images/UGX.png'
import USD from '../assets/images/USD.svg'
import VND from '../assets/images/VND.svg'
import ZAR from '../assets/images/ZAR.svg'

export const CoinJson = {
  BTC: BitCoin,
  USD: USDT,
  USDT: USDT,
  'USDT-TRC20': USDT,
  ETH: ETH,
  BUSD: BUSD,
  'BUSD-BEP20': BUSD,
  'USDT-BEP20': USD,
  DAI: DAI,
  CAD: CAD,

}

export const FiatJson = {
  AUD,
  BGN,
  BRL,
  CAD,
  CHF,
  CNY,
  COP,
  CZK,
  DKK,
  DOP,
  EGP,
  EUR,
  GBP,
  GHS,
  HKD,
  HRK,
  IDR,
  ILS,
  JOD,
  JPY,
  KES,
  KRW,
  KWD,
  KZT,
  LKR,
  MAD,
  MXN,
  MYR,
  NGN,
  NOK,
  NZD,
  OMR,
  PEN,
  PKR,
  PLN,
  RON,
  RUB,
  SEK,
  SGD,
  THB,
  TRY,
  TWD,
  TZS,
  UAH,
  UGX,
  USD,
  VND,
  ZAR,
}

export const CoinTips = {
  BTC: 'Bitcoin blockchain',
  EUR: 'Ethereum blockchain',
  USD: 'Ethereum blockchain (ERC20)',
  USDT: 'Ethereum blockchain (ERC20)',
  ETH: 'Ethereum blockchain',
  BUSD: 'Ethereum blockchain (BEP20)',
  'BUSD-BEP20': 'Binance Smart Chain (BEP20)',
  DAI: 'Ethereum blockchain (ERC20)',
}

export const CoinPay = {
  'visa/Master': visaMaster,
  Visa: visa,
  Master: masterCard,
  'Quick Pass': yunPay,
  Unionpay: UnionPay,
  'Crypto Gateway': Inst,
  Payway: visaMaster,
  Datacash: visaMaster,
  Saebo: visaMaster,
  Airwallex: visaMaster,
  'Airwallex Intent': visaMaster,
  Checkout: visaMaster,
  Unionintl: masterCard,
  Sathapana: visaMaster,
  Waafipay: visaMaster,
  Stanbicbank: visaMaster,
  'SEPA': SepaLogoEN,
  // 'SEPA': sepa,
}

