import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Result } from 'antd-mobile'
import { message, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCheckout } from '../../utils/api'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Success from '../../assets/images/Success.png'
import TipsIconNew from '../../assets/images/tipsIconNew.svg'
import './index.css'
import { handleReturnUrl } from '../../utils/handle'


const ResultSuccess = (props) => {
  const { t } = useTranslation()
  const GotItLabel = t('Result.commonTips2')
  const [url, handleUrl] = useState('')
  const [id, handleId] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')
  const [btnTitle, handleBtnTitle] = useState('')
  const [defaultTime, handleDefaultTime] = useState(5)
  const [btnDisabled, handleDtnDisabled] = useState(false)

  useEffect(() => {
    const paramsId = props.location.search.split('id=')[1]
    if (paramsId) {
      handleId(paramsId)
      queryOrder(paramsId)
    }
  }, [])

  useEffect(() => {
    const paramsId = props.location.search.split('id=')[1]
    // if (paramsId && paramsId[0] === 'b') {
    if (paramsId ) {
      handleDtnDisabled(true)
      setTimeout(CountDown, 1000)
    } else {
      handleBtnTitle(GotItLabel)
    }
  }, [defaultTime])

  const toNextPage = () =>{
    console.log('to next', url)
    const nextUrl = handleReturnUrl(`${url}`, {
      status: 1,
      id,
    });
    if(nextUrl){
      window.location.href = nextUrl
    }
  }

  const queryOrder = (id) => {
    let params = { id }
    getCheckout(params)
      .then((res) => {
        handleUrl(res.data.result.return_url)
        handleLogo(res.data.result.logo)
        handleTitle(res.data.result.title)
      })
      .catch((err) => {
        message.error(err.msg)
      })
  }

  const CountDown = () => {
    if (defaultTime > 1) {
      handleDefaultTime(defaultTime - 1)
      handleBtnTitle(`${GotItLabel} ( ${defaultTime - 1} )`)
    } else {
      handleDtnDisabled(false)
      handleBtnTitle(GotItLabel)
      toNextPage()
    }
  }

  const messageNode = () => {
    return (
      <>
        <div className='topMessage'>
          {id[0] === 'b' ? (
            <div className='tipsContainer'>
              <div className='attention'>
                <img src={TipsIconNew} />
                <div className='title'>Attention</div>
              </div>
              <div className='tips'> 1. {t('Result.tips21')}</div>
              <div className='tips'>
                2. {t('Result.tips22')} "{id} Not Receive"
              </div>
            </div>
          ) : (
            <div className='tipsContainerNew'>{t('Result.tips16')}</div>
          )}
        </div>
        <Button
          type='primary'
          className='goToBtn'
          disabled={btnDisabled}
          onClick={() => {
            toNextPage()
          }}
        >
          {btnTitle}
        </Button>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'result-example-embedded' : ''
        } result-example`}
      >
        <Result
          img={<img src={Success} alt='img' />}
          title={t('Result.tips17')}
          message={messageNode()}
        />
      </div>
      <Footer />
    </>
  )
}

export default withRouter(ResultSuccess)
