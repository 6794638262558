import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Pagination, Spin } from 'antd'
import moment from 'moment'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { getFastBuyHistory } from '../../../utils/api'
import ListImg from '../../../assets/images/list.svg'
import SuccessImg from '../../../assets/images/successImg.svg'
import PendingImg from '../../../assets/images/pendingImg.svg'
import ErrorImg from '../../../assets/images/errorImg.svg'
import ExpireImg from '../../../assets/images/expireImg.svg'
import TipsIcon from '../../../assets/images/tipsIcon.svg'
import './index.css'

const FastBuyHistory = (props) => {
  const [listTab, handleListTab] = useState([])
  const [total, handleTotal] = useState(0)
  const [loading, handleLoading] = useState(false)
  const [current, handleCurrent] = useState(1)
  const [page_num, handlePageNum] = useState(0)
  const [page_size, handlePageSize] = useState(10)

  useEffect(() => {
    let params = {
      page_num: page_num,
      page_size,
    }
    getHistory(params)
  }, [])

  const getHistory = (params) => {
    handleLoading(true)
    getFastBuyHistory(params)
      .then((res) => {
        handleLoading(false)
        if (res.code === 0) {
          handleListTab(res.result?.records || [])
          handleTotal(res.result?.total)
        }
      })
      .catch(() => {
        handleLoading(false)
      })
  }

  const pageChange = (page) => {
    handleCurrent(page)
    let params = {
      page_num: page - 1,
      page_size,
    }
    getHistory(params)
  }

  const renderStatusImg = (status) => {
    if (status === 1) {
      return <img src={SuccessImg} />
    } else if (status === 3) {
      return <img src={ErrorImg} />
    } else if (status === 4) {
      return <img src={ErrorImg} />
    } else if (status === 5) {
      return <img src={ErrorImg} />
    } else if (status === 6) {
      return <img src={ExpireImg} />
    } else if (status === 8) {
      return <img src={SuccessImg} />
    } else if (status === 9) {
      return <img src={ErrorImg} />
    } else {
      return <img src={PendingImg} />
    }
  }
  const renderList = () => {
    return listTab.map((item) => {
      return (
        <div
          className='listContainer'
          key={item.id}
          onClick={() => {
            if(item.purchase_type==="2"){
              props.history.push(
                `/buyCrypto/historyDetails?id=${item.order_no}&type=2`
              )
              return 
            }
            props.history.push(
              `/buyCrypto/historyDetails?id=${item.cust_order.order_no}&type=0`
            )
          

          }}
        >
          <div className='listLeft'>
            {renderStatusImg(item.cust_order?.settle_status)}
            <div className='listLeftTitle'>
              <div className='listLeftTitleTop'>Buy {item.coin_type}</div>
              <div className='listLeftTitleBottom'>
                {moment(item.create_time).format('YYYY/MM/DD HH:mm')}
              </div>
            </div>
          </div>
          <div className='listRight'>
            {item.cust_order?.amount} {item.cust_order?.currency}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'historyContainer-embedded' : ''
        } historyContainer`}
      >
        <div className='titleContainer'>
          <div className='titleContainerLeft'>
            <span className='title'>Trade History</span>
          </div>
          <div className='titleContainerRight'>
            <img
              src={ListImg}
              alt='list'
              onClick={() => {
                props.history.push('/buyCrypto/setting')
              }}
            />
          </div>
        </div>
        <div className='tipsAnimeContainer'>
          <img src={TipsIcon} alt='tips' />
          <div className='tipsText'>
            ATTENTION! We handles KYC and orders Monday-Friday 10:00-18:00
            GMT+08:00
          </div>
        </div>
        <Spin spinning={loading}>
          <div className='listContent'>{renderList()}</div>
        </Spin>
        <div className='pageSty'>
          <Pagination
            simple
            current={current}
            total={total}
            onChange={pageChange}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(FastBuyHistory)
