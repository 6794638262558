import React, { useState, useEffect } from "react";
import BigNumber from 'bignumber.js'
import {
  Spin,
  message,
  Button,
  Radio,
  List,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Checkbox,
} from "antd";
import { withRouter } from "react-router-dom";
import {
  fastBuyCurrency,
  fastBuyCurrencyWithSepa,
  getKycInfo,
  getBindCardList,
  userWallet,
  getGaStatus,
  sendEmail,
  getWalletRate,
} from "../../../utils/api";
import { payDefaultUrl } from "../../../utils/config";
import { bindCardLength } from "../../../utils/enum";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import HeaderTop from "../Components/HeaderTop";
import Jinggao from "../../../assets/images/jinggao.svg";
import NEO from "../../../assets/images/NEO.png";
import BNEO from "../../../assets/images/BNEO.png";
import USDT from "../../../assets/images/USDT.png";
import GAS from "../../../assets/images/gasImg.svg";
import FLM from "../../../assets/images/FLM.png";
import RightIcon from "../../../assets/images/rightIcon.svg";
import USD from "../../../assets/images/USDNew.svg";
import EUR from "../../../assets/images/EUR.svg";
import GBP from "../../../assets/images/GBP.svg";
import masterCard from "../../../assets/images/masterCard1.svg";
import walletCircle from "../../../assets/images/walletCircle.svg";
import sepaLogo from "../../../assets/images/sepa.png";

import TipsIconNew from "../../../assets/images/tipsIconNew.svg";
import RightIconBP from "../../../assets/images/rightIconB.svg";
import "./index.css";

import { websiteConditionUrl } from "../../../utils/config";
import SepaModal from "../../Components/SepaModal/index";

const ChooseBindCard = (props) => {
  const [channel_name, handleChannelName] = useState("");
  const [amount, handleAmount] = useState("");
  const [approximatelyAmount, handleApproximatelyAmount] = useState("");
  const [fromCurrency, handleFromCurrency] = useState("USD");
  const [toCurrency, handleToCurrency] = useState("");
  const [rate, handleRate] = useState("");
  const [walletRate, handleWalletRate] = useState("");
  const [sepaRate, handleSepaRate] = useState("");

  const [walletAddress, handleWalletAddress] = useState("");
  const [payDisabled, handlePayDisabled] = useState(false);
  const [returnUrl, handleReturnUrl] = useState("");
  const [custOrderId, handleCustOrderId] = useState("");
  const [cardList, handleCardList] = useState([]);
  const [cardNumber, handleCardNumber] = useState("");
  const [cardListLoading, handleCardListLoading] = useState(false);
  const [walletList, handleWalletList] = useState([]);

  const [form] = Form.useForm();
  const [sendEmailLoading, handleSendEmailLoading] = useState(false);
  const [sendEmailDisabled, handleSendEmailDisabled] = useState(false);
  const [sendEmailText, handleSendEmailText] = useState("Send");
  const [defaultTime, handleDefaultTime] = useState(0);
  const [gaStatus, handleGaStatus] = useState(false);
  const [walletPaymentVisible, handleWalletPaymentVisible] = useState(false);
  const [mailCodeToken, handleMailCodeToken] = useState("");
  const [checked, handleChecked] = useState(false);
  const [showCheckedTips, handleShowCheckedTips] = useState(false);
  const [buttonText, handleButtonText] = useState("Payment");
  const [sepaOnly, setSepaOnly] = useState(sessionStorage.getItem("sepa"));
  const [sepaModalVisible, setSepaModalVisible] = useState(false);

  const [speaData, setSpeaData] = useState({ bank: {} });

  console.log(localStorage.getItem("payment_methods"))
  const [paymentMethods, setPaymentMethods] = useState([])

  const getParams = () => {
    var params = {};
    if (
      props.location.search.indexOf("?") === 0 &&
      props.location.search.indexOf("=") > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split("&");
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split("=")[0]] = currentValue.split("=")[1];
        });
      }
    }
    return params;
  };

  useEffect(() => {
    if (defaultTime > 0) {
      setTimeout(CountDown, 1000);
    }
  }, [defaultTime]);

  useEffect(() => {
    if(localStorage.getItem("payment_methods")){

      try{
        let payment_methods = JSON.parse(localStorage.getItem("payment_methods"))
        setPaymentMethods(payment_methods)
  
      }
      catch{
  
      }
    }
    if (getParams().from_currency) {
      handleFromCurrency(getParams().from_currency);
    }
    if (getParams().to_crypto) {
      handleToCurrency(getParams().to_crypto);
    }
    if (getParams().amount) {
      handleAmount(getParams().amount);
    }
    if (getParams().approximatelyAmount) {
      handleApproximatelyAmount(getParams().approximatelyAmount);
    }
    if (getParams().channel_name) {
      handleChannelName(getParams().channel_name);
    }
    if (getParams().rate) {
      handleRate(getParams().rate);
    }
    if (getParams().walletAddress) {
      handleWalletAddress(getParams().walletAddress);
    }
    if (getParams().return_url) {
      handleReturnUrl(getParams().return_url);
    }
    if (getParams().custOrderId) {
      handleCustOrderId(getParams().custOrderId);
    }
  }, []);

  useEffect(() => {
    handleCardListLoading(true);
    getBindCardList()
      .then((res) => {
        handleCardListLoading(false);
        if (res.code === 0) {
          handleCardList(res.result);
        }
      })
      .catch(() => {
        handleCardListLoading(false);
      });
  }, []);

  useEffect(() => {
    const authorization = localStorage.getItem("authorization");
    userWallet(authorization).then((res) => {
      if (res.code === 0) {
        let list = [];
        res.result.wallets?.map((item) => {
          if (item.currency === getParams().from_currency) {
            list.push(item);
          }
        });
        handleWalletList(list);
      }
    });
  }, []);

  useEffect(() => {
    const email = localStorage.getItem("inst_payone_pay_mail");
    getGaStatus(email).then((res) => {
      if (res.code === 0) {
        handleGaStatus(res.result.ga_enable);
      }
    });
  }, []);

  useEffect(() => {
    const params = {
      from_currency: getParams().from_currency,
      to_crypto: getParams().to_crypto,
    };
    getWalletRate(params).then((response) => {
      let res = response.data;
      if (res.code === 0) {
        handleWalletRate(res.result.wallet_rate);
        handleSepaRate(res.result.sepa_rate);
      }
    });
  }, []);

  const CountDown = () => {
    if (defaultTime > 1) {
      handleDefaultTime(defaultTime - 1);
      handleSendEmailText(defaultTime - 1);
    } else {
      handleSendEmailDisabled(false);
      handleSendEmailText("Send");
    }
  };

  const payNewMethod = () => {
    if (cardList.length < bindCardLength) {
      if (!checked) {
        handleShowCheckedTips(true);
        return;
      } else {
        submitToOrder();
      }
    } else {
      props.history.push(`/buyCrypto/addBindCard`);
    }
  };

  const tryPayWithSepa = () => {

    const param = {
      address: walletAddress,
      pay_with: "sepa",
      from_currency: fromCurrency,
      to_crypto: toCurrency,
      amount: amount,
      rate: sepaRate,
      authorization: localStorage.getItem("authorization"),
    };
    if(custOrderId){
      param.cust_order_id = custOrderId
    }
    handlePayDisabled(true);

    // return ;
    fastBuyCurrencyWithSepa(param)
    
      .then((res) => {
        handlePayDisabled(false);

        if (res.code === 0) {
          setSpeaData(res.result);
          setSepaModalVisible(true);
        }
      })
      .catch((err) => {
        handlePayDisabled(false);
      });
  };

  const submitGetOrder = () => {

    if (!checked) {
      handleShowCheckedTips(true);
      return;
    } else if (cardList.length > 0 && !cardNumber) {
      message.error("Please select your card");
    } else if (cardNumber === "wallet") {
      const buy_amount = BigNumber(amount)
      const wallet_balance = BigNumber(walletList[0].balance)

      if(buy_amount.isNaN()){
        message.error("Error amount format");
      }
      else if(wallet_balance.isNaN()){
        message.error("Error wallet balance format");
      }
      else if (buy_amount.isGreaterThan(wallet_balance)) {
        message.error("Insufficient wallet balance");
      } else {
        handleWalletPaymentVisible(true);
      }
    } else if (cardNumber === "sepa") {
      tryPayWithSepa();
    } 
    else if( cardNumber){
       submitToOrder();
    }
    else {
     
      message.error("Please select your payment method");
      
    }
  };

  const submitToOrder = () => {
    handlePayDisabled(true);
    getKycInfo().then((res) => {
      if (res.code === 0) {
        const canBuyCrypto =  res.result?.basic_kyc_status === 2 || res.result?.buy_one_order_enable
        if (canBuyCrypto) {
          const param = {
            cust_order_id: custOrderId || Date.now(),
            from_currency: fromCurrency,
            to_crypto: toCurrency,
            amount: amount,
            channel_name: channel_name,
            address: walletAddress,
            rate: rate,
            authorization: localStorage.getItem("authorization"),
            return_url: returnUrl || payDefaultUrl,
            customer: {
              card_number: cardNumber,
            },
            pay_with: "card",
          };
          fastBuyCurrency(param)
            .then((res) => {
              handlePayDisabled(false);
              if (res.code === 0) {
                props.history.push(
                  `../?id=${res.result.order_id}&pageFrom=buyCrypto`
                );
              }
            })
            .catch((err) => {
              handlePayDisabled(false);
            });
        } else {
          handlePayDisabled(false);
          message.error("Your identity verification is under review.");
        }
      }
    });
  };

  const renderBeforeImg = () => {
    if (fromCurrency === "USD") {
      return <img src={USD} />;
    } else if (fromCurrency === "EUR") {
      return <img src={EUR} />;
    } else if (fromCurrency === "GBP") {
      return <img src={GBP} />;
    }
  };

  const renderAfterImg = () => {
    if (toCurrency === "NEO") {
      return <img src={NEO} />;
    } else if (toCurrency === "USDT-ERC20") {
      return <img src={USDT} />;
    } else if (toCurrency === "USDT-TRC20") {
      return <img src={USDT} />;
    } else if (toCurrency === "GAS") {
      return <img src={GAS} />;
    } else if (toCurrency === "bNEO") {
      return <img src={BNEO} />;
    } else if (toCurrency === "FLM") {
      return <img src={FLM} />;
    } else if (toCurrency === "fUSDT") {
      return <img src={USDT} />;
    } else {
      return null;
    }
  };

  const selectChange = (e) => {
    handleCardNumber(e.target.value);
    if (e.target.value === "wallet") {
      handleButtonText("Payment");
    } else if (e.target.value === "sepa") {
      handleButtonText("Payment");
    } else {
      handleButtonText("Deposit and Pay");
    }
  };

  const getCaptcha = () => {
    handleSendEmailLoading(true);
    handleSendEmailDisabled(true);
    const email = localStorage.getItem("inst_payone_pay_mail");
    const params = {
      email,
    };
    sendEmail(params)
      .then((res) => {
        if (res.code === 0) {
          handleMailCodeToken(res.result);
          handleSendEmailLoading(false);
          message.success("send email success");
          handleDefaultTime(60);
        } else {
          handleSendEmailLoading(false);
          handleSendEmailDisabled(false);
        }
      })
      .catch(() => {
        handleSendEmailLoading(false);
        handleSendEmailDisabled(false);
      });
  };

  const confirmWallet = (values) => {
    handlePayDisabled(true);
    getKycInfo().then((res) => {
      if (res.code === 0) {
        const canBuyCrypto =  res.result?.basic_kyc_status === 2 || res.result?.buy_one_order_enable
        if (canBuyCrypto) {
          const param = {
            pay_with: "wallet",
            wallet_address: walletList[0].address,
            mail_token: mailCodeToken,
            mail_code: values["email-code"],
            from_currency: fromCurrency,
            to_crypto: toCurrency,
            amount: amount,
            rate: walletRate,
            address: walletAddress,
            authorization: localStorage.getItem("authorization"),
          };
          if (gaStatus) {
            param["ga-code"] = values["ga-code"];
          }
          fastBuyCurrency(param)
            .then((res) => {
              handlePayDisabled(false);
              if (res.code === 0) {
                props.history.push("/buyCrypto/wallet");
              }
            })
            .catch((err) => {
              handlePayDisabled(false);
            });
        } else {
          handlePayDisabled(false);
          message.error("Your identity verification is under review.");
        }
      }
    });
  };

  const checkoutChange = (e) => {
    handleChecked(e.target.checked);
    handleShowCheckedTips(!e.target.checked);
  };

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem("embedded")
            ? "ChooseBindCardContainer-embedded"
            : ""
        } ChooseBindCardContainer`}
      >
        <HeaderTop title={`Select Card`} />
        <Spin spinning={payDisabled}>
          <div className="container">
            <div className="topTips">
              <div className="left">
                <div>
                  {renderBeforeImg()}
                  {fromCurrency}
                </div>
                <div>{amount}</div>
              </div>
              <div className="center">
                <img src={RightIcon} alt="" />
              </div>
              <div className="right">
                <div>
                  {renderAfterImg()}
                  {toCurrency}
                </div>
                <div>{approximatelyAmount}</div>
              </div>
            </div>
          </div>

          <Spin spinning={cardListLoading}>
            <Radio.Group onChange={selectChange} className="radioContainer">
              <div className="tips_container">
                <img src={Jinggao} alt="jinggao" />
                <span>
                  If the orders is refunded,the Processing fee will not be
                  returned .
                </span>
              </div>
              <div className="payWith">Pay With</div>
              {!sepaOnly && walletList.length > 0 ? (
                <div className="bottomCardContainer">
                  <div className="title">Wallet</div>
                  <List
                    itemLayout="horizontal"
                    dataSource={walletList}
                    renderItem={(item) => (
                      <List.Item
                        className="renderItem"
                        actions={[<Radio value="wallet" />]}
                      >
                        <List.Item.Meta
                          title={
                            <div className="walletRenderItemTitle">
                              <div className="walletRenderItemTitleTips">
                                {item.address}
                              </div>
                              <div className="walletRenderItemTitleTipsBottom">
                                {item.balance} {item.currency}
                              </div>
                            </div>
                          }
                          avatar={
                            <img
                              className="renderItemImg"
                              slot="avatar"
                              src={walletCircle}
                            />
                          }
                        />
                      </List.Item>
                    )}
                  ></List>
                </div>
              ) : null}

              <div
                className="bottomCardContainer"
                hidden={fromCurrency !== "EUR" || (!paymentMethods.includes('sepa'))}
              >
                <div className="title">Bank Transfer</div>
                <List itemLayout="horizontal">
                  <List.Item
                    className="renderItem"
                    actions={[<Radio value="sepa" />]}
                  >
                    <List.Item.Meta
                      title={
                        <div className="walletRenderItemTitle">
                          <div className="walletRenderItemTitleTips">Sepa</div>
                          <div className="walletRenderItemTitleTipsBottom">
                            {/* {item.balance} {item.currency} */}
                          </div>
                        </div>
                      }
                      avatar={
                        <img
                          alt=""
                          className="renderItemImg"
                          slot="avatar"
                          src={sepaLogo}
                        />
                      }
                    />
                  </List.Item>
                </List>
              </div>

              {(!sepaOnly && (paymentMethods.includes('visa') || paymentMethods.includes('mastercard')))&& (
                <>
                  <div className="bottomCardContainer">
                    <div className="title">MasterCards</div>
                    {cardList.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={cardList}
                        renderItem={(item) => (
                          <List.Item
                            className="renderItem"
                            actions={[<Radio value={item.card_number} />]}
                          >
                            <List.Item.Meta
                              title={
                                <span className="renderItemTitle">
                                  **** **** **** ****{" "}
                                  {item?.card_number?.substring(
                                    item?.card_number?.length - 4,
                                    item?.card_number?.length
                                  )}
                                </span>
                              }
                              avatar={
                                <img
                                  className="renderItemImg"
                                  slot="avatar"
                                  src={masterCard}
                                />
                              }
                            />
                          </List.Item>
                        )}
                      ></List>
                    ) : (
                      <div className="cardContainer" />
                    )}
                  </div>
                  <div className="bind_list" onClick={payNewMethod}>
                    <div className="chooseCardTips">Pay with a new card</div>
                    <img src={RightIconBP} alt=""  />
                  </div>
                </>
              )}

              {/* {cardList.length > 0 ? (
                <div className='bottomCardContainer'>
                  <div className='title'>My Cards</div>
                  <List
                    itemLayout='horizontal'
                    dataSource={cardList}
                    renderItem={(item) => (
                      <List.Item
                        className='renderItem'
                        actions={[<Radio value={item.card_number} />]}
                      >
                        <List.Item.Meta
                          title={
                            <span className='renderItemTitle'>
                              **** **** **** ****{' '}
                              {item?.card_number?.substring(
                                item?.card_number?.length - 4,
                                item?.card_number?.length
                              )}
                            </span>
                          }
                          avatar={
                            <img
                              className='renderItemImg'
                              slot='avatar'
                              src={masterCard}
                            />
                          }
                        />
                      </List.Item>
                    )}
                  ></List>
                </div>
              ) : (
                <div className='cardContainer' />
              )} */}
            </Radio.Group>
          </Spin>

          <div className="checkContainer">
            <Checkbox onChange={checkoutChange} className="checkSty" />I accept
            the{" "}
            <a href={websiteConditionUrl} target="_blank">
              GENERAL TEAMS AND CONDITIONS
            </a>
          </div>
          <Button
            loading={payDisabled}
            type="primary"
            className="goToBtn"
            onClick={() => submitGetOrder()}
          >
            {buttonText}
          </Button>
          {/* <div className='chooseCardTips' onClick={payNewMethod}>
            Pay with a new card
          </div> */}
          <div className="showEmailTips">
            {showCheckedTips ? (
              <div className="showEmailTipsContainer">
                <img src={TipsIconNew} />
                <span>Please agree to the terms of service first</span>
              </div>
            ) : null}
          </div>
        </Spin>
      </div>
      <Footer />

      <Modal
        title="Verify"
        visible={walletPaymentVisible}
        footer={null}
        onCancel={() => handleWalletPaymentVisible(false)}
      >
        <Form
          form={form}
          name="verify"
          layout="vertical"
          onFinish={confirmWallet}
        >
          <Form.Item
            name="email-code"
            label="Email Verification Code"
            className="walletLabel"
            rules={[
              {
                required: true,
                message: "Please input the captcha you got",
              },
            ]}
          >
            <Row gutter={8}>
              <Col span={18}>
                <Input />
              </Col>
              <Col span={6} className="walletGoToBtnContainer">
                <Button
                  className="walletGoToBtn"
                  onClick={getCaptcha}
                  type="primary"
                  disabled={sendEmailDisabled}
                  loading={sendEmailLoading}
                >
                  {sendEmailText}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          {gaStatus ? (
            <Form.Item
              name="ga-code"
              label="Google Authenticator Verification Code"
              className="walletLabel"
              rules={[
                {
                  required: true,
                  message: "Please input the captcha you got",
                },
              ]}
            >
              <Input />
            </Form.Item>
          ) : null}
          <Form.Item className="walletConfirmGoToBtnContainer">
            <Button
              loading={payDisabled}
              type="primary"
              className="walletConfirmGoToBtn"
              htmlType="submit"
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <SepaModal
        visible={sepaModalVisible}
        speaData={speaData}
        cancel={() => {
          setSepaModalVisible(false);
          props.history.push("/buyCrypto/history");
        }}
      />
    </>
  );
};

export default withRouter(ChooseBindCard);
