import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { Button, Input, Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import BackImg from '../../../assets/images/back.svg'
import TipsIconNew from '../../../assets/images/tipsIconNew.svg'
import { sendEmail, login } from '../../../utils/api'
import { websitePrivacyUrl, websiteTermsUrl } from '../../../utils/config'
import './index.css'

const Verification = (props) => {
  const { t } = useTranslation()
  const [resendEmailDisabled, handleResendEmailDisabled] = useState(false)
  const [code, handleCode] = useState('')
  const [emailCode, handleEmailCode] = useState('')
  const [showEmailTips, handleShowEmailTips] = useState(false)
  const [currency, handleCurrency] = useState('')
  const [crypto, handleCrypto] = useState('')
  const [cryptoAmount, handleCryptoAmount] = useState('')
  const [amount, handleAmount] = useState('')
  const [walletAddress, handleWalletAddress] = useState('')
  const [returnUrl, handleReturnUrl] = useState('')
  const [custOrderId, handleCustOrderId] = useState('')
  const [checked, handleChecked] = useState(false)
  const [showCheckedTips, handleShowCheckedTips] = useState(false)

  useEffect(() => {
    if (getParams().from_currency) {
      handleCurrency(getParams().from_currency)
    }
    if (getParams().to_crypto) {
      handleCrypto(getParams().to_crypto)
    }
    if (getParams().walletAddress) {
      handleWalletAddress(getParams().walletAddress)
    }
    if (getParams().cryptoAmount) {
      handleCryptoAmount(getParams().cryptoAmount)
    }
    if (getParams().amount) {
      handleAmount(getParams().amount)
    }
    if (getParams().returnUrl) {
      handleReturnUrl(getParams().returnUrl)
    }
    if (getParams().custOrderId) {
      handleCustOrderId(getParams().custOrderId)
    }
  }, [])

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  const resendEmail = () => {
    let params = {
      email: props.match.params.email,
    }
    sendEmail(params).then((res) => {
      if (res.code == 0) {
        message.success('Send email success')
        localStorage.setItem('emailCode', res.result)
        handleEmailCode(res.result)
        handleResendEmailDisabled(true)
        setInterval(() => {
          handleResendEmailDisabled(false)
        }, 60000)
      }
    })
  }

  const toLogin = () => {
    if (!checked) {
      handleShowCheckedTips(true)
      return
    } else if (!code || code.length !== 6) {
      handleShowEmailTips(true)
      return
    } else {
      let params = {
        code,
        mail: props.match.params.email,
        token: localStorage.getItem('emailCode') || emailCode,
      }
      let authorization = getParams().authorization
      login(params, authorization).then((res) => {
        if (res.code === 0) {
          localStorage.setItem('authorization', getParams().authorization)
          localStorage.setItem('inst_payone_pay_mail', props.match.params.email)
          localStorage.setItem('daily_amount', res.result?.limit_amount || '')
          const canBuyCrypto =  res.result?.basic_kyc_status === 2  || res.result?.buy_one_order_enable
          if (canBuyCrypto) {
            props.history.push(
              `/buyCrypto/fastBuy?currency=${currency}&crypto=${crypto}&cryptoAmount=${cryptoAmount}&amount=${amount}&walletAddress=${walletAddress}&custOrderId=${custOrderId}&returnUrl=${returnUrl}`
            )
          } 
          // else if (res.result.basic_kyc_status === 0 ||res.result.basic_kyc_status === 7) {
          //   props.history.push(`/buyCrypto/kycCenter`)
          // }
          else {
            props.history.push(`/buyCrypto/kycCenter`)
          }
        }
      })
    }
  }

  const checkoutChange = (e) => {
    handleChecked(e.target.checked)
    handleShowCheckedTips(!e.target.checked)
  }

  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded')
            ? 'VerificationContainer-embedded'
            : ''
        } VerificationContainer`}
      >
        <div className='titleContainer'>
          <div className='titleContainerLeft'>
            <img
              src={BackImg}
              alt='back'
              onClick={() => props.history.go(-1)}
            />
            <span className='title'>{t('Verification.title')}</span>
          </div>
          <div className='titleContainerRight'></div>
        </div>
        <div className='tipsContainer'>{t('Verification.tips1')}</div>
        <Input
          type='number'
          placeholder={t('Verification.tips5')}
          value={code}
          onChange={(value) => {
            handleCode(value.target.value)
            if (value.target?.value.length !== 6 || !value.target?.value) {
              handleShowEmailTips(true)
            } else {
              handleShowEmailTips(false)
            }
          }}
        />
        <div className='showEmailTips'>
          {showEmailTips ? (
            <div className='showEmailTipsContainer'>
              <img src={TipsIconNew} />
              <span>Please input 6-digit number</span>
            </div>
          ) : null}
        </div>
        <div className='resendContainer'>
          <span className='tipsLeft'>{t('Verification.tips2')}</span>
          <span
            className={`tipsRight ${
              resendEmailDisabled ? 'resendEmailDisabled' : null
            }`}
            onClick={resendEmail}
          >
            {t('Verification.tips3')}
          </span>
        </div>
        <Button className='verConfirm' onClick={toLogin} type='primary'>
          {t('Verification.Confirm')}
        </Button>
        <div className='checkContainer'>
          <Checkbox onChange={checkoutChange} className='checkSty' />I agree to
          INST <a href={websiteTermsUrl}>Terms of use</a> and{' '}
          <a href={websitePrivacyUrl}>Privacy policy</a>
        </div>
        <div className='showEmailTips'>
          {showCheckedTips ? (
            <div className='showEmailTipsContainer'>
              <img src={TipsIconNew} />
              <span>Please agree to the terms of service first</span>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default withRouter(Verification)
