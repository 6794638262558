import axios from 'axios'
import baseUrl from './baseUrl'
import baseAdminUrl from './baseAdminUrl'
import request from './request'

export function getCheckout(params) {
  return axios({
    method: 'get',
    url: `${baseUrl}/v1/checkout?id=${params.id}`,
    data: params,
  })
}

export function choosePayment(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/choosePayment`,
    data: {
      domain: 'https://pay.inst.money/',
      ...params,
    },
  })
}

export function cancelPayment(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/cancel`,
    data: params,
  })
}

export function channelPayment(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/channel/payment`,
    data: params,
  })
}

export function eventTracking(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/eventTracking`,
    data: params,
  })
}

export function getRates(params, authorization) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/buy/rates`,
    headers: {
      Authorization: authorization,
    },
    data: params,
  })
}

export function fastBuyCurrency(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/user/fast/buy`,
    headers: {
      Authorization: params.authorization,
      'ga-code': params['ga-code'] || '',
    },
    data: { ...params },
  })
}

export function fastBuyCurrencyWithSepa(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/fast_buy`,
    headers: {
      Authorization: params.authorization,
    },
    data: { ...params },
  })
}


export function sendEmail(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/v1/email`,
    data: params,
  })
}

export function login(params, authorization) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/user/login`,
    headers: {
      Authorization: authorization,
    },
    data: params,
  })
}

export function editKycInfo(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/kyc/basic`,
    data: params,
  })
}

export function getKycInfo() {
  return request({
    method: 'get',
    url: `${baseUrl}/api/v1/kyc/basic`,
  })
}

export function getFastBuyHistory(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/user/crypto`,
    data: params,
  })
}

export function getCustCrypto(authorization) {
  return request({
    method: 'get',
    url: `${baseUrl}/api/v1/cust/crypto`,
    headers: {
      Authorization: authorization,
    },
  })
}

export function getBindCardList(params) {
  return request({
    method: 'get',
    url: `${baseUrl}/v1/account/bind-card/card-list`,
    data: params,
  })
}

export function getBindCardInfo(id) {
  return request({
    method: 'get',
    url: `${baseUrl}/v1/account/bind-card?id=${id}`,
  })
}

export function bindCard(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/v1/account/bind-card`,
    data: params,
  })
}

export function userWallet(authorization) {
  return request({
    method: 'post',
    headers: {
      Authorization: authorization,
    },
    url: `${baseUrl}/api/v1/wallet/user`,
  })
}

export function getWalletHistory(params, authorization) {
  return request({
    method: 'post',
    headers: {
      Authorization: authorization,
    },
    url: `${baseUrl}/api/v1/wallet/transactions`,
    data: { ...params },
  })
}

export function getGaStatus(mail) {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/common/security`,
    params: {
      mail,
      login_type: '4',
    },
  })
}

export function getWalletRate(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/buy/wallet_rates`,
    data: params,
  })
}

export function getUserStatusController() {
  return request({
    method: 'get',
    url: `${baseUrl}/api/v1/risk/user-status`,
  })
}

export function userFeedback(params) {
  return request({
    method: 'put',
    url: `${baseUrl}/api/v1/risk/user-feedback`,
    data: params,
  })
}

export function submitCreateOrder(params, authorization) {
  return request({
    method: 'post',
    headers: {
      Authorization: authorization,
    },
    url: `${baseUrl}/api/v1/deposit`,
    data: { ...params },
  })
}

export function createBankTransfer(params, authorization) {
  return request({
    method: 'post',
    headers: {
      Authorization: authorization,
    },
    url: `${baseUrl}/api/v1/wallet/deposit`,
    data: { ...params },
  })
}
