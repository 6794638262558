import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import it from './locales/zh-it.json'
import en from './locales/en-us.json'

const resources = {
  it: {
    translation: it,
  },
  en: {
    translation: en,
  },
}

i18n
  .use(LanguageDetector) //嗅探当前浏览器语言
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    },
  })

export default i18n
