import React from 'react'
import { withRouter } from 'react-router-dom'
import PayContainer from './PayContainerNew/index'
import Footer from '../Components/Footer'

const Pay = () => {
  return (
    <>
      <PayContainer />
      <Footer />
    </>
  )
}

export default withRouter(Pay)
