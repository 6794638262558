import React, { useState, useEffect, useRef } from 'react'
import { Button, Steps } from 'antd-mobile'
import { message, Input, Select, DatePicker, Upload, Spin } from 'antd'
import { MessageTwoTone, FundTwoTone, MailTwoTone } from '@ant-design/icons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import HeaderTop from '../Components/HeaderTop'
import { getKycInfo, editKycInfo } from '../../../utils/api'
import countryList from '../../../utils/Country.json'
import CloudUpload from '../../../assets/images/cloudUpload.svg'
import passwordImg from '../../../assets/images/passwordImg.svg'
import drivingImg from '../../../assets/images/drivingImg.svg'
import selfieCard from '../../../assets/images/selfieCard.svg'
import baseAdminUrl from '../../../utils/baseAdminUrl'
import './index.css'

const { Option } = Select
const { Step } = Steps

const uploadUrl = `${baseAdminUrl}/v1/common/file/public`
const headers = {
  authorization: localStorage.getItem('payone_access_token') || '',
}

const Kyc = (props) => {
  const { t } = useTranslation()
  const idTypeRef = useRef()
  const uploadRef = useRef()
  const dateRef = useRef()
  const [kycMsg, handleKycMsg] = useState({})
  const [first_name, handleFirstName] = useState('')
  const [last_name, handleLastName] = useState('')
  const [nationality, handleNationality] = useState('')
  const [countryOption, handleCountryOption] = useState([])
  const [idTypeOption, handleIDTypeOption] = useState([])
  const [idType, handleIDType] = useState('')
  const [doc_no, handleDocNo] = useState('')
  const [birDate, handleDate] = useState(null)
  const [frontLoading, handleFrontLoading] = useState(false)
  const [frontHttpImgUrl, handleFrontHttpImgUrl] = useState(false)
  const [frontImgUrl, handleFrontImgUrl] = useState(false)
  const [isIos, handleIsIos] = useState(false)
  const [idTypeOptionOpen, handleIdTypeOptionOpen] = useState(false)
  const [dateOpen, handleDateOpen] = useState(false)

  const [kycLevel, handleKycLevel] = useState(null)
  const [frontDriverHttpImgUrl, handleFrontDriverHttpImgUrl] = useState(false)
  const [frontDriverImgUrl, handleDriverFrontImgUrl] = useState(false)

  const [mixLoading, handleMixLoading] = useState(false)
  const [mixHttpImgUrl, handleMixHttpImgUrl] = useState('')
  const [mixImgUrl, handleMixImgUrl] = useState('')

  useEffect(() => {
    //判断是否为ios
    let u = navigator.userAgent
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    handleIsIos(isiOS)
    initOption()
    getKyc()
    initFastClick()
    if (getParams().kyc_level) {
      handleKycLevel(getParams().kyc_level)
      if (getParams().kyc_level === '1') {
        handleIDType('driving')
      } else {
        handleIDType('passport')
      }
    }
  }, [])

  const initFastClick = () => {
    let a = document.getElementsByClassName('ant-upload')
    for (var i = 0; i < a.length; i++) {
      document
        .getElementsByClassName('ant-upload')
        [i].classList.add('needsclick')
    }
  }

  const getParams = () => {
    var params = {}
    if (
      props.location.search.indexOf('?') == 0 &&
      props.location.search.indexOf('=') > 1
    ) {
      var paramArray = unescape(props.location.search)
        .substring(1, props.location.search.length)
        .split('&')
      if (paramArray.length > 0) {
        paramArray.forEach(function (currentValue) {
          params[currentValue.split('=')[0]] = currentValue.split('=')[1]
        })
      }
    }
    return params
  }

  const getKyc = () => {
    getKycInfo().then((res) => {
      if (res.code === 0) {
        handleKycMsg(res.result)
        handleFirstName(res.result?.first_name)
        handleLastName(res.result?.last_name)
        handleNationality(res.result?.nationality)
        // handleIDType(res.result?.doc_type)
        handleDocNo(res.result?.doc_no)
        handleFrontHttpImgUrl(res.result?.front_doc)
        handleFrontDriverHttpImgUrl(res.result?.driver_front_doc)
        handleMixHttpImgUrl(res.result?.mix_doc)
        if (res.result?.birthday) {
          handleDate(moment(res.result?.birthday))
        }
      }
    })
  }
  const initOption = () => {
    let countryOptions = []
    countryList.map((item) => {
      countryOptions.push({
        value: item.countryCode,
        label: item.name_en,
      })
    })
    handleCountryOption(countryOptions)
    handleIDTypeOption([
      { label: 'Passport', value: 'passport' },
      { label: "Driver's license", value: 'driving' },
    ])
  }

  const dateOnChange = (date, dateString) => {
    if (isIos) {
      handleDateOpen(false)
      dateRef.current.blur()
    }
    if (date) {
      handleDate(moment(date))
    } else {
      handleDate(null)
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt4M = file.size / 1024 / 1024 < 4
    if (!isLt4M) {
      message.error('Image must smaller than 4MB!')
    }
    return isJpgOrPng && isLt4M
  }

  const uploadProps = {
    name: 'file',
    showUploadList: false,
    action: uploadUrl,
    headers: headers,
    beforeUpload: beforeUpload,
    onChange(info) {
      if (info.file.status === 'uploading') {
        handleFrontLoading(true)
        return
      }
      if (info.file.status === 'done') {
        handleFrontLoading(false)
        if (info?.file?.response?.code === 0) {
          if (idType === 'driving') {
            handleFrontDriverHttpImgUrl(info?.file?.response?.result)
            getBase64(info.file.originFileObj, (imageUrl) => {
              handleDriverFrontImgUrl(imageUrl)
            })
          } else {
            handleFrontHttpImgUrl(info?.file?.response?.result)
            getBase64(info.file.originFileObj, (imageUrl) => {
              handleFrontImgUrl(imageUrl)
            })
          }
        }
      }
    },
  }

  const uploadSelfieProps = {
    name: 'file',
    showUploadList: false,
    action: uploadUrl,
    headers: headers,
    beforeUpload: beforeUpload,
    onChange(info) {
      if (info.file.status === 'uploading') {
        handleMixLoading(true)
        return
      }
      if (info.file.status === 'done') {
        handleMixLoading(false)
        if (info?.file?.response?.code === 0) {
          handleMixHttpImgUrl(info?.file?.response?.result)
          getBase64(info.file.originFileObj, (imageUrl) => {
            handleMixImgUrl(imageUrl)
          })
        }
      }
    },
  }

  const submitKyc = () => {
    if (kycLevel === '3') {
      let params = {
        ...kycMsg,
        kyc_level: kycLevel,
        level_three_status: 0,
        mix_doc: mixHttpImgUrl,
      }
      editKycInfo(params).then((res) => {
        if (res.code === 0) {
          props.history.push(`/buyCrypto/kycResult?kyc_level=${kycLevel}`)
        }
      })
    } else {
      if (!nationality) {
        message.error('Please select nationality', 3)
      } else if (!first_name) {
        message.error('Please input your first name', 3)
      } else if (!last_name) {
        message.error('Please input your last name', 3)
      } else if (!idType) {
        message.error('Please select your ID type', 3)
      } else if (!frontHttpImgUrl && !frontDriverHttpImgUrl) {
        message.error('Please upload Passport Photo', 3)
      } else {
        let params = {
          first_name,
          last_name,
          nationality,
          // doc_no,
          doc_type: idType,
          kyc_level: kycLevel,
          // birthday: moment(birDate).valueOf(),
        }
        if (idType === 'passport') {
          params.front_doc = frontHttpImgUrl
        } else {
          params.driver_front_doc = frontDriverHttpImgUrl
        }
        editKycInfo(params).then((res) => {
          if (res.code === 0) {
            props.history.push(`/buyCrypto/kycResult?kyc_level=${kycLevel}`)
          }
        })
      }
    }
  }

  const idTypeChange = (value) => {
    if (isIos) {
      handleIdTypeOptionOpen(false)
      idTypeRef.current.blur()
    }
    handleIDType(value)
  }

  const renderIdType = () => {
    if (isIos) {
      return (
        <Select
          ref={idTypeRef}
          bordered={true}
          className='selectCountryContainer selectIdATypeContainer'
          placeholder={t('Kyc.tips5')}
          value={idType}
          onChange={idTypeChange}
          open={idTypeOptionOpen}
          onFocus={() => handleIdTypeOptionOpen(true)}
          onBlur={() => handleIdTypeOptionOpen(false)}
          disabled={true}
        >
          {idTypeOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    } else {
      return (
        <Select
          bordered={true}
          className='selectCountryContainer selectIdATypeContainer'
          placeholder={t('Kyc.tips5')}
          value={idType}
          onChange={(value) => {
            handleIDType(value)
          }}
          disabled={true}
        >
          {idTypeOption.map((item) => {
            return (
              <Option value={item.value} key={item.value}>
                {item.label}
              </Option>
            )
          })}
        </Select>
      )
    }
  }

  const renderDatePicker = () => {
    if (isIos) {
      return (
        <DatePicker
          ref={dateRef}
          value={birDate}
          inputReadOnly={true}
          onChange={dateOnChange}
          className='datePickerContainer'
          style={{ height: '48px', borderRadius: '10px', width: '100%' }}
          open={dateOpen}
          allowClear={false}
          onFocus={() => {
            handleDateOpen(true)
          }}
          onClick={() => {
            handleDateOpen(!dateOpen)
          }}
        />
      )
    } else {
      return (
        <DatePicker
          value={birDate}
          inputReadOnly={true}
          onChange={dateOnChange}
          className='datePickerContainer'
          style={{ height: '48px', borderRadius: '10px', width: '100%' }}
        />
      )
    }
  }
  return (
    <>
      <Header />
      <div
        className={`${
          sessionStorage.getItem('embedded') ? 'KycContainer-embedded' : ''
        } KycContainer`}
      >
        <HeaderTop title='Identification' />
        <div className='tipsContainer'></div>
        {/* <div className='inputContainer'>
            <div className='inputContent inputContentLeft'>
              <div className='inputTitle'>
                {idType === 'passport'
                  ? t('Kyc.tips6')
                  : "Driver's License Number"}
              </div>
              <Input
                type='text'
                placeholder={
                  idType === 'passport'
                    ? t('Kyc.tips6')
                    : "Driver's License Number"
                }
                value={doc_no}
                size='large'
                onChange={(value) => handleDocNo(value.target.value)}
              />
            </div>
            <div className='inputContent'>
              <div className='inputTitle'>{t('Kyc.tips7')}</div>
              {renderDatePicker()}
            </div>
          </div> */}
        {kycLevel === '1' || kycLevel === '2' ? (
          <>
            <div className='inputContainer'>
              <div className='inputContent inputContentLeft'>
                <div className='inputTitle'>{t('Kyc.tips2')}</div>
                <Input
                  type='text'
                  placeholder={t('Kyc.tips2')}
                  value={first_name}
                  size='large'
                  onChange={(value) => handleFirstName(value.target.value)}
                />
              </div>
              <div className='inputContent'>
                <div className='inputTitle'>{t('Kyc.tips3')}</div>
                <Input
                  type='text'
                  placeholder={t('Kyc.tips3')}
                  value={last_name}
                  size='large'
                  onChange={(value) => handleLastName(value.target.value)}
                />
              </div>
            </div>
            <div className='inputContainer'>
              <div className='inputContent inputContentLeft'>
                <div className='inputTitle'>{t('Kyc.tips4')}</div>
                <Select
                  size='large'
                  bordered={true}
                  className='selectCountryContainer'
                  placeholder={t('Kyc.tips4')}
                  value={nationality}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.children[1].props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) => {
                    optionA.children[1].props.children
                      .toLowerCase()
                      .localeCompare(
                        optionB.children[1].props.children.toLowerCase()
                      )
                  }}
                  onChange={(value) => {
                    handleNationality(value)
                  }}
                >
                  {countryOption.map((item) => {
                    return (
                      <Option
                        value={item.value}
                        key={item.value}
                        className='countryOption'
                      >
                        <span className='optionRight'>+{item.value} </span>
                        <span className='optionLeft'>{item.label}</span>
                      </Option>
                    )
                  })}
                </Select>
              </div>
              <div className='inputContent'>
                <div className='inputTitle'>{t('Kyc.tips5')}</div>
                {renderIdType()}
              </div>
            </div>
            <div className='inputContainer'>
              <div className='inputContent'>
                <div className='inputTitle'>
                  {idType === 'passport'
                    ? t('Kyc.tips8')
                    : "Driver's License Photo"}
                  <Upload
                    {...uploadProps}
                    ref={uploadRef}
                    className='needsclick'
                  >
                    <div className='uploadBtn needsclick' type='primary'>
                      <span className='needsclick'>
                        upload
                        <img
                          className='needsclick'
                          src={CloudUpload}
                          alt='upload'
                        />
                      </span>
                    </div>
                  </Upload>
                </div>
                <div className='idCardContainer'>
                  <Spin spinning={frontLoading}>
                    {idType === 'driving' ? (
                      frontDriverHttpImgUrl || frontDriverImgUrl ? (
                        <img
                          src={frontDriverHttpImgUrl || frontDriverImgUrl}
                          alt='front card'
                        />
                      ) : (
                        <img src={drivingImg} alt='front card' />
                      )
                    ) : null}

                    {idType === 'passport' ? (
                      frontHttpImgUrl || frontImgUrl ? (
                        <img
                          src={frontHttpImgUrl || frontImgUrl}
                          alt='front card'
                        />
                      ) : (
                        <img src={passwordImg} alt='front card' />
                      )
                    ) : null}
                  </Spin>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {kycLevel === '3' ? (
          <div className='inputContainer'>
            <div className='inputContent'>
              <div className='inputTitle'>
                Selfie with passport
                <Upload
                  {...uploadSelfieProps}
                  ref={uploadRef}
                  className='needsclick'
                >
                  <div className='uploadBtn needsclick' type='primary'>
                    <span className='needsclick'>
                      upload
                      <img
                        className='needsclick'
                        src={CloudUpload}
                        alt='upload'
                      />
                    </span>
                  </div>
                </Upload>
              </div>
              <div className='idCardContainer idCardSelfieContainer'>
                <Spin spinning={mixLoading}>
                  {mixHttpImgUrl || mixImgUrl ? (
                    <img src={mixHttpImgUrl || mixImgUrl} alt='front card' />
                  ) : (
                    <img src={selfieCard} alt='selfie card' />
                  )}
                </Spin>
              </div>
              <span className='uploadDescribe'>
                *Make sure the text on the passport is clear(please do not use
                selfie mode or mirror the photo);your entire face is visible,and
                your shoulders and arms are included in the photo.
              </span>
            </div>
          </div>
        ) : null}
        {kycLevel === '4' ? (
          <div className='inputContainer'>
            <div className='topContainer'>
              <h4>Process:</h4>
              <div className='stepContainer'>
                <Steps direction='vertical'>
                  <Step
                    status='finish'
                    title='Send documents via email'
                    icon={<MessageTwoTone />}
                  />
                  <Step
                    status='finish'
                    title='Review (around 1 week)'
                    icon={<FundTwoTone />}
                  />
                  <Step
                    status='finish'
                    title='Get result via email'
                    icon={<MailTwoTone />}
                  />
                </Steps>
              </div>
              <div className='tipsContainer'>
                <div className='leftMsg'>
                  <div className='leftMsgTitle'>Required document:</div>
                  <div className='leftMsgTips'>
                    <div>1.</div>
                    <div>
                      A statement of how the purchased tokens will be used with
                      photos or videos of the platform to use.
                    </div>
                  </div>
                  <div className='leftMsgTips'>
                    <div>2.</div>
                    <div>
                      Proof of Address. Provide your complete address in
                      English, along with a recent proof of address issued no
                      more than 6 months before the date you submit it. A proof
                      of address can be a utility bill, phone bill, or bank
                      statement that displays your name and current address.
                    </div>
                  </div>
                </div>
              </div>
              <div className='tipsContact'>
                To keep your information safe, please send the required
                documents to
                <a href='mailto:support@inst.money'> support@inst.money</a>
              </div>
            </div>
          </div>
        ) : null}
        {kycLevel !== '4' ? (
          <Button className='verConfirm' onClick={submitKyc}>
            {t('Verification.Confirm')}
          </Button>
        ) : null}
      </div>
      <Footer />
    </>
  )
}

export default withRouter(Kyc)
