import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { message } from 'antd'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import Header from '../Components/Header'
import { getCheckout } from '../../utils/api'
import quickPass from '../../assets/images/yunPay.svg'
import './index.css'

const CloudFlashPayment = (props) => {
  const { t } = useTranslation()
  const [qrcode, handleQrcode] = useState('')
  const [logo, handleLogo] = useState('')
  const [title, handleTitle] = useState('')

  useEffect(() => {
    if (props.match.params.id) {
      let params = { id: props.match.params.id }
      getCheckout(params).then((response) => {
        let res = response.data
        if (res.code === 0) {
          handleQrcode(res.result?.data?.qrcode || '')
          handleLogo(res.result?.logo)
          handleTitle(res.result?.title)
        } else {
          message.error(res.msg)
        }
      })
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel='icon' href={logo} />
      </Helmet>
      <Header hasCancel={false} logo={logo} title={title} />
      <div className='payContainer payContainerFlash'>
        <div className='payCurrencyContainer payCurrencyContainerFlash'>
          <div className='quickPassContainer'>
            <h2 className='quickTop'>{t('CloudFlashPayment.scanToPay')}</h2>
            <div className='codeScanQuick'>
              <QRCode value={qrcode} size={150} />
            </div>
            <div className='quickPassBottom'>
              <img src={quickPass} className='quickPassImg' alt='img'></img>
              <p>{t('CloudFlashPayment.tips1')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(CloudFlashPayment)
